import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { DivideRowStyleService } from "../../shared_services/divide-row-style.service";

@Component({
  selector: "app-image-title",
  templateUrl: "./image-title.component.html",
  styleUrls: ["./image-title.component.scss"],
})
export class ImageTitleComponent implements OnInit {
  @Input() component: any;

  lang: string;
  currentLang: string;

  image_position: any;
  outData: any;
  ImageTitleData: any;
  websiteSecColor: any;
  col: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private colStyleService:DivideRowStyleService

  ) {}

  ngOnInit(): void {
   
    
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.image_position = this.component.image_position;

    this.homeService
    .getHomeComponentsData(this.component)
    .subscribe((res: any) => {
      this.ImageTitleData = res.content;
      this.col=  this.colStyleService.getColClass(res.content);

    console.log("ImageTitleData",this.component);

    });

    this.outData = this.ImageTitleData;

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
      });
    });
  }
}
