<div class="row" *ngIf="article">
  <div
    class="col-md-12 col-sm-12 col-xs-12"
    
    *ngIf="component_title_position != 'center'"
  >
    <h4
      *ngIf="lang == 'en_container'"
      class="component-title-left"
      style="margin-bottom: 20px"
      [ngStyle]="{
        'border-left-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ article.component_title }}
    </h4>
    <h4
      *ngIf="lang == 'ar_container'"
      class="component-title-right"
      style="margin-bottom: 20px"
      [ngStyle]="{
        'border-right-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ article.component_title }}
    </h4>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>

  <div
    class="col-md-12 col-sm-12 col-xs-12"
    
    style="text-align: center"
    *ngIf="component_title_position == 'center'"
  >
    <h3
      style="font-weight: bold"
      [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
    >
      {{ ComponentTitle }}
    </h3>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>

  <div
    class="col-md-12 col-sm-12 col-xs-12"
    
  >
    <a (click)="navigateToListShow(article)">
      <div class="card">
        <div class="row" style="height: 100%">
          <div class="col-md-4 col-sm-4 col-xs-4">
            <img
              *ngIf="article.type == 'image'"
              class="image"
              [src]="article.image"
            />
          </div>
          <div class="col-md-8 col-sm-8 col-xs-8">
            <h6
              class="main-title"
              *ngIf="hide_title == 1"
              [innerHtml]="article.title_detailed"
            ></h6>
            <p
              class="p-date"
              *ngIf="
                article.module_calendar == 1 &&
                Hide_Date == 0 &&
                article.end_date != ''
              "
            >
              {{ article.end_date }}
            </p>
            <p
              class="p-date"
              *ngIf="
                article.module_calendar == 2 &&
                Hide_Date == 0 &&
                article.end_date != ''
              "
            >
              {{ article.end_date_hijri }}
            </p>

            <p
              class="paragraph"
              *ngIf="hide_description == 1"
              [innerHtml]="article.description_detailed"
            ></p>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
