import * as $ from "jquery";

import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

import { CommonGeneralService } from "../../../general-pages/pages/services/common-general.service";
import { HeaderComponent } from "../header/header.component";
import { LangService } from "../../shared_services/lang.service";
import { MenuService } from "../../../services/General/menu.service";
import { NotificationsService } from "../../../shared/shared_services/notifications.service";
import { PusherService } from "src/app/services/pusher/pusher.service";
import { ToastrService } from "ngx-toastr";
import { BehaviourSubjectService } from "src/app/services/behaviour-subject.service";

@Component({
  selector: "app-header5",
  templateUrl: "./header5.component.html",
  styleUrls: ["./header5.component.scss"],
})
export class Header5Component extends HeaderComponent implements OnInit {

  Menu_Response;
  Menu_items_sorted;

  Text_Style;
  contactData: any;

  constructor(
    protected toastr: ToastrService,
    protected router: Router,
    protected notifi: NotificationsService,
    protected pusherService: PusherService,
    protected menuService: MenuService,
    protected service: CommonGeneralService,
    protected route: ActivatedRoute,
    protected langService: LangService,
    protected commonPagesService: CommonGeneralService,
    protected behaviorService:BehaviourSubjectService

  ) {
    super(
      toastr,
      router,
      notifi,
      pusherService,
      menuService,
      service,
      route,
      langService,
      commonPagesService,
      behaviorService
    );

    if (this.APP_ID != null) {
      this.menuService.menuData$.subscribe((res: any) => {
        this.Menu_Response = res;
      })

    }   

  }

  ngOnInit(): void {
    this.Menu_items_sorted = this.MenuItems.sort((a, b) => (a.order < b.order) ? -1 : 1);
    this.getContactData()
  }

  changeLang(lang: string) {
    this.langService.changeLang(lang);
    window.location.replace(`${lang}/${this.router.url.slice(4)}`);
  }

  navigatetoModule(item) {
    // //comment it when finshed from BE
    // item.can_navigate == 1;
    // //
    if (item.can_navigate == 1) {
      this.itemSelectedFromHeader = item.name;
      $("body").removeClass(" nb-theme-default");

      localStorage.setItem("apiparameter", JSON.stringify(item.flow[0]));
      localStorage.setItem("sublist", JSON.stringify(item.flow[1]));
      localStorage.setItem("lastScreen", JSON.stringify(item.flow.length - 1));
      localStorage.setItem(
        "sublist_moduleNo",
        JSON.stringify(item.flow[item.flow.length - 1].parameters.module_id)
      );

      localStorage.setItem("perview", JSON.stringify(item.flow[0].action_id));

      localStorage.setItem("ModuleID", JSON.stringify(item.name));
      localStorage.setItem("ModuleNo", JSON.stringify(item.module_id));

      // this.router.navigate(['/cmsforms'], { queryParams: { formparams:JSON.parse( item.flow[0]) } });

      if (item.flow[0].layout_id == 32 || item.flow[0].layout_id == 1) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/general-list`], {
            queryParams: {
              list: 1,
              module: JSON.parse(localStorage.getItem("ModuleNo")),
            },
          });
        });
        localStorage.setItem(
          "list1_Components",
          JSON.stringify(item.flow[0].components)
        );
        localStorage.setItem(
          "show1_Components",
          JSON.stringify(item.flow[1].components)
        );
        localStorage.setItem("List1_Title", JSON.stringify(item.title));
      } else if (item.flow[0].layout_id == 33 || item.flow[0].layout_id == 2) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/general-list`], {
            queryParams: {
              list: 2,
              module: JSON.parse(localStorage.getItem("ModuleNo")),
            },
          });
        });
        localStorage.setItem(
          "list2_Components",
          JSON.stringify(item.flow[0].components)
        );
        localStorage.setItem(
          "show2_Components",
          JSON.stringify(item.flow[1].components)
        );
        localStorage.setItem("List2_Title", JSON.stringify(item.title));
      } else if (item.flow[0].layout_id == 31) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/general-list`], {
            queryParams: {
              list: 3,
              module: JSON.parse(localStorage.getItem("ModuleNo")),
            },
          });
        });
        localStorage.setItem(
          "list3_Components",
          JSON.stringify(item.flow[0].components)
        );
        localStorage.setItem(
          "show3_Components",
          JSON.stringify(item.flow[1].components)
        );
        localStorage.setItem("List3_Title", JSON.stringify(item.title));
      } else if (item.flow[0].layout_id == 34) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/general-list`], {
            queryParams: {
              list: 4,
              module: JSON.parse(localStorage.getItem("ModuleNo")),
            },
          });
        });
        localStorage.setItem(
          "list4_Components",
          JSON.stringify(item.flow[0].components)
        );
        localStorage.setItem(
          "show4_Components",
          JSON.stringify(item.flow[1].components)
        );
        localStorage.setItem("List4_Title", JSON.stringify(item.title));
      } else if (item.flow[0].layout_id == 35) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/general-list`], {
            queryParams: {
              list: 5,
              module: JSON.parse(localStorage.getItem("ModuleNo")),
            },
          });
        });
        localStorage.setItem(
          "list5_Components",
          JSON.stringify(item.flow[0].components)
        );
        localStorage.setItem(
          "show5_Components",
          JSON.stringify(item.flow[1].components)
        );
        localStorage.setItem("List5_Title", JSON.stringify(item.title));
      } else if (item.flow[0].layout_id == 36) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/general-list`], {
            queryParams: {
              list: 6,
              module: JSON.parse(localStorage.getItem("ModuleNo")),
            },
          });
        });
        localStorage.setItem(
          "list6_Components",
          JSON.stringify(item.flow[0].components)
        );
        localStorage.setItem(
          "show6_Components",
          JSON.stringify(item.flow[1].components)
        );
        localStorage.setItem("List6_Title", JSON.stringify(item.title));
      } else if (item.flow[0].layout_id == 37) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/general-list`], {
            queryParams: {
              list: 7,
              module: JSON.parse(localStorage.getItem("ModuleNo")),
            },
          });
        });
        localStorage.setItem(
          "list7_Components",
          JSON.stringify(item.flow[0].components)
        );
        localStorage.setItem(
          "show7_Components",
          JSON.stringify(item.flow[1].components)
        );
        localStorage.setItem("List7_Title", JSON.stringify(item.title));
      } else if (item.flow[0].layout_id == 38) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/general-list`], {
            queryParams: {
              list: 8,
              module: JSON.parse(localStorage.getItem("ModuleNo")),
            },
          });
        });

        localStorage.setItem(
          "list8_Components",
          JSON.stringify(item.flow[0].components)
        );
        localStorage.setItem(
          "show8_Components",
          JSON.stringify(item.flow[1].components)
        );
        localStorage.setItem("List8_Title", JSON.stringify(item.title));
      } else if (item.flow[0].layout_id == 59) {
        // Go to General Pages Module
        this.langService.getLang().subscribe((res) => {
        this.behaviorService.updateMenuItem(item.id);

          this.router.navigate([`${res.key}/cms/general`], {
            queryParams: { module: JSON.parse(item.id) },
          });
        });
      } else if (item.flow[0].layout_id == 29) {
        // localStorage.setItem(
        //   "Home_components",
        //   JSON.stringify(item.flow[0].components)
        // );
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/home`]);
        });
      } else if (item.flow[0].layout_id == 30) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cmspages/contactus`]);
        });
      } else if (item.flow[0].layout_id == 2459) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/info`], {
            queryParams: {
              module: JSON.parse(localStorage.getItem("ModuleNo")),
            },
          });
        });
      } else if (item.flow[0].layout_id == 58) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/cms/general-list`], {
            queryParams: {
              module: JSON.parse(localStorage.getItem("ModuleNo")),
            },
          });
        });

        localStorage.setItem(
          "General_list_Components",
          JSON.stringify(item.flow[0].components)
        );
        localStorage.setItem(
          "show_Components",
          JSON.stringify(item.flow[1].components)
        );
        localStorage.setItem("general_List_Title", JSON.stringify(item.title));
      } else if (item.flow[0].layout_id == 68) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/forms`], {});
        });
       
      }
 
      else if (item.flow[0].layout_id == 70) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/form`], { queryParams: { module_id: JSON.parse(item.flow[0].parameters.module_id ) , template:JSON.parse(item.flow[0].parameters.template_id )  } } );
        });
       
      }
      else if (item.flow[0].layout_id == 96) {
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/iframe`], { queryParams: {
             module_id: JSON.parse(item.flow[0].parameters.module_id ),
             link: encodeURIComponent(item.link)   } } );
        });
      }

    } else {
      this.scrollToElement(item.internal_navigate_to);
    }

  }

  navigateToForms() {
    $("body").removeClass(" nb-theme-default");
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cmsforms`], {
        queryParams: { formparams: localStorage.getItem("form") },
      });
    });
  }

  navigateToHome() {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/home`]);
    });
  }

  scrollToElement(element: string): void {
    if (this.router.url !== "/en/home" && this.router.url !== "/ar/home") {
      this.router.navigate([`${this.lang.key}/home`]).then(() => {
        setTimeout(() => {
          document
            .getElementById(element)
            .scrollIntoView({ behavior: "smooth", inline: "nearest" });
        }, 2000);
      });
    } else {
      document
        .getElementById(element)
        .scrollIntoView({ behavior: "smooth", inline: "nearest" });
    }
  }
  decreaseFontSize(fontSize: string): string {
    return this.commonPagesService.decreaseFontSize(fontSize);
  }
  getContactData()
  {
    this.commonPagesService.getContactData().subscribe((res:any)=>
    {
      this.contactData=res.content[0];
      console.log('contactData',this.contactData);
      
    })
  }
}
