<div [appMyStyles]="component">
  <app-header-title-desc [article]="component" [appMyStyles]="component"></app-header-title-desc>
</div>

<div class="mt-3 mb-5 d-flex"
[appMyStyles]="component"
>
  <div
    class="row col-lg-12 m-auto py-3"
    [ngStyle]="{
      'background-image': component?.component_background_image !== 'https://limberx.com/test/limberx_generator_api/public/img/Default_background.png' 
        ? 'url(' + component?.component_background_image + ')' 
        : 'none'
        
    }"
    
   
  >
  <!-- for center content -->
   <ng-container *ngIf="component?.Style_content_position == 0">
    <div
    class="mar-bottom-col px-1" [ngClass]="col"
    *ngFor="let item of FourbuttoncardsData | slice : 0 : component?.items_number;index as i"
  >
    <div class="card hover-style align-items-center"  [appGridStyle]="component"
    >
      <button  class="btn btn-half text-center" *ngIf="item?.price != null && item?.image_tags != null"
      [ngStyle]="{
         'border-color':websiteSecColor,'color':websiteSecColor}"
         [ngClass]="[
        component?.button_position == 'center' ? 'text-center' : component?.button_position == 'left'? 'text-start':'text-end']">
       {{ item?.price }}
     </button>
      <img  [ngClass]="[component?.Style_image_shape=='1'?'card-img-top':'card-img', component?.Style_image_is_icon==1?'img-icon':'']" [src]="item?.image"  *ngIf="item?.image && item?.link == null"/>
      <div class="video-container" [class.playing]="isPlaying(i)" *ngIf="item?.link!=null ">
        <iframe 
        #videoFrame
        class="video-content" 
        [src]="isPlaying(i) ? (transformYouTubeUrl(item?.link) | safeUrl) : null" 
        frameborder="0" 
        allow="autoplay; encrypted-media"
        allowfullscreen
        [title]="'Video: ' + (item?.title || 'Untitled')"
        *ngIf="isPlaying(i)"
      ></iframe>
        
        <div class="overlay" (click)="activateIframe(i)" *ngIf="!isPlaying(i)">
          <img [src]="item?.image" class="video-content" *ngIf="item?.image" alt="Video thumbnail" />
          <button class="play-button" (click)="$event.stopPropagation(); activateIframe(i)">
            <i class="fas fa-play"></i>
          </button>
        </div>
        
        <div class="overlay playing" (click)="deactivateIframe(i)" *ngIf="isPlaying(i)">
          <button class="pause-button" (click)="$event.stopPropagation(); deactivateIframe(i)">
            <i class="fas fa-pause"></i>
          </button>
        </div>
        
        <div class="fullscreen-button" (click)="openFullscreenIframe(i)" *ngIf="isPlaying(i)">
          <i class="fas fa-expand"></i>
        </div>
      </div>


      <div class="px-3 mb-3" [ngClass]="item?.image == null ?'no-image':''"
      [ngStyle]="{'background-color':component?.Style_card_background}"> 
        <h6 
        *ngIf="item?.image != null"
        class="fixed-height"
        [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,
      'text-transform':'none',
      'font-weight':component?.data_title_weight}"
        >
          {{ item.title }}
        </h6>
        <div class="title-wrapper"  *ngIf="item?.image == null"
        >
          <span class="line" ></span>
          <h6  class="fixed-height"
          [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,
        'text-transform':'none',
        'font-weight':component?.data_title_weight}"
          >{{ item.title }}</h6>
          <span class="line"></span>
        </div>
      
        <p  class="desc-details" [ngClass]="[!item.description ? 'd-none ':'',component?.Style_image_shape=='0'?'trim-6':'']"
        [ngStyle]="{'font-size':component?.description_size,'text-transform':'lowercase', 'color':component?.description_color,'font-weight':component?.data_desc_weight}"
       *ngIf="item.description"
        >
          {{ item.description }} 
        </p>
        <span *ngIf="item?.price" class="pt-4 "
        [ngStyle]="{'font-size':'24px', 'color':websiteMainColor,
        'text-transform':'none',
        'font-weight':component?.data_desc_weight}"
        >
          {{item.price}} {{'EGP' |translate}}
        </span>
        <div class="d-flex" [ngClass]="[
        component?.button_position == 'center' ? 'justify-content-center' : component?.button_position == 'left'? 'justify-content-start':'justify-content-end']"
*ngIf="component?.component_buttons?.length>0"
>
          <button  *ngIf="component?.component_buttons?.length>0"
            [ngStyle]="{
              'background-color': component && component.component_buttons && component.component_buttons[0] && component.component_buttons[0].button_background_color ? component.component_buttons[0].button_background_color : 'transparent',
              'color': component && component.component_buttons && component.component_buttons[0] ? component.component_buttons[0].button_color : 'inherit',
              'border-color': component && component.component_buttons && component.component_buttons[0] && component.component_buttons[0].border_color ? component.component_buttons[0].border_color : 'transparent',
              'font-size': component && component.component_buttons && component.component_buttons[0] && component.component_buttons[0].button ? component.component_buttons[0].button.font_size : 'inherit',
              'font-weight': component && component.component_buttons && component.component_buttons[0] && component.component_buttons[0].button ? component.component_buttons[0].button.font_weight : 'normal',
              'text-transform':'capitalize',
              'padding':!component?.component_buttons[0]?.button_background_color && !component?.component_buttons[0]?.border_color?'':'10px 30px'
            }"
            (click)="navigateToShow(item.id)"
           
            class="read-more mx-3" 
          >
          
          <ng-container *ngIf="lang == 'ar_container'">{{component?.component_buttons[0]?.button_text}} </ng-container> 
          <ng-container *ngIf="lang == 'en_container'">{{component?.component_buttons[0]?.button_text}} </ng-container> 
     
          <ng-container *ngIf="!component?.component_buttons[0]?.button_background_color && !component?.component_buttons[0]?.border_color">
            <i class="fa fa-arrow-right"></i>
          </ng-container>
          
          </button>
        </div>
     
      </div>
    </div>
  </div>
   </ng-container>
  
  <!-- for side content -->

  <ng-container *ngIf="component?.Style_content_position != 0">
    <div [ngClass]="col" class="mb-3" *ngFor="let item of FourbuttoncardsData | slice : 0 : component?.items_number;index as i">
      <div class="card-side d-flex flex-wrap align-items-center" [appGridStyle]="component">
        <div class=""
        [ngClass]="component.Style_image_shape == '1' && item?.link == null ? 'h-100 w-100':''">
          <img
          *ngIf="item?.image && item?.link == null"
            [ngClass]="[component.Style_image_shape == '1' ? 'card-img-side':'card-img', component?.Style_image_is_icon==1?'img-icon':'']"
            [src]="item?.image"
            class="img-fluid"
            alt="Card image"
          />
          <div class="video-container" [class.playing]="isPlaying(i)" *ngIf="item?.link !=null">
            <iframe 
            #videoFrame
            class="video-content" 
            [src]="isPlaying(i) ? (transformYouTubeUrl(item?.link) | safeUrl) : null" 
            frameborder="0" 
            allow="autoplay; encrypted-media"
            allowfullscreen
            [title]="'Video: ' + (item?.title || 'Untitled')"
            *ngIf="isPlaying(i)"
          ></iframe>
            
            <div class="overlay" (click)="activateIframe(i)" *ngIf="!isPlaying(i)">
              <img [src]="item?.image" class="video-content" *ngIf="item?.image" alt="Video thumbnail" />
              <button class="play-button" (click)="$event.stopPropagation(); activateIframe(i)">
                <i class="fas fa-play"></i>
              </button>
            </div>
            
            <div class="overlay playing" (click)="deactivateIframe(i)" *ngIf="isPlaying(i)">
              <button class="pause-button" (click)="$event.stopPropagation(); deactivateIframe(i)">
                <i class="fas fa-pause"></i>
              </button>
            </div>
            
            <div class="fullscreen-button" (click)="openFullscreenIframe(i)" *ngIf="isPlaying(i)">
              <i class="fas fa-expand"></i>
            </div>
          </div>
        </div>
  
        <div [ngClass]="item?.link? 'in-video':''"  [ngStyle]="{'background-color':component?.Style_card_background}">
          <h6
            [ngStyle]="{
              'font-size': component?.title_size,
              color: component?.title_color,
              'font-weight': component?.data_title_weight
            }"
          >
            {{ item.title }}
          </h6>
          <div *ngIf="item.description" class="p-3">
            <p
              class="mb-5"
              [ngStyle]="{
                'font-size': component?.description_size,
                color: component?.description_color,
                'font-weight': component?.data_desc_weight
              }"
            >
              {{ item.description }}
            </p>
  
            <button
              *ngIf="component?.component_buttons?.length > 0"
              [ngStyle]="{
                'background-color': component?.component_buttons[0]?.button_background_color,
                color: component?.component_buttons[0]?.button_color,
                'border-color': component?.component_buttons[0]?.button_background_color,
                'font-size': component?.component_buttons[0]?.button?.font_size,
                'font-weight': component?.component_buttons[0]?.button?.font_weight
              }"
             
              class="btn btn-primary "
              [ngClass]="item?.link?'read-more-video':'read-more-side'"
            >
              <ng-container *ngIf="lang == 'ar_container'">
                {{ component?.component_buttons[0]?.button_text }}
              </ng-container>
              <ng-container *ngIf="lang == 'en_container'">
                {{ component?.component_buttons[0]?.button_name }}
              </ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  </div>
</div>

<app-alert 
  *ngIf="FourbuttoncardsData?.length == 0" 
  [componentId]="component?.id" 
  [mainId]="component?.main_id"
  [message]="'InfoAlarm' | translate"
  [type]="'warning'"
>
</app-alert>