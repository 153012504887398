import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-image-with-benfits',
  templateUrl: './image-with-benfits.component.html',
  styleUrls: ['./image-with-benfits.component.scss']
})
export class ImageWithBenfitsComponent implements OnInit {
  title_center:number=1;
  isCurve :number=1;
  @Input() component: any;

  imageTitleData;
  
  lang: string; 
  currentLang: string;

  outData: any;
  backgroundUrl = 'https://limberx.com/test/limberx_generator_api/public/img/Default_background.png';
  websiteMainColor;
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
  
    ) {}

  ngOnInit(): void {
    console.log("benefits,",this.component);
    
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.imageTitleData = res.content;
        
      });

      this.outData = this.imageTitleData;

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.imageTitleData = res.content;
         
        });

    });
  }

  getItemsNumber(): number {
    return this.component?.items_number != null ? this.component.items_number : this.imageTitleData?.length ?? 0;
  }
  isDefaultBackground(bg): boolean {
    return this.backgroundUrl === bg; 
  } 

}
