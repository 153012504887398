<div  [appMyStyles]="component">

  <app-header-title-desc [article]="component" ></app-header-title-desc>

</div>
<div class="container mt-5">
  <div class="row">
    <!-- Image Section --> 
    <div
      class="col-md-5 col-sm-12 text-center mb-4"
      [class.order-md-last]="component?.image_position === 'right'"
      *ngIf="Data?.length > 0"
    >
      <img
        *ngIf="Data?.length > 0"
        alt=""
        class="w-100"
        [src]="Data[0]?.image || 'assets/images/default.png'"
      />
    </div>
  
    <!-- Text and Calendar Section -->
    <div class="col-md-7 col-sm-12" *ngIf="Data?.length > 0">
       
        <h1 [ngStyle]="{ color: component?.title_font_color, 'text-transform':'none', 'font-size': component?.title_font_size }">
        {{ component?.component_title }}
      </h1>
  
      <p [ngStyle]="{ color: component?.desc_font_color, 'font-size':component?.description_size,'text-transform':'lowercase' }" [innerHTML]="Data[0]?.description"></p>
  
      <!-- Calendar -->
      <div class="calendar mt-3">
        <!-- Calendar Header -->
        <div class="calendar-header">
          <h5 class="mb-0">{{ currentMonth }} {{ currentYear }}</h5>
        </div>
      
        <!-- Calendar Days -->
        <div class="calendar-grid">
          <div class="day" *ngFor="let day of daysOfWeek">{{ day }}</div>
      
          <!-- Calendar Dates -->
          <div
            class="date"
            *ngFor="let date of calendarDays"
            [class.active]="isToday(date)"
            [class.has-appointments]="getAppointmentsForDate(date).length"
            (click)="selectDate(date)"
          >
            {{ date.getDate() }}
            <!-- Appointment Tooltip -->
            <div
              *ngIf="selectedDate && selectedDate.toDateString() === date.toDateString()"
              class="appointment-popup"
            >
              <p>
                <strong>Available appointment on {{ date | date: 'MMMM d, yyyy' }}</strong>
              </p>
              <p *ngFor="let appointment of getAppointmentsForDate(date)">
                {{ appointment.time }}
              </p>
              <button class="btn btn-sm btn-primary">Book appointment</button>
            </div>
          </div>
        </div>
      </div>
      
      
      
    </div>
  </div>
</div>

<!-- No Data Alert -->
<div
  class="alert alert-warning text-center mt-4"
  role="alert"
  *ngIf="!Data?.length"
>
  <p>
    <b>#{{ component?.id }}</b>
    {{ 'InfoAlarm' | translate }}
  </p>
</div>
