import { Component, OnInit } from "@angular/core";

import { DomSanitizer } from "@angular/platform-browser";
import { MenuService } from "src/app/services/General/menu.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import { SeoService } from "src/app/services/seo.service";
import { SwiperPaginationInterface } from "ngx-swiper-wrapper";
import { EditComponentPopupComponent } from "src/app/shared/edit-component-popup/edit-component-popup.component";
import { DialogService } from "primeng/dynamicdialog";

declare var $: any;

// import {SupportService}from '../../../../app/support/services/support.service';
// import { AssociationService } from '../../../../app/memorize-quran/services/association.service';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  Home_Components_Data;
  slides: any[] = [];

  list1_component_data;
  list2_component_data;
  list3_component_data;
  list4_component_data;
  list5_component_data;
  list6_component_data;
  list7_component_data;
  list8_component_data;
  about = "about";
  currentPage: number = 1;

  rtlRef = false;
  customOptions: OwlOptions;
  awards: OwlOptions;

  param;
  Module_Name: any;
  Home_Page_ID: any;

  MenuItems: any[] = [];
  module_components: any[] = [];

  module_components_up: any[] = [];
  module_components_up_right: any[] = [];
  module_components_up_left: any[] = [];

  module_components_down: any[] = [];
  module_components_down_right: any[] = [];
  module_components_down_left: any[] = [];

  module_components_right: any[] = [];
  module_components_left: any[] = [];
  module_components_middle: any[] = [];

  module_components_up_sorted: any[] = [];
  module_components_up_right_sorted: any[] = [];
  module_components_up_left_sorted: any[] = [];

  module_components_down_sorted: any[] = [];
  module_components_down_right_sorted: any[] = [];
  module_components_down_left_sorted: any[] = [];

  module_components_right_sorted: any[] = [];
  module_components_left_sorted: any[] = [];
  module_components_middle_sorted: any[] = [];

  module_components_No_Position: any[] = [];
  module_components_No_Position_array: any[] = [];

  components: any;
  applicationId: string = "";
   arraysWithNames = [
    { name: 'module_components_up', data: this.module_components_up },
    { name: 'module_components_up_right', data: this.module_components_up_right },
    { name: 'module_components_up_left', data: this.module_components_up_left },
    { name: 'module_components_down', data: this.module_components_down },
    { name: 'module_components_down_right', data: this.module_components_down_right },
    { name: 'module_components_down_left', data: this.module_components_down_left },
    { name: 'module_components_right', data: this.module_components_right },
    { name: 'module_components_left', data: this.module_components_left },
    { name: 'module_components_middle', data: this.module_components_middle },
    { name: 'module_components_up_sorted', data: this.module_components_up_sorted },
    { name: 'module_components_up_right_sorted', data: this.module_components_up_right_sorted },
    { name: 'module_components_up_left_sorted', data: this.module_components_up_left_sorted },
    { name: 'module_components_down_sorted', data: this.module_components_down_sorted },
    { name: 'module_components_down_right_sorted', data: this.module_components_down_right_sorted },
    { name: 'module_components_down_left_sorted', data: this.module_components_down_left_sorted },
    { name: 'module_components_right_sorted', data: this.module_components_right_sorted },
    { name: 'module_components_left_sorted', data: this.module_components_left_sorted },
    { name: 'module_components_middle_sorted', data: this.module_components_middle_sorted },
   
  ];
  private pagination: SwiperPaginationInterface = {
    el: ".swiper-pagination",
    clickable: true,
  };
  cached=false;

  constructor(
    public sanitizer: DomSanitizer,
    private menuService: MenuService,
    private seoService: SeoService,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.menu();
  }
  menu(data?)
  {
      this.menuService.menuData$.subscribe((res: any) => {
      if (res) {
        
        this.MenuItems = res.Items;
        
        const homeData = this.MenuItems.filter(
          (res) => res.name === "Home page" || res.name === "Home Page" || res.name === "الصفحة الرئيسيه" ||res.name === "الصفحه الرئيسية"
                  ||res.name === "الصفحات" || res.name === "home" || res.name === "Home" || res.name === "الصفحة الرئيسية"
        );
        this.seoService.addTitle(homeData[0]?.page_seo_name);
        if(homeData.length>0)
        homeData[0].seo.forEach((seo) => {
          this.seoService.addTag(seo.key, seo.value);
        });

        this.MenuItems.forEach((element) => {
          if (element.flow[0].layout_id == 29) {
            this.Home_Components_Data = element.flow[0].components;

            this.Module_Name = element.name;
            
            this.Home_Components_Data.forEach((obj) => {
          
              if (obj.position == "down") {
                
                this.module_components_down.push(obj);
                this.module_components_down_sorted = this.module_components_down.sort((a, b) => (a.block_id < b.block_id) ? -1 : 1);
                
              } else if (obj.position == "down_right") {
             
                this.module_components_down_right.push(obj);
                this.module_components_down_right_sorted = this.module_components_down_right.sort((a, b) => (a.block_id < b.block_id) ? -1 : 1);
                
              } else if (obj.position == "down_left") {
              
                this.module_components_down_left.push(obj);
                this.module_components_down_left_sorted = this.module_components_down_left.sort((a, b) => (a.block_id < b.block_id) ? -1 : 1);
                
              } else if (obj.position == "up") {
              
                  this.module_components_up.push(obj);
                  this.module_components_up_sorted = this.module_components_up.sort((a, b) => (a.block_id < b.block_id) ? -1 : 1);
                
              } else if (obj.position == "up_right") {
               
                this.module_components_up_right.push(obj);
                this.module_components_up_right_sorted = this.module_components_up_right.sort((a, b) => (a.block_id < b.block_id) ? -1 : 1);
                
              } else if (obj.position == "up_left") {
              
                this.module_components_up_left.push(obj);
                this.module_components_up_left_sorted = this.module_components_up_left.sort((a, b) => (a.block_id < b.block_id) ? -1 : 1);
                
              } else if (obj.position == "right_side") {
            
                this.module_components_right.push(obj);
                this.module_components_right_sorted = this.module_components_right.sort((a, b) => (a.block_id < b.block_id) ? -1 : 1);
                
              } else if (obj.position == "left_side") {
                
                this.module_components_left.push(obj);
                this.module_components_left_sorted = this.module_components_left.sort((a, b) => (a.block_id < b.block_id) ? -1 : 1);
                
              } else if (obj.position == "middile" || obj.position == 'middle') {
              
                this.module_components_middle.push(obj);
                this.module_components_middle_sorted = this.module_components_middle.sort((a, b) => (a.block_id < b.block_id) ? -1 : 1);
                
              } else if (obj.position == "") {
                this.module_components_No_Position.push(obj);
              }
            });
           
            if(data)
              {
              const index = this.Home_Components_Data.findIndex(item => item.id === data?.oldId);
           
              var result=this.findIdInArrays(data.oldId);
              if (result.found)
              { 
                
                 this.updateComponentsList(result.arrays[0],data.oldId,data.newItem);
              }
              
              }
            this.module_components_No_Position_array =
              this.module_components_No_Position;
            
          }
        });
      }
    });
  }
  showDialog(type,item?) {
  
    const ref = this.dialogService.open(EditComponentPopupComponent, {
      data: {
        type: type,
        item:item,
        cached:this.cached

    },
      header: `Change the component: ${item.id}`,
      width: '80%'
  });
  ref.onClose.subscribe((res) => {
   
        if(res)
        {
          this.menu({'newItem':res,'oldId':item.id});
          console.log('newItem',res);
          
        }
        this.cached=true;
});
}
updateComponentsList(array,idToRemove: number, newItem: any): void {
 
 const arrayNameIndex=this.arraysWithNames.findIndex(item=> item.name === array);
  const index = this.arraysWithNames[arrayNameIndex].data.findIndex(item => item.id === idToRemove);

  if (index !== -1) {
    // Replace the item at the found index with the new item
    this.arraysWithNames[arrayNameIndex].data[index].id = newItem.component_id;

  }
}
findIdInArrays(selectedId: number): { found: boolean; arrays: string[] } {
  const foundArrays = this.arraysWithNames
    .filter(array => array.data.some(item => item.id === selectedId))
    .map(array => array.name);

  return {
    found: foundArrays.length > 0,
    arrays: foundArrays
  };
}

}
