import { AppRoutingModule, routes } from "./app-routing.module";
import { BrowserModule, TransferState } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
} from "@gilsdav/ngx-translate-router";
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { NbLayoutModule, NbThemeModule } from "@nebular/theme";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";

import { AboutUsPageComponent } from "./about-us-page/about-us-page.component";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { ApiConstData } from "./cms-forms/consts/ApiConstData";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ButtonModule } from "primeng/button";
import { CarouselModule } from "ngx-owl-carousel-o";
import { DonatePopupComponent } from "./shared/donate-popup/donate-popup.component";
import { EmbedVideo } from "ngx-embed-video";
import { GeneralPagesModule } from "./general-pages/general-pages.module";
import { HttpInterceptorService } from "./services/http-interceptor.service";
import { Location } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { NewDetailsPopupComponent } from "./general-pages/pages/news/new-details-popup/new-details-popup.component";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PopupComponent } from "./shared/popup/popup.component";
import { RatingModule } from "ng-starrating";
import { RouterModule } from "@angular/router";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SharedModule } from "./shared/shared.module";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { SwiperModule } from "ngx-swiper-wrapper";
import { TextInputAutocompleteModule } from "angular-text-input-autocomplete";
import { ToastModule } from "primeng/toast";
import { ToastrModule } from "ngx-toastr";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { localizeBrowserLoaderFactory } from "./shared/utils/localize-browser.loader";

import { FormGeneratorModule } from './form-generator/form-generator.module';
import { TestFormGeneratorComponent } from './test-form-generator/test-form-generator.component';
import { CardProfileComponent } from './card-profile/card-profile.component';
import { DialogService} from "primeng/dynamicdialog";
import { IframeComponent } from "./iframe/iframe.component";

// import { CdTimerModule } from 'angular-cd-timer';
// import { CountdownModule } from 'ngx-countdown';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "../assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    PopupComponent,
    DonatePopupComponent,
    NewDetailsPopupComponent,
    TestFormGeneratorComponent,
    IframeComponent,

    // LoaderComponent
  ],
  imports: [
    MatDialogModule,
    BrowserModule,
    MatDialogModule,
    AppRoutingModule,
    // MatStepperModule,
    FormsModule,
    TextInputAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatButtonModule,
    SharedModule,
    SwiperModule,
    CarouselModule,
    ButtonModule,
    ToastModule,
    MatSelectModule,
    HttpClientModule,
    // NgMultiSelectDropDownModule.forRoot(),
    RatingModule,
    GeneralPagesModule,
    // NbThemeModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NbThemeModule.forRoot({ name: "default" }),
    NbLayoutModule,
    NbEvaIconsModule,
    ToastrModule.forRoot(),
    NgbModule,
    EmbedVideo.forRoot(),
    AccordionModule.forRoot(),
    RouterModule,
    LocalizeRouterModule,
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: localizeBrowserLoaderFactory,
        deps: [
          TranslateService,
          Location,
          LocalizeRouterSettings,
          HttpClient,
          TransferState,
        ],
      },
      initialNavigation: true,
    }),
    FormGeneratorModule,

    // CountdownModule

    // CdTimerModule

    // TabsModule.forRoot(),
    // AutocompleteLibModule,

    // ShareButtonsModule.withConfig({
    //   debug: true
    // }),
    // ShareIconsModule
    // AngularCountdowntimerModule

  ],
  exports: [
    SharedModule
  ],
  providers: [
    ApiConstData,
    // GlobalFunctions,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    Location,
    TransferState,
    DialogService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
