<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
    <div
      class="row col-lg-12 m-auto py-3"
      [class]="article?.animations?.length > 0 ? article?.animations[1] : ''"
    >
      <div class="col-md-6 col-sm-12 col-xs-12">
        <img class="image w-100" src="{{ Contact_Info?.photo }}" />
      </div>
  
      <div class="col-md-6 col-sm-12 col-xs-12 big-padd">
        <div class="row">
          <div class="col-md-6 col-sm-12 col-xs-12">
            <div style="margin-bottom: 55px">
              <div style="display: flex">
                <img class="small-img" src="assets/images/phone.png" />
                <h5>{{ "Call" | translate }}</h5>
              </div>
              <p class="txt">{{ Contact_Info?.mobile }}</p>
            </div>
  
            <div style="margin-bottom: 55px">
              <div style="display: flex">
                <img class="small-img" src="assets/images/email (5) 1.png" />
                <h5>{{ "Mail" | translate }}</h5>
              </div>
              <p class="txt">{{ Contact_Info?.email }}</p>
            </div>
          </div>
  
          <div class="col-md-6 col-sm-12 col-xs-12">
            <div style="margin-bottom: 55px">
              <div style="display: flex">
                <img
                  class="small-img"
                  src="assets/images/maps-and-flags (2) 1.png"
                />
                <h5>{{ "Address" | translate }}</h5>
              </div>
              <p class="txt">{{ Contact_Info?.mobile }}</p>
            </div>
  
            <div style="margin-bottom: 55px">
              <div style="display: flex">
                <img class="small-img" src="assets/images/world-wide-web 1.png" />
                <h5>{{ "Web link" | translate }}</h5>
              </div>
              <p class="txt">{{ Contact_Info?.email }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
