import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";

@Component({
  selector: "app-projects3-with-sharp-image",
  templateUrl: "./projects3-with-sharp-image.component.html",
  styleUrls: ["./projects3-with-sharp-image.component.scss"],
})
export class Projects3WithSharpImageComponent implements OnInit {
  @Input() article: any;

  content;

  lang: string;
  currentLang: string;

  outData: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private langService: LangService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");



    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.content = res.content;
    console.log('projects', this.content);
      });

    this.outData = this.content;


    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.content = res.content;
        });
    });
  }
}
