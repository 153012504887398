<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="d-flex row"
    
  >

  <div class="image-grid">
    <img [ngStyle]="{'borderRadius': article?.component_style === 'Curved' ? '6px' : '0'}"
    *ngFor="let item of images | slice:0:6 ; let i = index"
    [src]="item.image"
    alt="Dynamic Image"
    [ngClass]="{ 'col-lg-6': i === 0 || i===5, 'col-lg-3': i !== 0 && i !== 5 }"
  />
  </div>
    
  </div>
</div>
<app-alert *ngIf="images?.length == 0" [componentId]="article?.id" [mainId]="article?.main_id"
[message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>

<app-alert *ngIf="images?.length == 0" [componentId]="article?.id" [mainId]="article?.main_id"
[message]="'NumberAlarm' | translate" [type]="'warning'">
</app-alert>