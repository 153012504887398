<div class="row"
    
    [appMyStyles]="article"
    [ngStyle]="{
       'background-image': 'url(' + article?.component_background_image + ')' 
       }"
    style="padding: 50px;background-repeat: no-repeat;background-size: cover;"    
>

    <div class="col-md-12 col-sm-12 col-xs-12"
        style="text-align: center"
        *ngIf="component_title_position == 'center'">

        <h1
        class="main-title text-center padd"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h1>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>

    </div>

    <div
    class="col-md-12 col-sm-12 col-xs-12"
    *ngIf="component_title_position != 'center'"
   >
    <h4
      *ngIf="lang == 'en_container'"
      class="component-title-left"
      [ngStyle]="{
        'border-left-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h4
      *ngIf="lang == 'ar_container'"
      class="component-title-right"
      [ngStyle]="{
        'border-right-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
    </div>

</div>