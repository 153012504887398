<div [appMyStyles]="component">

<app-header-title-desc [article]="component"></app-header-title-desc>
  
  <div class="carousel-container position-relative mt-3">
    <!-- Left Navigation -->
    <button class="carousel-button prev" (click)="prev()">
      <i class="fas fa-chevron-left"></i>
    </button>

    <!-- Carousel Items -->
    <div class="row justify-content-center">
      <div
        class="col-lg-4 col-md-12 col-sm-12 media"
        *ngFor="let item of visibleItems"
      >
        <div
          class="bg-image"
          [ngStyle]="{ 'background-image': 'url(' + item?.image + ')' }"
        >
          <div class="text">
            <p class="author"
            [ngStyle]="{
              'font-size': component?.title_size,
              color: component?.title_color,
              'font-weight': component?.data_title_weight
            }">{{ item?.title }}</p>
          
          </div>
        </div>
      </div>
    </div>

    <!-- Right Navigation -->
    <button class="carousel-button next" (click)="next()">
      <i class="fas fa-chevron-right m-0"></i>
    </button>
  </div>
</div>

<div
  class="alert alert-warning text-center"
  role="alert"
  *ngIf="imageTitleData?.length == 0"
>
  <p>
    <b>#{{ component?.id }}</b> {{ "InfoAlarm" | translate }}
  </p>
</div>
