<div class="row col-lg-10 m-auto" [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>

<div class="babysitter-profile">
  <!-- Characteristics Header -->
  <div class="characteristics">
    <i class="fas fa-bars menu-icon"></i> 
    <span class="title">Characteristics</span>
  </div>

  <!-- Tags -->
  <div class="tags">
    <span class="tag">Responsible</span>
    <span class="tag">Friendly</span>
    <span class="tag">Creative</span>
  </div>

  <!-- Profile Section -->
  <div class="profile-section">
    <h3 class="title supersitter">
      <i class="fas fa-award"></i> Supersitter
    </h3>
    <p>
      I’ve worked with 4-month-olds to sophomores in high school during the last 4 years and I worked at the
      school for the blind as an internship for a semester. I worked with kids who were paralyzed or blind.
    </p>
  </div>

  <!-- Experience Details -->
  <div class="experience">
    <div class="experience-item">
      <i class="fas fa-clock"></i> 
      <span class="label">Experience with babysitting</span>
      <span class="value">3 Years</span>
    </div>

    <div class="experience-item">
      <i class="fas fa-baby"></i> 
      <span class="label">Experience with age</span>
      <span class="value">Baby • Toddler • Preschooler</span>
    </div>

    <div class="experience-item">
      <i class="fas fa-medal"></i> 
      <span class="label">Amanda is a supersitter</span>
      <span class="value">Supersitters are babysitters who have provided excellent service.</span>
    </div>

    <div class="experience-item">
      <i class="fas fa-id-card"></i> 
      <span class="label">Government ID</span>
      <span class="value">Amanda successfully provided a government ID.</span>
    </div>
  </div>

  <!-- Booking Section -->
  <div class="booking-section">
    <div class="booking-options">
      <button class="booking-btn">Hourly</button>
      <button class="booking-btn">Daily</button>
      <button class="booking-btn">Monthly</button>
    </div>
    <button class="book-now">Book now</button>
  </div>
</div>
