import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

import { CommonGeneralService } from "../services/common-general.service";
import { LangService } from "src/app/shared/shared_services/lang.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  SearchList: any[] = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private service: CommonGeneralService,
    private router: Router,
    private langService: LangService
  ) {}

  ngOnInit(): void {
    /* this.searchData(this.activeRoute.snapshot.queryParams['query']); */
    this.activeRoute.queryParams.subscribe((res) => {
      this.searchData(this.activeRoute.snapshot.queryParams["query"]);
    });
  }

  searchData(query) {
    this.service.HomeSearch(query).subscribe((res: any) => {
      this.SearchList = res.content;
      console.log("search",this.SearchList);
      
    });
  }

  navigateToShow(id) {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cms/1/${id}`]);
    });
    // this.router.navigateByUrl( `cms/2/${id}`)
  }
}
