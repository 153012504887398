import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

interface StyleConfig {
  borderRadius: string;
  justifyContent: string;
  backgroundColor: string;
  backGroundImage: string;
  overflow: string;
  flexWrap: string;
  paddingInline: string;
  marginTop: string;
  animationClass?: string; // Store class name here instead of treating it as a style

  [key: string]: string | undefined;
}

@Directive({
  selector: "[appMyStyles]",
})
export class MyStylesDirective {
  private _styleConfig: StyleConfig;

  @Input("appMyStyles")
  set appMyStyles(article: any) {
    const conditions = {
      justifyContent: article?.full_width === 0 ? "center" : "flex-start",
      borderRadius: article?.component_style === "Curved" ? "20px" : "0",
      backgroundColor: article?.component_background_color
        ? article?.component_background_color
        : "transparent",
      backGroundImage: article?.component_background_color == null ? `url(${article?.component_background_image})` : '',
      overflow: article?.component_list === "split" ? "hidden" : "scroll",
      flexWrap: article?.component_list === "split" ? "wrap" : "nowrap",
      paddingInline: article?.full_width === 0 ? "3rem" : "0",
      marginTop: article?.space_up === 0 ? "0" : "60px",
      animationClass: article?.animations?.length > 0 ? "right" : "left", // Store class name
    };

    const {
      justifyContent,
      borderRadius,
      backgroundColor,
      backGroundImage,
      overflow,
      flexWrap,
      paddingInline,
      marginTop,
      animationClass,
    } = conditions;

    this._styleConfig = {
      justifyContent,
      borderRadius,
      backgroundColor,
      backGroundImage,
      overflow,
      flexWrap,
      paddingInline,
      marginTop,
      animationClass,
    };

    this.applyStyles();
    this.applyClass(animationClass); // Apply class separately
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  private applyStyles() {
    this.resetStyles();
    for (const prop in this._styleConfig) {
      if (this._styleConfig.hasOwnProperty(prop) && prop !== "animationClass") {
        const value = this._styleConfig[prop] as string;
        this.renderer.setStyle(this.el.nativeElement, prop, value);
      }
    }
  }

  private applyClass(animationClass?: string) {
    // Remove previous animation classes
    this.renderer.removeClass(this.el.nativeElement, "left");
    this.renderer.removeClass(this.el.nativeElement, "right");

    if (animationClass) {
      this.renderer.addClass(this.el.nativeElement, animationClass);
    }
  }

  private resetStyles() {
    for (const prop in this._styleConfig) {
      if (this._styleConfig.hasOwnProperty(prop) && prop !== "animationClass") {
        this.renderer.removeStyle(this.el.nativeElement, prop);
      }
    }
  }
}
