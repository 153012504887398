<div class="row" [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>
<div class="row" [appMyStyles]="component">
  <div class="col-md-3 col-sm-12 col-xs-12" style="margin-bottom: 15px" *ngFor="let item of threeCardsData">
    <div class="home-article hover-style" (click)="navigateToShow(item?.id)">
      <a *ngIf="item?.type == 'image'">

        <img *ngIf="item?.type == 'image'" [src]="item?.image" class="cover-img">

       
        <div class="home-article-desc">
          <h4   
          class="title"         
           [ngClass]="[component?.component_title_position == 'center' ? 'text-center' : 'text-start']"

          [ngStyle]="{'font-size':component?.title_size,
        'text-transform':'none'
        ,'color':component?.title_color,'font-weight':component?.data_title_weight}">
          {{ item?.title }}
        </h4>
          <p 
          [ngClass]="[component?.Style_content_position == '0' ? 'text-center' : 'text-start']"
            [ngStyle]="{'font-size':component?.description_size,'text-transform':'lowercase', 'color':component?.description_color,'font-weight':component?.data_desc_weight}">
            {{ item?.description }}
          </p>
        </div>
      </a>
    </div>
  </div>
</div>
<div class="row d-flex justify-content-center mb-5">

  <button *ngIf="threeCardsData?.length > 0" [ngStyle]="{
        color: websiteSecColor
      }" class="btn see-more">
    <i class="fas fa-chevron-down" aria-hidden="true"></i>

    {{'see more' | translate}}

  </button>
</div>

<app-alert *ngIf="threeCardsData?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
[message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>