import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-image-with-two-list',
  templateUrl: './image-with-two-list.component.html',
  styleUrls: ['./image-with-two-list.component.scss']
})
export class ImageWithTwoListComponent implements OnInit {
  isCurve :number=1;
  isDescription :number=1;
  @Input() component: any;

  ImageTitleData;
  lang: string;
  currentLang: string;
  image_position: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,

    ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.image_position = this.component.image_position;
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
      
      });
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.ImageTitleData = res.content;
          
        });

    });
  }

}
