<a
  [appMyStyles]="article"
  
  (click)="navigateToListShow(article.Data)"
>
  <div class="card text-left" style="height: 280px">
    <img
      *ngIf="article.Data.type == 'image'"
      class="card-img-top"
      [src]="article.Data.image"
    />
    <!-- <iframe
        *ngIf="article.Data.type  != 'image' "
        [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(article.Data.link))"
        width="100%"
        height="150px"
        frameborder="0"
        title="test"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> -->
    <div class="card-body">
      <div style="display: flex">
        <h3 class="card-title">{{ article.Data.title }}</h3>
      </div>

      <p
        class="p-date"
        *ngIf="
          article.Data.module_calendar == 1 &&
          Hide_Date == 0 &&
          article.Data.end_date != ''
        "
      >
        {{ article.end_date }}
      </p>
      <p
        class="p-date"
        *ngIf="
          article.Data.module_calendar == 2 &&
          Hide_Date == 0 &&
          article.Data.end_date != ''
        "
      >
        {{ article.end_date_hijri }}
      </p>

      <!-- <p [innerHTML]="article.Data.description" *ngIf="hide_description == 0"></p> -->
      <p *ngIf="hide_description == 0" class="paragraph">
        {{ article.Data.description }}
      </p>
      <p
        *ngIf="hide_description == 0"
        [innerHtml]="article.Data.description_detailed"
      ></p>
    </div>
  </div>
</a>

<!-- <div class="row" *ngIf="component_list_Data > 0 ">
    <div class="col-md-4 col-sm-12 col-xs-12" *ngFor="let item of component_list_Data" >
        <a   (click)="navigateToListShow(item)">
            <div class="card text-left" style="height: 280px;">

                <img class="card-img-top" [src]="item.image">
                <div class="card-body">
                    <div style ="display: flex;">
                      <h3 class="card-title" >{{item.title}}</h3>
                    </div>


                        <p class="p-date" *ngIf="item.module_calendar == 1 && Hide_Date == 0 && item.end_date != '' ">  {{item.end_date}} </p>
                        <p class="p-date" *ngIf="item.module_calendar == 2 && Hide_Date == 0 && item.end_date != '' "> {{item.end_date_hijri}} </p>

                        <p [innerHTML]="item.description" *ngIf="Hide_Description == 0"></p>


                </div>
            </div>
        </a>
    </div>


</div> -->
