<div class="container-fluid" style="padding: 0px; position: relative">
  <!--================ Start Up Positions  ================  -->

  <div
    class="row"
    *ngIf="
      module_components_up_sorted.length > 0 &&
      module_components_up_right_sorted.length == 0 &&
      module_components_up_left_sorted.length == 0
    "
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      style="padding: 0px; position: relative"
    >
      <div *ngFor="let item of module_components_up_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 40px"
    *ngIf="
      module_components_up_sorted.length > 0 &&
      module_components_up_right_sorted.length > 0 &&
      module_components_up_left_sorted.length == 0
    "
  >
    <div class="col-md-8 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-4 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 40px"
    *ngIf="
      module_components_up_sorted.length > 0 &&
      module_components_up_right_sorted.length == 0 &&
      module_components_up_left_sorted.length > 0
    "
  >
    <div class="col-md-4 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-8 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 40px"
    *ngIf="
      module_components_up_sorted.length > 0 &&
      module_components_up_right_sorted.length > 0 &&
      module_components_up_left_sorted.length > 0
    "
  >
    <div class="col-md-3 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-3 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 40px"
    *ngIf="
      module_components_up_sorted.length == 0 &&
      module_components_up_right_sorted.length > 0 &&
      module_components_up_left_sorted.length > 0
    "
  >
    <div class="col-md-6 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 40px"
    *ngIf="
      module_components_up_sorted.length == 0 &&
      module_components_up_right_sorted.length == 0 &&
      module_components_up_left_sorted.length > 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    style="margin-top: 40px"
    *ngIf="
      module_components_up_sorted.length == 0 &&
      module_components_up_right_sorted.length > 0 &&
      module_components_up_left_sorted.length == 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_up_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <!--================ End Up Positions  ================  -->

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length > 0 &&
      module_components_right_sorted.length > 0 &&
      module_components_middle_sorted.length == 0
    "
  >
    <div class="col-md-6 col-sm-12 col-xs-12">
      <div style=" position: relative">
        <div *ngFor="let item of module_components_right_sorted">
          <ng-container
            *ngTemplateOutlet="components; context: { $implicit: item }"
          ></ng-container>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <div style=" position: relative">
        <div *ngFor="let item of module_components_left_sorted">
          <ng-container
            *ngTemplateOutlet="components; context: { $implicit: item }"
          ></ng-container>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length > 0 &&
      module_components_right_sorted.length > 0 &&
      module_components_middle_sorted.length > 0
    "
  >
    <div class="col-md-3 col-sm-12 col-xs-12">
      <div
        style=" position: relative"
        *ngFor="let item of module_components_left_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12">
      <div
        style=" position: relative"
        *ngFor="let item of module_components_middle_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-3 col-sm-12 col-xs-12">
      <div
        *ngFor="let item of module_components_right_sorted"
        style=" position: relative"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length == 0 &&
      module_components_right_sorted.length == 0 &&
      module_components_middle_sorted.length > 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div
        style=" position: relative"
        *ngFor="let item of module_components_middle_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length == 0 &&
      module_components_right_sorted.length > 0 &&
      module_components_middle_sorted.length > 0
    "
  >
    <div class="col-md-8 col-sm-12 col-xs-12">
      <div
        style=" position: relative"
        *ngFor="let item of module_components_middle_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-4 col-sm-12 col-xs-12">
      <div
        style=" position: relative"
        *ngFor="let item of module_components_right_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length == 0 &&
      module_components_right_sorted.length > 0 &&
      module_components_middle_sorted.length == 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div
        style=" position: relative"
        *ngFor="let item of module_components_right_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length > 0 &&
      module_components_right_sorted.length == 0 &&
      module_components_middle_sorted.length > 0
    "
  >
    <div class="col-md-4 col-sm-12 col-xs-12">
      <div
        style=" position: relative"
        *ngFor="let item of module_components_left_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div class="col-md-8 col-sm-12 col-xs-12">
      <div
        style=" position: relative"
        *ngFor="let item of module_components_middle_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_left_sorted.length > 0 &&
      module_components_right_sorted.length == 0 &&
      module_components_middle_sorted.length == 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div
        style=" position: relative"
        *ngFor="let item of module_components_left_sorted"
      >
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <!--================ Start Down Positions  ================  -->

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length > 0 &&
      module_components_down_right_sorted.length == 0 &&
      module_components_down_left_sorted.length == 0
    "
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      style=" position: relative"
    >
      <div *ngFor="let item of module_components_down_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length > 0 &&
      module_components_down_right_sorted.length > 0 &&
      module_components_down_left_sorted.length == 0
    "
  >
    <div
      class="col-md-8 col-sm-12 col-xs-12"
      style=" position: relative"
    >
      <div *ngFor="let item of module_components_down_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div
      class="col-md-4 col-sm-12 col-xs-12"
      style=" position: relative"
    >
      <div *ngFor="let item of module_components_down_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length > 0 &&
      module_components_down_right_sorted.length == 0 &&
      module_components_down_left_sorted.length > 0
    "
  >
    <div
      class="col-md-4 col-sm-12 col-xs-12"
      style=" position: relative"
    >
      <div *ngFor="let item of module_components_down_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div
      class="col-md-8 col-sm-12 col-xs-12"
      style=" position: relative"
    >
      <div *ngFor="let item of module_components_down_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length > 0 &&
      module_components_down_right_sorted.length > 0 &&
      module_components_down_left_sorted.length > 0
    "
  >
    <div
      class="col-md-3 col-sm-12 col-xs-12"
      style=" position: relative"
    >
      <div *ngFor="let item of module_components_down_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div
      class="col-md-6 col-sm-12 col-xs-12"
      style=" position: relative"
    >
      <div *ngFor="let item of module_components_down_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div
      class="col-md-3 col-sm-12 col-xs-12"
      style=" position: relative"
    >
      <div *ngFor="let item of module_components_down_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length == 0 &&
      module_components_down_right_sorted.length > 0 &&
      module_components_down_left_sorted.length > 0
    "
  >
    <div
      class="col-md-6 col-sm-12 col-xs-12"
      style=" position: relative"
    >
      <div *ngFor="let item of module_components_down_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>

    <div
      class="col-md-6 col-sm-12 col-xs-12"
      style=" position: relative"
    >
      <div *ngFor="let item of module_components_down_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length == 0 &&
      module_components_down_right_sorted.length > 0 &&
      module_components_down_left_sorted.length == 0
    "
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      style=" position: relative"
    >
      <div *ngFor="let item of module_components_down_right_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="
      module_components_down_sorted.length == 0 &&
      module_components_down_right_sorted.length == 0 &&
      module_components_down_left_sorted.length > 0
    "
  >
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div *ngFor="let item of module_components_down_left_sorted">
        <ng-container
          *ngTemplateOutlet="components; context: { $implicit: item }"
        ></ng-container>
      </div>
    </div>
  </div>

  <!--================ End Down Positions  ================  -->
</div>

<ng-template let-item #components>
  <!-- <div class="edit-component" (click)="showDialog('component',item)">
    <button pButton type="button" icon="pi pi-pencil" class="p-button-raised p-button-rounded" style="margin: 5px;"></button>
  </div> -->

  <app-two-image-big-cards
    [article]="item"
    *ngIf="item.id == 93 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
  ></app-two-image-big-cards>

  <!--  no title -->

  <!-- <app-cms-tags-component [article]="item" *ngIf="item.id == 81 && item.hidden == 0 && item.block == 1  " data-aos-duration="2000" ></app-cms-tags-component> -->

  <app-card3-white-bg
    [component]="item"
    *ngIf="item.id == 2464 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="2464"
  ></app-card3-white-bg>



  <app-button-cards4
    [component]="item"
    *ngIf="item.id == 2459 && item.hidden == 0"
    data-aos-duration="2000"
    id="2459"
  ></app-button-cards4>


  <app-button-cards-side
  [component]="item"
  *ngIf="item.id == 2497 && item.hidden == 0"
  data-aos-duration="2000"
  id="2497"
></app-button-cards-side>
  

  <app-img-title-description-card
    [component]="item"
    *ngIf="item.id == 2466 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="2466"
  ></app-img-title-description-card>


  <app-icon-bg-title-desc-card3
    [article]="item"
    *ngIf="item.id == 96 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="96"
  ></app-icon-bg-title-desc-card3>

  <app-cards6-slider
    [article]="item"
    *ngIf="item.id == 129 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="129"
  ></app-cards6-slider>


  <app-web-slider
    [article]="item"
    *ngIf="item.id == 2471 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="2471"
  ></app-web-slider>


  <app-web-slider-new [article]="item" *ngIf="item.id == 2492 && item.hidden == 0 && item.block == 1" id="2492"></app-web-slider-new>
  

  <app-gallery6
    [article]="item"
    *ngIf="item.id == 2475 && item.hidden == 0"
    data-aos-duration="2000"
    id="2475"
  ></app-gallery6>
  <!-- gallery6 added -->

  <app-getintouch
    [article]="item"
    *ngIf="item.id == 125 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="125"
  ></app-getintouch>



  <app-cards-list
    [component]="item"
    *ngIf="item.id == 2462 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="2462"
  ></app-cards-list>
  <app-card-bg-label
    [component]="item"
    *ngIf="item.id == 136 && item.hidden == 0 && item.block == 1"
    id="136"
  >
  </app-card-bg-label>


 

 

  <app-image-title-desc-horizontzal-cards
    [article]="item"
    *ngIf="item.id == 133 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="133"
  >
  </app-image-title-desc-horizontzal-cards>

  <app-image-title-description
    [component]="item"
    *ngIf="item.id == 107 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="107"
  ></app-image-title-description>


  <app-big-video
    [article]="item"
    *ngIf="item.id == 109 && item.hidden == 0 && item.block == 1"
    id="109"
    data-aos-duration="2000"
    id="109"
  ></app-big-video>



  



  <app-advertising
    [component]="item"
    *ngIf="item.id == 2470 && item.hidden == 0 && item.block == 1"
    id="2470"
  ></app-advertising>



  <app-academy-menu
    [article]="item"
    *ngIf="item.id == 137 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="137"
  ></app-academy-menu>
 
  <app-academy-register-no-bg
    [article]="item"
    *ngIf="item.id == 140 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="140"
  ></app-academy-register-no-bg>
 

 


  <app-slider-image-sign-content
    [component]="item"
    *ngIf="item.id == 2469 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="2469"
  >
  </app-slider-image-sign-content>



  <app-stepper
    [component]="item"
    *ngIf="item.id == 2482 && item.hidden == 0 && item.block == 1"
    data-aos-duration="2000"
    id="2482"
  >
  </app-stepper>



  
 


  <app-grid-cards5
    [component]="item"
    *ngIf="item.id == 150 && item.hidden == 0 && item.block == 1"
    id="150"
    data-aos-duration="2000"
    id="150"
  >
  </app-grid-cards5>



 



 

  <app-title-side-description-card
    [article]="item"
    *ngIf="item.id == 147 && item.hidden == 0 && item.block == 1"
    id="147"
  >
  </app-title-side-description-card>

 



 

  <app-cms-component4-general
    [component]="item"
    *ngIf="item.id == 2460 && item.hidden == 0 && item.block == 1"
    id="2460"
  ></app-cms-component4-general>


  <!-- title desc dynamic added -->
  
  <app-images-grid
    [article]="item"
    *ngIf="item.id == 2463 && item.hidden == 0 && item.block == 1"
    id="2463"
  >
  </app-images-grid>

  <app-img-title-dec-grid
    [component]="item"
    *ngIf="item.id == 2465 && item.hidden == 0 && item.block == 1"
    id="2465"
  >
  </app-img-title-dec-grid>

  <app-comments-with-cards
    [component]="item"
    *ngIf="item.id == 212 && item.hidden == 0 && item.block == 1"
    id="212"
  ></app-comments-with-cards>

  <app-image-with-benfits
    [component]="item"
    *ngIf="item.id == 2478 && item.hidden == 0 && item.block == 1"
    id="2478"
    id="2478"
  ></app-image-with-benfits>

  <app-projects3-with-sharp-image
    [article]="item"
    *ngIf="item.id == 2481 && item.hidden == 0 && item.block == 1"
    id="2481"
  >
  </app-projects3-with-sharp-image>

  <app-reviews
    [component]="item"
    *ngIf="item.id == 98 && item.hidden == 0 && item.block == 1"
    id="98"
  >
  </app-reviews>

  <app-facts
    [component]="item"
    *ngIf="item.id == 273 && item.hidden == 0 && item.block == 1"
    id="273"
  >
  </app-facts>

  <app-babysitter-profile
    [component]="item"
    *ngIf="item.id == 2486 && item.hidden == 0 && item.block == 1"
    id="2486"
  >
  </app-babysitter-profile>

  <app-contactus2
    [article]="item"
    *ngIf="item.id == 227 && item.hidden == 0 && item.block == 1"
    id="227"
  >
  </app-contactus2>

  <app-contactus3
    [component]="item"
    *ngIf="item.id == 223 && item.hidden == 0 && item.block == 1"
    id="223"
  ></app-contactus3>

  <app-contactus4
    [component]="item"
    *ngIf="item.id == 220 && item.hidden == 0 && item.block == 1"
    id="220"
  ></app-contactus4>

  <app-logo
    [article]="item"
    *ngIf="item.id == 240 && item.hidden == 0 && item.block == 1"
    id="240"
  ></app-logo>
  <!-- title desc dynamic added -->

  <!-- title desc dynamic added -->


  <app-contact-us-with-image
    [article]="item"
    *ngIf="item.id == 2473 && item.hidden == 0 && item.block == 1"
    id="2473"
  ></app-contact-us-with-image>

  <app-two-image-with-description
    [article]="item"
    *ngIf="item.id == 242 && item.hidden == 0 && item.block == 1"
    id="242"
  ></app-two-image-with-description>

  <app-flags
    [article]="item"
    *ngIf="item.id == 2477 && item.hidden == 0 && item.block == 1"
    id="2477"
  ></app-flags>

  <app-blogs
    [component]="item"
    *ngIf="item.id == 245 && item.hidden == 0 && item.block == 1"
    id="245"
  ></app-blogs>

  <app-videos-slider
    [component]="item"
    *ngIf="item.id == 2487 && item.hidden == 0 && item.block == 1"
    id="2487"
  ></app-videos-slider>
  
  <app-contact-us7
    [component]="item"
    *ngIf="item.id == 248 && item.hidden == 0 && item.block == 1"
    id="248"
  ></app-contact-us7>
  <app-about-us-overview
    [component]="item"
    *ngIf="item.id == 2468 && item.hidden == 0 && item.block == 1"
    id="2468"
  ></app-about-us-overview>

  <app-contactus5
    [article]="item"
    *ngIf="item.id == 253 && item.hidden == 0 && item.block == 1"
    id="253"
  ></app-contactus5>

  <app-date-cards4
    [article]="item"
    *ngIf="item.id == 254 && item.hidden == 0 && item.block == 1"
    id="254"
  ></app-date-cards4>

  <app-about-us3
    [article]="item"
    *ngIf="item.id == 2467 && item.hidden == 0 && item.block == 1"
    id="2467"
  ></app-about-us3>

  <app-faq
    [component]="item"
    *ngIf="item.id == 257 && item.hidden == 0 && item.block == 1"
    id="257"
  ></app-faq>
<app-image-with-two-list
[component]="item"
*ngIf="item.id == 2401 && item.hidden == 0 && item.block == 1"
id="2401">

</app-image-with-two-list>
  <app-contactus6
    [article]="item"
    *ngIf="item.id == 260 && item.hidden == 0 && item.block == 1"
    id="260"
  ></app-contactus6>

  <app-contactus8 [article]="item" *ngIf="item.id == 2493 && item.hidden == 0 && item.block == 1" id="2493">
  </app-contactus8>

  <app-about-us7
  [article]="item"
  *ngIf="item.id == 2494 && item.hidden == 0 && item.block == 1"
  id="2494"
></app-about-us7>

<app-about-us8
[article]="item"
*ngIf="item.id == 2495 && item.hidden == 0 && item.block == 1"
id="2495"
></app-about-us8>

<app-automatic-slider
[article]="item"
*ngIf="item.id == 2496 && item.hidden == 0 && item.block == 1"
id="2496"
></app-automatic-slider>
  
  <app-slider-desc-search
    [article]="item"
    *ngIf="item.id == 264 && item.hidden == 0 && item.block == 1"
    id="264"
  ></app-slider-desc-search>

  <app-cards-filter
    [article]="item"
    *ngIf="item.id == 266 && item.hidden == 0 && item.block == 1"
    id="266"
  ></app-cards-filter>

  <app-images-list
    [article]="item"
    *ngIf="item.id == 2400 && item.hidden == 0 && item.block == 1"
    id="2400"
  ></app-images-list>
  <app-slider-images-title
    [component]="item"
    id="2485"
    *ngIf="item.id == 2485 && item.hidden == 0 && item.block == 1"
  >
  </app-slider-images-title>
  <app-brands-cars
    [article]="item"
    *ngIf="item.id == 2480 && item.hidden == 0 && item.block == 1"
    id="2480"
  ></app-brands-cars>

  <app-image-title
    [component]="item"
    *ngIf="item.id == 2461 && item.hidden == 0 && item.block == 1"
    id="2461"
  ></app-image-title>
  <!-- old id 276 -->

  <app-title-desc-image3
    [component]="item"
    *ngIf="item.id == 2398 && item.hidden == 0 && item.block == 1"
    id="2398"
  ></app-title-desc-image3>
  <!-- <app-card-profile></app-card-profile> -->

  <app-how-to-image-desc
    [article]="item"
    *ngIf="item.id == 2397 && item.hidden == 0 && item.block == 1"
    id="2397"
  ></app-how-to-image-desc>
  
  <app-cards4-objectives-card [article]="item"
  *ngIf="item.id == 134 && item.hidden == 0 && item.block == 1" id="134"></app-cards4-objectives-card>

  <app-gallery
    [article]="item"
    *ngIf="item.id == 119 && item.hidden == 0 && item.block == 1" id="119"
  ></app-gallery>
<app-get-touch
*ngIf="item.id == 2399 && item.hidden == 0 && item.block == 1" id="2399"
 [article]="item"></app-get-touch>
  <app-cards-slider [article]="item" *ngIf="item.id == 2484 && item.hidden == 0 && item.block == 1"  id="2484"></app-cards-slider>
  <app-four-logos-image [article]="item" *ngIf="item.id == 124 && item.hidden == 0 && item.block == 1" id="124"></app-four-logos-image>
</ng-template>

<!-- //////////////////////////////////////////////////////////////////////////////////// -->
<!-- ---------------------------- TEMPLATE COMPONENT UPDATED ----------------------------  -->
<!-- //////////////////////////////////////////////////////////////////////////////////// -->
<!-- 2459 , 2460 , 2466 , 2467 , 122 , 2461 , 129 , 136 , 2463 , 2464 , 2465 , 2462 , 2470 , 2469 , 147 , 2471,2468 -->
