<div
  [appMyStyles]="article"
  [ngStyle]="{ 'background-image': 'url(' + Background_image + ')' }"
  class="Background"
  
  *ngIf="article.main_id != 15771"
>
<app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row"
    
    style="margin: 5px 0px"
  >
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
      <div
        class="card grey-bg"
        (click)="navigateToShow(TitleDescriptionCardData?.id)"
        style="cursor: pointer"
      >
     

        <h5 >
          {{ TitleDescriptionCardData?.title }}
        </h5>
        <p >
          {{ TitleDescriptionCardData?.description }}
        </p>
      </div>
    </div>
  </div>
</div>

<div [appMyStyles]="article" *ngIf="article.main_id == 15771">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row"
    
    style="margin: 5px 0px"
  >
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
      <div
        class="card grey-bg"
        (click)="navigateToShow(TitleDescriptionCardData?.id)"
        style="cursor: pointer; height: 200px"
      >
        <h5
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="TitleDescriptionCardData?.title_detailed"
        ></h5>
        <p
          *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
          [innerHtml]="TitleDescriptionCardData?.description_detailed"
        ></p>

        <h5 *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
          {{ TitleDescriptionCardData?.title }}
        </h5>
        <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
          {{ TitleDescriptionCardData?.description }}
        </p>
      </div>
    </div>
  </div>
</div>
