<div class="container">
  <div class="row" [appMyStyles]="component">
    <app-header-title-desc [article]="component"></app-header-title-desc>
  </div>

  <div class="row" [appMyStyles]="component">
    <div class="col-md-4 col-sm-12 col-xs-12 mb-5"
      [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
      *ngFor="let item of threeDateCardsData | slice : 0 : component.items_number;index as i">
      <div class="card" onMouseOver="this.style.outline='thin website_main_color solid'"
        onMouseOut="this.style.outline='thin transparent solid'">
        <div class="card-img-top" [ngStyle]="{ 'background-image': 'url(' + item?.image + ')' }"></div>



        <div class="top-left">
          <button *ngIf="item?.price != null" class="btn  text-center" [ngStyle]="{
            'background-color': component?.component_buttons[0]?.button_color,
                        color: component?.component_buttons[0]?.button_background_color,
                     
                        'font-size':component?.component_buttons[0]?.button?.font_size,
                        'font-weight':component?.component_buttons[0]?.button?.font_weight
    
          }">
            {{ item?.price }}
          </button>
          <button *ngIf="item?.image_tags != null" class="btn  text-center" [ngStyle]="{
            'background-color': component?.component_buttons[0]?.button_color,
                        color: component?.component_buttons[0]?.button_background_color,
                      
                        'font-size':component?.component_buttons[0]?.button?.font_size,
                        'font-weight':component?.component_buttons[0]?.button?.font_weight
    
          }">
            {{ item?.image_tags }}
          </button>
          <button *ngIf="item?.price == null " class="btn text-center" [ngStyle]="{
            'background-color': component?.component_buttons[0]?.button_color,
                        color: component?.component_buttons[0]?.button_background_color,
                        
                        'font-size':component?.component_buttons[0]?.button?.font_size,
                        'font-weight':component?.component_buttons[0]?.button?.font_weight
    
          }">
            {{ "Free" | translate }}
          </button>
        </div>

        <div class="card-body" [ngStyle]="{'background-color':component?.Style_card_background}">
          <div class="row">
            <!-- <div
              class="col-md-3 col-sm-4 xol-xs-4"
              *ngIf="item?.start_date != ''"
            >
         
              <time
                datetime=""
                class="icon"
                *ngIf="lang.key == 'ar' && item?.start_date != ''"
              >
                <em>{{ item?.start_date | dateFormatter : "Y" : "ar" }}</em>
                <strong>{{
                  item?.start_date | dateFormatter : "MMM" : "ar"
                }}</strong>
                <span [ngStyle]="{ color: website_main_color }">{{
                  item?.start_date | dateFormatter : "D" : "ar"
                }}</span>
              </time>
            
              <time
                datetime=""
                class="icon"
                *ngIf="lang.key == 'en' && item?.start_date != ''"
              >
                <em>
                  {{ item?.start_date | dateFormatter : "Y" : "en" }}
                </em>
                <strong>{{
                  item?.start_date | dateFormatter : "MMM" : "en"
                }}</strong>
                <span [ngStyle]="{ color: website_main_color }">{{
                  item?.start_date | dateFormatter : "D" : "en"
                }}</span>
              </time>
            </div>

            <div
              class="col-md-9 col-sm-9 col-xs-9"
              *ngIf="item?.start_date != ''"
            >
              <div class="event-desc">
                <h4 class="card-title" 
      [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
      >
                  {{ item?.title }}
                </h4>
                <p 
      [ngStyle]="{'font-size':component?.description_size,'text-transform':'lowercase', 'color':component?.description_color,'font-weight':component?.data_desc_weight}"
      >
                  {{ item?.description }}
                </p>
                <a
                  (click)="navigateToShow(item?.id)"
                  class="btn text-light text-center book-btn"
                  [ngStyle]="{
                    'background-color': websiteSecColor,
                    width: '50%'
                  }"
                  >{{ "Book" | translate }}
                </a>
              </div>
            </div> -->
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="event-desc">
                <h4 class="card-title"
                  [ngStyle]="{'font-size':component?.title_size,
        'text-transform':'none'
        ,'color':component?.title_color,'font-weight':component?.data_title_weight}">
                  {{ item?.title }}
                </h4>
                <div class="row">
                  <p class="col-lg-8"
                    [ngStyle]="{'font-size':component?.description_size,'text-transform':'lowercase', 'color':component?.description_color,'font-weight':component?.data_desc_weight}">
                    {{ item?.description }}
                  </p>
                  <button *ngIf="component?.component_buttons?.length>0" [ngStyle]="{
                  'background-color': component?.component_buttons[0]?.button_background_color,
                              color: component?.component_buttons[0]?.button_color,
                              'border-color':component?.component_buttons[0]?.border_color,
                              'font-size':component?.component_buttons[0]?.button?.font_size,
                              'font-weight':component?.component_buttons[0]?.button?.font_weight
          
                }" (click)="navigateToShow(item.id)" class="btn col-lg-3">
                    <ng-container *ngIf="lang.key == 'en'">{{component?.component_buttons[0]?.button_text}}
                    </ng-container>
                    <ng-container *ngIf="lang.key == 'ar'">{{component?.component_buttons[0]?.button_name}}
                    </ng-container>

                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

   
    <app-alert *ngIf="threeDateCardsData?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
    [message]="'InfoAlarm' | translate" [type]="'warning'">
  </app-alert>
  
  </div>
</div>