<div class="position-relative mt-3 py-3" [appMyStyles]="component">
  <!-- Title Section -->
  <app-header-title-desc [appMyStyles]="component" [article]="component"></app-header-title-desc>


  <div class="mt-3 mb-5 position-relative">
    <div class="row p-5" [ngStyle]="{
        'background-image': component?.component_background_image !== 'https://limberx.com/test/limberx_generator_api/public/img/Default_background.png' 
          ? 'url(' + component?.component_background_image + ')' 
          : 'none'
      }"  [appMyStyles]="component"
      [appMyStyles]="component"
    >
      <div
      class="col-lg-3 col-md-6 d-flex flex-column justify-content-center align-items-center text-center"
      *ngFor="let stat of facts | slice: 0 : component?.items_number; index as i; let last = last"
      [ngStyle]="{
        'border-right': currentLang != '2' && !last ? '2px solid ' + component.title_color : null,
        'border-left': currentLang == '2' && !last ? '2px solid ' + component.title_color : null
      }">
        <h3 class="mb-3" [ngStyle]="{
            'font-size': component?.title_size,
            color: component?.title_color,
            'font-weight': component?.data_title_weight,
        'text-transform':'none'

          }">
                   {{ stat?.title | slice:0:20 }}{{ stat.title?.length > 20 ? '...' : '' }}

        </h3>
        <p class="text-truncate" [ngStyle]="{
            'font-size': component?.description_size,
            'text-transform':'lowercase',
            color: component?.description_color,
            'font-weight': component?.data_desc_weight
          }">
          {{ stat?.description | slice:0:20 }}{{ stat.description?.length > 20 ? '...' : '' }}
        </p>
      </div>
    </div>
  </div>
</div>


<app-alert *ngIf="facts?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
[message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>