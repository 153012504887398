import { Component, Input, OnInit } from "@angular/core";

import { DomSanitizer } from "@angular/platform-browser";
import { HomeService } from "../../../general-pages/pages/home/home.service";

import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: 'app-babysitter-profile',
  templateUrl: './babysitter-profile.component.html',
  styleUrls: ['./babysitter-profile.component.scss']
})
export class BabysitterProfileComponent implements OnInit {
  @Input() component: any;
  
  APIParameters;
  facts;
  websiteMainColor: any;
 
  lang: string;
  currentLang: string;

  video_array: any[] = [];

  Button_Color: any;
  Button_Background_Color: any;
  main_id: any;
  websiteSecColor: any;

  col: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,

  ) {}

  ngOnInit(): void {

    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

    this.main_id = this.component.main_id;

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.facts = res.content;
      });
    
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
         
        });
    });
  }

}
