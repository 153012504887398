<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>
<div [appMyStyles]="article">

    <div class="row touch-card mt-5" > 
        <div class="card-desc col-lg-4 col-md-6 col-sm-12" *ngFor="let item of getTouchDetails"
        [ngClass]="[item.image != null ?'big-h':'small-h']"
        >
        <div class="content"
        [ngStyle]="{'boxShadow': article?.Style_border === 0 ? 'none' : '0px 5px 6px 0px #9c9c9c1f',
        'backgroundColor': article?.Style_card_background? article?.Style_card_background: 'transparent'
      }"
        >
  <img [src]="item.image" class="card-icon" *ngIf="item.image">
  <p  [ngStyle]="{
    'font-size': article.title_size,
     color: article.title_color
  }"            
  >{{item.title}}</p>
  <p  [ngStyle]="{
    'font-size': article.description_size,
     color: article.description_color
   }"
  >{{item.description}}</p>
        </div>
        
        </div>
    </div>
  </div>
  