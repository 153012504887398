<div *ngIf="Three_Circles_Data?.length > 0" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row equal-height-container"
    
    [appMyStyles]="article"
  >
    <div class="col-12">
      <div class="d-flex align-items-stretch mb-4">
        <!-- Left Side Items -->
        <div class="col-md-5 col-12 d-flex flex-column">
          <div
            *ngFor="let item of Three_Circles_Data | slice: 0: leftChunkLength; let i = index"
            class="specialty-item flex-grow-1 d-flex flex-column mb-4"
            [ngClass]="i % 2 === 0 ? 'order-md-1' : 'order-md-3'"
          >
            <div class="circle-container mx-auto mb-3">
              <div class="circle-number">
                {{ i + 1 < 10 ? '0' + (i + 1) : (i + 1) }}
              </div>
            </div>
            <h6
              class="px-4 mt-auto mb-4"
              [ngStyle]="{
                'text-transform':'none',

                'font-size': article?.title_size,
                color: article?.title_color,
                'font-weight': article?.data_title_weight
              }"
              [ngClass]="[article?.Style_content_position == '0' ? 'text-center' : 'text-start']"
            >
              {{ item.title }}
            </h6>
            <p
              class="desc-details mt-auto"
              [ngStyle]="{
                'font-size': article?.description_size,
                'text-transform':'none',
                color: article?.description_color,
                'font-weight': article?.data_desc_weight
              }"
              [ngClass]="[article?.Style_content_position == '0' ? 'text-center' : 'text-start']"
            >
              {{ item.description }}
            </p>
          </div>
        </div>

        <!-- Dotted Line -->
        <div class="col-md-2 text-center d-flex align-items-center">
          <div class="dotted-line mx-auto"></div>
        </div>

        <!-- Right Side Items -->
        <div class="col-md-5 col-12 d-flex flex-column">
          <div
            *ngFor="let item of Three_Circles_Data | slice: leftChunkLength:Three_Circles_Data.length; let i = index"
            class="specialty-item flex-grow-1 d-flex flex-column mb-4"
            [ngClass]="i % 2 !== 0 ? 'order-md-1' : 'order-md-3'"
          >
            <div class="circle-container mx-auto mb-3">
              <div class="circle-number">
                {{ i + leftChunkLength + 1 < 10 ? '0' + (i + leftChunkLength + 1) : (i + leftChunkLength + 1) }}
              </div>
            </div>
            <h6
              class="px-4 mt-auto mb-4"
              [ngStyle]="{
                'text-transform':'none',

                'font-size': article?.title_size,
                color: article?.title_color,
                'font-weight': article?.data_title_weight
              }"
              [ngClass]="[article?.Style_content_position == '0' ? 'text-center' : 'text-start']"
            >
              {{ item.title }}
            </h6>
            <p
              class="desc-details mt-auto"
              [ngStyle]="{
                'font-size': article?.description_size,
                'text-transform':'none',

                color: article?.description_color,
                'font-weight': article?.data_desc_weight
              }"
              [ngClass]="[article?.Style_content_position == '0' ? 'text-center' : 'text-start']"
            >
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- No Data Fallback -->
<div
  *ngIf="!Three_Circles_Data?.length"
  class="alert alert-warning text-center mt-4"
>
  <p>No data available for the three circles.</p>
</div>
