<section
  class="faq__header"
  
>
  <h1 class="faq__header__title">{{ "Ask Us" | translate }} ?</h1>
  <h1 class="faq__header__desc">{{ "FAQ" | translate }}</h1>
  <div class="faq__header__border">
    <!-- [ngStyle]="{
      right: language == 'ar' ? '10%' : 'unset',
      left: language == 'en' ? '10%' : 'unset'
    }" -->
    <div class="faq__header__border__item"></div>
  </div>
</section>
