import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from "../../shared_services/navigation.service";

import { HomeService } from 'src/app/general-pages/pages/home/home.service';
import { LangService } from '../../shared_services/lang.service';

@Component({
  selector: 'app-card-bg-label',
  templateUrl: './card-bg-label.component.html',
  styleUrls: ['./card-bg-label.component.scss']
})
export class CardBgLabelComponent implements OnInit {

  @Input() component: any;

  APIParameters;

  threeDateCardsData;

  website_main_color: any;

  websiteSecColor: any;



  side_related_items;

  relateditems_side;

  relateditems_side_title;

  Hide_Description;

  currentLang;


  side_1_data;

  side1_Title;

  side_2_data;

  side2_Title;

  Items_Number;

  lang: { key: String; value: String } = { key: "en", value: "EN" };

  title_font_color;
  title_font_size;

  desc_font_color;
  desc_font_size;

  component_sub_title;

  hide_title;
  hide_description;

  component_title_position;
  html_content: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    public sanitizer: DomSanitizer,
    private navigationService:NavigationService

  ) {
    this.langService.getLang().subscribe((lang) => {
      this.lang = lang;
    });
  }

  ngOnInit(): void {
    // this.lang = localStorage.getItem("container_lang");

    this.website_main_color = JSON.parse(localStorage.getItem("mainColor"));

    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Hide_Description = localStorage.getItem("HideDescription");
    this.Items_Number = this.component.items_number;

    this.title_font_color = this.component.title_font_color;
    this.title_font_size = this.component.title_font_size;

    this.desc_font_color = this.component.desc_font_color;
    this.desc_font_size = this.component.desc_font_size;

    this.hide_description = this.component.show_cms_description;
    this.hide_title = this.component.show_cms_title;

    this.component_sub_title = this.component.component_description;
    this.component_title_position = this.component.component_title_position;

    this.html_content = this.component.text_as_html;

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.threeDateCardsData = res.content;
       

        this.side_related_items = res.content[0].related_items;
        if(this.side_related_items && this.side_related_items.length>0)
        this.side_related_items.forEach((element) => {
          if (element.position == "side") {
            this.relateditems_side = element.data;
            this.relateditems_side_title = element.title;
          }
        });
        console.log("side", res);
        
      });

    
    this.side1_Title = JSON.parse(localStorage.getItem("side1-title"));
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.side_1_data = res.content;
        this.side1_Title = res.meta_data.module_name;
      });

   
    this.side2_Title = JSON.parse(localStorage.getItem("side2-title"));
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.side_2_data = res.content;
        this.side2_Title = res.meta_data.module_name;
      });

    this.currentLang = localStorage.getItem("lang");

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.APIParameters)
        .subscribe((res: any) => {
          this.threeDateCardsData = res.content;
    

          this.side_related_items = res.content[0].related_items;

          this.side_related_items.forEach((element) => {
            if (element.position == "side") {
              this.relateditems_side = element.data;
              this.relateditems_side_title = element.title;
            }
          });
        });
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.side_1_data = res.content;
          this.side1_Title = res.meta_data.module_name;
        });
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.side_2_data = res.content;
          this.side2_Title = res.meta_data.module_name;
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.component,id,2);

  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
