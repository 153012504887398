<div
  [appMyStyles]="article"
  class="container-fluid project-container"
>
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="row m-4 brand-container"
    
  >
    <div
      *ngFor="
        let item of (article?.items_number
          ? (content | slice: 0 : article?.items_number)
          : content);
        index as i
      "
      class="col-md-4 col-sm-6 text-center project-item mb-4"
    >
      <div class="project-image-container">
        <img
          [ngClass]="
            article.Style_image_shape == '0'
              ? 'slider-img-circle'
              : 'slider-img-square'
          "
          src="{{ item.image }}"
          alt="{{ item.title }}"
          class="project-image"
        />
        <div class="project-title-container">
          <p class="project-title"
          [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}">{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
