<div [appMyStyles]="article">

    <div
    class="row"
    
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      style="text-align: center"
      *ngIf="component_title_position == 'center'"
    >
      <!-- <h4 *ngIf="lang ==  'en_container' " class="component-title-left"   [ngStyle]="{'border-left-color':websiteMainColor}" >{{ComponentTitle}}</h4> -->
      <!-- <h4 *ngIf="lang ==  'ar_container' " class="component-title-right"  [ngStyle]="{'border-right-color':websiteMainColor}" >{{ComponentTitle}}</h4> -->
      <h1
        class="main-title text-center padd"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h1>
      <h6
        [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }"
        style="margin-bottom: 30px"
      >
        {{ component_sub_title }}
      </h6>
    </div>
  
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      *ngIf="component_title_position != 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        [ngStyle]="{
          'border-left-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6
        [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }"
        style="margin-bottom: 30px"
      >
        {{ component_sub_title }}
      </h6>
    </div>
    </div>
  
    <div
      class="row padd"
      
    >

  
      <div  class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;align-items:center">
        <div class="card">
          <img class="small-img" src="{{Contact_Data[0].image}}" />
          <h6>{{'Sales'|translate}}</h6>

          <span style="display: flex;">
            <i class="fa fa-phone" aria-hidden="true" style="padding: 3px 10px ;"></i>
            <p  [innerHtml]="Contact_Data[0].title_detailed"></p>
          </span> 
         
          <span style="display: flex;"> 
            <i class="fa fa-envelope" aria-hidden="true" style="padding: 3px 10px ;"></i>
            <p  [innerHtml]="Contact_Data[0].description_detailed"></p>       
         </span> 
              
      </div>
      </div>
  
      <div class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;align-items:center">
        <div class="card">
          <img class="small-img" src="{{Contact_Data[1].image}}" />
          <h6>{{'Technical support'|translate}}</h6>
          
        <span style="display: flex;">
          <i class="fa fa-phone" aria-hidden="true" style="padding: 3px 10px ;"></i>
          <p  [innerHtml]="Contact_Data[1].title_detailed"></p>
        </span>
        <span style="display: flex;"> 
          <i class="fa fa-envelope" aria-hidden="true" style="padding: 3px 10px ;"></i>
          <p  [innerHtml]="Contact_Data[1].description_detailed"></p>       
      </span>
        </div>
      </div>
  
      <div  class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;align-items:center">
        <div class="card">
          <img class="small-img" src="{{Contact_Data[2].image}}" />
          <h6>{{'Accounts'|translate}}</h6>
          
          <span style="display: flex;">
            <i class="fa fa-phone" aria-hidden="true" style="padding: 3px 10px ;"></i>
            <p  [innerHtml]="Contact_Data[2].title_detailed"></p>
          </span>
          <span style="display: flex;"> 
            <i class="fa fa-envelope" aria-hidden="true" style="padding: 3px 10px ;"></i>
            <p  [innerHtml]="Contact_Data[2].description_detailed"></p>       
        </span>
        </div>
      </div>
  
  
      <div class="col-md-6 col-sm-12 col-xs-12">
        <form
          class="custom full-width form-padding"
          [formGroup]="contactForm"
          (ngSubmit)="submit(contactForm.value)"
          enctype="multipart/form-data"
        >
          <div class="form-group">
            <input
              type="text"
              formControlName="name"
              class="form-control"
              placeholder="{{ 'Name' | translate }}"
            />
            <span
              *ngIf="
                contactForm.get('name').touched && contactForm.get('name').invalid
              "
            >
              <small
                *ngIf="contactForm.get('name').errors.required"
                class="form-text required"
              >
                {{ "VALIDATORS.REQUIRED" | translate }}
              </small>
            </span>
          </div>
  
          <div class="form-group">
            <input
              type="email"
              formControlName="email"
              class="form-control"
              placeholder="{{ 'Email' | translate }}"
            />
            <!--Validation-->
            <span
              *ngIf="
                contactForm.get('email').touched && contactForm.get('email').invalid
              "
            >
              <small
                *ngIf="contactForm.get('email').errors.required"
                class="form-text required"
              >
                {{ "VALIDATORS.REQUIRED" | translate }}
              </small>
              <small
                *ngIf="contactForm.get('email').errors.pattern"
                class="form-text required"
              >
                {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
              </small>
            </span>
          </div>
  
          <div class="form-group">
            <input
              type="text"
              (keypress)="acceptOnlyNumbers($event)"
              formControlName="mobile"
              maxlength="11"
              class="form-control Numbers-font"
              placeholder="{{ 'Mobile' | translate }}"
            />
  
            <span
              *ngIf="
                contactForm.get('mobile').touched &&
                contactForm.get('mobile').invalid
              "
            >
              <small
                *ngIf="contactForm.get('mobile').errors.required"
                class="form-text required"
              >
                {{ "VALIDATORS.REQUIRED" | translate }}
              </small>
              <small
                *ngIf="contactForm.get('mobile').errors.pattern"
                class="form-text required"
              >
                {{ "phoneLength" | translate }}
              </small>
            </span>
          </div>
  
          <div class="form-group">
            <textarea
              class="form-control"
              formControlName="message"
              placeholder="{{ 'GENERAL_PAGES.CONTACT.MESSAGE' | translate }}"
            ></textarea>
          </div>
  
          <button
            type="button"
            class="btn-custom"
            type="submit"
            [ngStyle]="{
              'background-color': Button_Background_Color,
               color: Button_Color,
              'border-color': Button_Color
            }"
          >
            {{ "GENERAL_PAGES.CONTACT.SEND" | translate }}
          </button>
        </form>
      </div>
  
      <div class="col-md-6 col-sm-12 col-xs-12">
        <agm-map [latitude]="mapLatitude" [longitude]="mapLongitude" [zoom]="mapZoom">
            <agm-marker
              *ngFor="let loc of Contact_Data"
              [latitude]="loc.latitude"
              [longitude]="loc.longitude"
            ></agm-marker>
          </agm-map>
     </div>
  
    </div>
  
  </div>
  
  
  