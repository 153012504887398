import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
  
})
export class StepperComponent implements OnInit {

 constructor(
     private homeService: HomeService,
     private translateService: TranslateService
   ) {}
 @Input() component: any;
 isLinear = false;
 steps = [];
currentStep = 0;
lang: string;
websiteSecColor: string;

ngOnInit(): void {
  this.lang = localStorage.getItem("container_lang");
  if(this.lang=='ar_container')this.currentStep=2;
  this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

  this.homeService
    .getHomeComponentsData(this.component)
    .subscribe((res: any) => {
      this.steps = res.content;
    });

  this.translateService.onLangChange.subscribe((res) => {
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.steps = res.content;
        
      });
  });
}

}
