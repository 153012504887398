import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { NavigationService } from "../../shared_services/navigation.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-about-us3',
  templateUrl: './about-us3.component.html',
  styleUrls: ['./about-us3.component.scss']
})
export class AboutUs3Component implements OnInit {
  @Input() article: any;

  TwoTitleDescriptionCardData;
  lang: string;
  currentLang: string;
  websiteSecColor: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService

  ) {}

  ngOnInit(): void {
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.currentLang = localStorage.getItem("container_lang");
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.TwoTitleDescriptionCardData = res.content;
        console.log(this.article);
        
      });
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.TwoTitleDescriptionCardData = res.content;
        });
    });
  }
  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);
  }

}
