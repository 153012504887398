import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "src/app/general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";

@Component({
  selector: "app-automatic-slider",
  templateUrl: "./automatic-slider.component.html",
  styleUrls: ["./automatic-slider.component.scss"],
})
export class AutomaticSliderComponent implements OnInit {
  @Input() article: any;

  ImageTitleData;

  lang: string;
  currentLang: string;
  outData: any;
  i: number = 0;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
      });
    console.log("component ", this.article);

    this.outData = this.ImageTitleData;
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.ImageTitleData = res.content;
        });
    });
  }
  next() {
    if (this.ImageTitleData.length < this.i + 4) {
    } else {
      this.i = this.i + 1;
    }
  }

  prev() {
    if (this.i <= 0) {
    } else {
      this.i = this.i - 1;
    }
  }
}
