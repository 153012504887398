<div class="container-fluid" [appMyStyles]="component">
    <div class="row" [appMyStyles]="component">
      <app-header-title-desc [article]="component"></app-header-title-desc>
    </div>
  
    <div class="card-container">
      <div
        class="card mb-5"
        [ngClass]="{ larger: i === 1 }"
        
        *ngFor="let item of threeDateCardsData | slice : 0 : 3; let i = index"
      >
        <div
         
          onMouseOver="this.style.outline='thin website_main_color solid'"
          onMouseOut="this.style.outline='thin transparent solid'"
        >


  
          <div class="top-left">
            <button *ngIf="item?.price != null" class="btn  text-center" 
             [ngStyle]="{
                'background-color':websiteSecColor}">
            {{ item?.price }}
          </button>
          <button *ngIf="item?.image_tags != null" class="btn  text-center" [ngStyle]="{
                'background-color':websiteSecColor}">
            {{ item?.image_tags }}
          </button>
          <!-- <button *ngIf="item?.price == null && item?.image_tags == null" class="btn text-center"
            [ngStyle]="{
                'background-color':websiteSecColor,
                'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}">
            {{ "Free" | translate }}
          </button> -->
        </div>

        <div class="card-body" [ngStyle]="{ 'background-image': 'url(' + item?.image + ')' , 'background-color':component.Style_card_background }">

          <div class="event-desc">
            <h4 class="card-title"
              [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,
        'text-transform':'none',
        'font-weight':component?.data_title_weight}">
              {{ item?.title }}
            </h4>
            <p
              [ngStyle]="{'font-size':component?.description_size,'text-transform':'lowercase', 'color':component?.description_color,'font-weight':component?.data_desc_weight}">
              {{ item?.description }}
            </p>

          </div>
        </div>
      </div>
    </div>

    <app-alert *ngIf="threeDateCardsData?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
    [message]="'InfoAlarm' | translate" [type]="'warning'">
  </app-alert>
  </div>
</div>