
<div class="video-container">
  <!-- Title (if available) -->
  <div
  [appMyStyles]="article"
>
<app-header-title-desc [article]="article"></app-header-title-desc>
</div>    
  <div class="youtube-container">
    <!-- Video player (shown after clicking play) -->
    <div *ngIf="showVideo && safeUrl" class="youtube-player">
      <iframe [src]="safeUrl" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen>
      </iframe>
    </div>
    
    <!-- Thumbnail with play button (shown initially) -->
    <div *ngIf="!showVideo && videoId" class="youtube-thumbnail" (click)="playVideo()">
      <!-- YouTube thumbnail image - direct link to ensure it works -->
      <div class="thumbnail-wrapper">
        <img [src]="'https://img.youtube.com/vi/' + videoId + '/0.jpg'" alt="Video thumbnail">
      </div>
      
      <!-- Play button overlay -->
      <div class="play-button">
        <div class="play-circle">
          <div class="play-triangle"></div>
        </div>
      </div>
    </div>
  </div>
</div>