import { BaseHttpService } from "src/app/services/base-http.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CommonGeneralService {
  constructor(
    private baseHttpService: BaseHttpService,
    private httpClient: HttpClient
  ) {}

  getwebsitedata() {
     
    return this.baseHttpService.post(
      "/api/applications/show",
      {
        id: localStorage.getItem("Application_ID"),
        // "id": 33,
        lang_id: localStorage.getItem("lang"),
      },
      {}
    );
  }

  getFooterSocialLinks() {
    return this.baseHttpService.post(
      "/api/social_media_values",
      {
        // "module_id":420,
        application_id: localStorage.getItem("Application_ID"),
        lang_id: localStorage.getItem("lang"),
      },
      {}
    );
  }

  HomeSearch(search) {
    let baseEventsObject = {
      application_id: localStorage.getItem("Application_ID"),
      lang_id: localStorage.getItem("lang"),
      text: search,
    };
    return this.baseHttpService.post("/api/search", baseEventsObject, {});
  }

  getContactData() {
    return this.baseHttpService.post(
      "/api/contact_details",
      {
        // "id": 10,
        application_id: localStorage.getItem("Application_ID"),
        lang_id: localStorage.getItem("lang"),
      },
      {}
    );
  }

  getWhoWeAre() {
    return this.baseHttpService.post(
      "/api/pages/show",
      {
        id: 8,
      },
      {}
    );
  }

  getMission() {
    return this.baseHttpService.post(
      "/api/pages/show",
      {
        id: 11,
      },
      {}
    );
  }

  getVision() {
    return this.baseHttpService.post(
      "/api/pages/show",
      {
        id: 10,
      },
      {}
    );
  }

  getGoals() {
    return this.baseHttpService.post(
      "/api/pages/show",
      {
        id: 12,
      },
      {}
    );
  }

  submitContactForm(body) {
    //  
    //  
    // return this.baseHttpService.post('/api/contact_us/store', {body}, {})

    return this.httpClient.post<any>(
      `https://limberx.com/test/limberx_generator_api/public/api/contact_us/store`,
      body
    );
  }
  
  getContactMessageTitle() {
    return this.baseHttpService.post(
      "/api/contact_us_messages",
      {
        application_id: localStorage.getItem("Application_ID"),
        lang_id: 1,
      },
      {}
    );
  }

  getFriendlyWebsiteslist() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 430,
      },
      {}
    );
  }

  getPrivacyPolicy() {
    return this.baseHttpService.post(
      "/api/pages/show",
      {
        id: 17,
      },
      {}
    );
  }

  getTerms() {
    return this.baseHttpService.post(
      "/api/pages/show",
      {
        id: 16,
      },
      {}
    );
  }

  getVisitors() {
    return this.baseHttpService.get("/api/visits");
  }
  decreaseFontSize(fontSize: string): string {
    // Parse the numeric part, subtract 2, and append 'px' back
    const numericValue = parseInt(fontSize, 10);
    return (numericValue - 4) + 'px';
  }
}
