import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";
import { DivideRowStyleService } from "../../shared_services/divide-row-style.service";

@Component({
  selector: 'app-date-cards4',
  templateUrl: './date-cards4.component.html',
  styleUrls: ['./date-cards4.component.scss']
})
export class DateCards4Component implements OnInit {

  @Input() article: any;
  ThreeDateCardsData;
  lang;

  side_related_items;

  relateditems_side;
  relateditems_side_title;

  currentLang;
  col: string;
  side_1_apiparameters;
  side_2_apiparameters;
  side_1_data;
  side1_Title;
  side_2_data;
  side2_Title;

  Background;

  constructor(
    private homeService: HomeService,
    private navigationService:NavigationService,
    public sanitizer: DomSanitizer,
    private colStyleService:DivideRowStyleService

  ) {}

  ngOnInit(): void {
    console.log(this.article);
    
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    if(this.article.component_background){
      this.Background = this.article.component_background
    }
    else {
      this.Background == 0;
    }
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.ThreeDateCardsData = res.content;
        this.col=  this.colStyleService.getColClass(res.content, this.article); 
        console.log(this.col);
      });
   
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }



}
