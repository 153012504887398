
<nav class="navbar navbar-expand-lg navbar-dark" [ngStyle]="{ 'background-color': Header_Bg_Color }"> 
  <a class="navbar-brand" [ngStyle]="{'width':website_logo?'unset':'50px'}">
     <img  alt="" class="logo" [src]="website_logo" *ngIf="website_logo" />     
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <ng-container *ngFor="let item of Menu_items_sorted; let i = index">
        <li
          class="nav-item"
          *ngIf="item.menu_design.header == 1"
        >
          <a
            (mouseover)="hoverHeaderItem = i"
            (mouseleave)="hoverHeaderItem = -1"
            [ngStyle]="{
              'color':  hoverHeaderItem == i || itemSelectedFromHeader == item.name ? websiteSecColor : Header_Color,
             'font-size' : Header_font_size ,
              'white-space': 'nowrap',
              'text-transform': Text_Style,
              'font-family':text_type,
              'font-weight': text_weight
             
            }"
            class="nav-link"
            [routerLinkActive]="'active-link'"
            (click)="navigatetoModule(item)"
            >{{ item.name }}
          </a>
        </li>
       
      </ng-container>
      <li class="nav-item">
        <a class="btn nav-btn" href="#"
        [ngStyle]="{'background-color':websiteSecColor,'border-color': websiteSecColor , 'text-transform': Text_Style ,
        'font-family':text_type,
        'font-weight': text_weight,
        'color':websiteMainColor
        }">{{'Register'|translate}}</a>
      </li>
      <li class="nav-item">
        <a class="btn nav-btn" [ngStyle]="{'background-color':Header_Color,'border-color': websiteMainColor , 'text-transform': Text_Style ,
        'font-family':text_type,
        'font-weight': text_weight,
        'color':websiteSecColor
        }" href="#">{{'Login'| translate}}</a>
      </li>

    </ul>
    <div class="form-inline my-2 my-lg-0 lang-section">
    
      <i class="fa fa-thin fa-globe p-1" aria-hidden="true"  [ngStyle]="{
          color: websiteSecColor }"></i>
      <a 
      (click)="lang.key == 'ar' ? changeLang('en') : changeLang('ar')"
        [ngStyle]="{
          color: websiteSecColor,
         'font-size' : Header_font_size ,
         
         'font-family':text_type,
         'font-weight': text_weight,
         'white-space': 'nowrap'
         }"> {{ 'languageType'  | translate}} </a>
    
    </div>
  </div>
</nav>