import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { NavigationService } from "../../shared_services/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { DivideRowStyleService } from "../../shared_services/divide-row-style.service";

@Component({
  selector: 'app-about-us7',
  templateUrl: './about-us7.component.html',
  styleUrls: ['./about-us7.component.scss']
})
export class AboutUs7Component implements OnInit {
  @Input() article: any;

  TwoTitleDescriptionCardData;
  lang: string;
  currentLang: string;
  websiteSecColor: any;

  col: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService,
    private colStyleService:DivideRowStyleService
  ) {}

  ngOnInit(): void {
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
    this.currentLang = localStorage.getItem("container_lang");
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.TwoTitleDescriptionCardData = res.content;
        this.col=  this.colStyleService.getColClass(res.content, this.article);
      });
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.TwoTitleDescriptionCardData = res.content;
        });
    });


  }
  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,1);
  }

}
