import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-images6',
  templateUrl: './images6.component.html',
  styleUrls: ['./images6.component.scss']
})
export class Images6Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
