import * as $ from "jquery";

import { ActivatedRoute, Router } from "@angular/router";
import { Component, HostListener, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { CommonGeneralService } from "../../../general-pages/pages/services/common-general.service";
import { LangService } from "../../shared_services/lang.service";
import { MenuService } from "../../../services/General/menu.service";
import { NotificationsService } from "../../../shared/shared_services/notifications.service";
import { PusherService } from "src/app/services/pusher/pusher.service";
import { ToastrService } from "ngx-toastr";
import { BehaviourSubjectService } from "src/app/services/behaviour-subject.service";

@Component({
  selector: "app-academy-menu",
  templateUrl: "./academy-menu.component.html",
  styleUrls: ["./academy-menu.component.scss"],
})
export class AcademyMenuComponent implements OnInit {
  @Input() article: any;

  overlay = false;
  viewRegister = false;

  lang: { key: String; value: String } = { key: "en", value: "EN" };
  mylogin: any;
  myprofile;
  token;
  notificationList: any[] = [];
  notification_count: number = 0;

  searchQuery: string;
  searchRes: any[] = [];
  searchSubscription: any;

  currentPage: number = 1;

  check_readnotification: any;
  MenuItems;
  APP_ID;
  website_logo;
  websiteMainColor;
  websiteSecColor;
  footer_logo: any;
  footer_bg_color: any;
  footer_color: any;
  side_component: any[] = [];
  isHidden;
  param;

  status: boolean = false;
  hoverHeaderItem: number;
  itemSelectedFromHeader: string = "";
  Header_Bg_Color: any;
  Header_Color: any;
  Header_font_size: any;
  Text_Style: any;
  text_weight: any;
  text_type: any;
  menu: any;
  isMobile = window.innerWidth < 992; // Check initial screen size

  @HostListener('window:resize', [])  // Listen to window resize events
  onResize(): void {
    this.isMobile = window.innerWidth < 992; // Update the isMobile variable
  }
  constructor(
    protected toastr: ToastrService,
    protected router: Router,
    protected notifi: NotificationsService,
    protected pusherService: PusherService,
    protected menuService: MenuService,
    protected service: CommonGeneralService,
    protected route: ActivatedRoute,
    protected langService: LangService,
    protected behaviorService:BehaviourSubjectService
    
  ) {
    this.langService.getLang().subscribe((lang) => {
      this.lang = lang;
    });
    this.isHidden = true;
    this.APP_ID = localStorage.getItem("Application_ID");
    this.pusherService.channel.bind("new-notification", (data) => {
      this.toastr.success(data.content.notification_text);
      // this.notificationsList.push(data);
    });

    this.token = localStorage.getItem("login_token");
    // this.getNotifications();

    if (this.APP_ID != null) {
      this.menuService.GetMenuItems().subscribe((res: any) => {
        this.MenuItems = res.Items;
        this.menu=res;
         
        this.itemSelectedFromHeader = this.MenuItems[0].name;

        this.websiteMainColor = res.Design.primary_color;
        this.websiteSecColor = res.Design.secondary_color;

        this.footer_logo = res.Design.Footer_Logo;
        this.footer_bg_color = res.Design.footer_background_color;
        this.footer_color = res.Design.footer_color;
        this.Header_Bg_Color = res.Design.header_background_color;
        this.Header_Color = res.Design.header_color;
        this.Header_font_size = res.Menu.header_font_size;

        this.Text_Style = res.Design.text_style;
        this.text_weight =  res.Design.text_weight;
        this.text_type = res.Design.text_type;

        localStorage.setItem(
          "mainColor",
          JSON.stringify(this.websiteMainColor)
        );
        localStorage.setItem(
          "secColor",
          JSON.stringify(this.websiteSecColor)
        );

        localStorage.setItem("footer_logo", JSON.stringify(this.footer_logo));
        localStorage.setItem("footer_color", JSON.stringify(this.footer_color));
        localStorage.setItem(
          "footer_bg_color",
          JSON.stringify(this.footer_bg_color)
        );

        //  
      });
    }

    this.service.getwebsitedata().subscribe((res: any) => {
      this.website_logo = res.content[0].logo;
       
    });

    this.route.queryParams.subscribe((params: any) => {
      this.param = params["query"];

      if (this.param != "") {
        this.searchQuery = "";
      } else {
      }
    });
  }

  ngOnInit(): void {}

  openMenu() {
    this.overlay = true;
    document.documentElement.style.overflow = "hidden";
  }

  closeMenu() {
    this.overlay = false;
    document.documentElement.style.overflow = "auto";
  }

  register(flag) {
    this.viewRegister = flag;
  }

  changeLang(lang: string) {
    this.langService.changeLang(lang);
    window.location.replace(`${lang}/${this.router.url.slice(4)}`);
  }

  Logout() {
    localStorage.removeItem("login_token");
    localStorage.removeItem("login_assoc");
    localStorage.removeItem("association_id");
    localStorage.removeItem("Application_ID");

    if (this.lang.key == "en") {
      this.toastr.success("  You Have Been Logout Suceesfully ");
    } else {
      this.toastr.success("لقد تم تسجيل الخروج بنجاح");
    }

    window.location.replace("/cms");
  }

  getNotifications() {
    this.notifi.getNotificationsList().subscribe((res: any) => {
      this.notificationList = res.content;
      this.notificationList.forEach((element) => {
        if (!element.read_at) {
          this.notification_count += 1;
        }
      });
    });
  }

  public readselected(item) {
    document.getElementById("notifi").classList.toggle("read");
    if (item.displayed_at == null) {
      this.notifi.ReadNotificationList(item.id).subscribe((res: any) => {
        this.notification_count -= 1;
        this.langService.getLang().subscribe((res) => {
          this.router.navigate([`${res.key}/user-area/orders`]);
        });
      });
    } else {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/user-area/orders`]);
      });
    }
  }

  clickEvent() {
    this.status = !this.status;
  }

  ResetSearch() {
    this.searchQuery = "";

    this.isHidden = true;
  }
  closesearch() {
    document.getElementById("togglesearch").style.display = "none";
  }

  search() {
    this.isHidden = false;

    if (this.searchQuery) {
      this.status = false;
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/search`], {
          queryParams: {
            query: this.searchQuery,
          },
        });
      });
    } else {
      return;
    }
  }

  FixTheStyle() {
    $("body").removeClass(" nb-theme-default");
  }

  navigatetoModule(item) {
    this.itemSelectedFromHeader = item.name;

    $("body").removeClass(" nb-theme-default");

    localStorage.setItem("apiparameter", JSON.stringify(item.flow[0]));
    localStorage.setItem("sublist", JSON.stringify(item.flow[1]));
    localStorage.setItem("lastScreen", JSON.stringify(item.flow.length - 1));
    localStorage.setItem(
      "sublist_moduleNo",
      JSON.stringify(item.flow[item.flow.length - 1].parameters.module_id)
    );

    localStorage.setItem("perview", JSON.stringify(item.flow[0].action_id));

    localStorage.setItem("ModuleID", JSON.stringify(item.name));
    localStorage.setItem("ModuleNo", JSON.stringify(item.module_id));

    // this.router.navigate(['/cmsforms'], { queryParams: { formparams:JSON.parse( item.flow[0]) } });

    if (item.flow[0].layout_id == 32 || item.flow[0].layout_id == 1) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 1,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list1_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show1_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List1_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 33 || item.flow[0].layout_id == 2) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 2,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list2_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show2_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List2_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 31) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 3,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list3_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show3_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List3_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 34) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 4,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list4_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show4_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List4_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 35) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 5,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list5_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show5_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List5_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 36) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 6,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list6_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show6_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List6_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 37) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 7,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list7_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show7_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List7_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 38) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 8,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });

      localStorage.setItem(
        "list8_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show8_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List8_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 59) {
      // Go to General Pages Module
      this.langService.getLang().subscribe((res) => {
        this.behaviorService.updateMenuItem(item.id);

        this.router.navigate([`${res.key}/cms/general`], {
          queryParams: { module: JSON.parse(item.id) },
        });
      });
    } else if (item.flow[0].layout_id == 29) {
      localStorage.setItem(
        "Home_components",
        JSON.stringify(item.flow[0].components)
      );
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/home`]);
      });
    } else if (item.flow[0].layout_id == 30) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cmspages/contactus`]);
      });
    } else if (item.flow[0].layout_id == 2459) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/info`], {
          queryParams: { module: JSON.parse(localStorage.getItem("ModuleNo")) },
        });
      });
    } else if (item.flow[0].layout_id == 58) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: { module: JSON.parse(localStorage.getItem("ModuleNo")) },
        });
      });

      localStorage.setItem(
        "General_list_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("general_List_Title", JSON.stringify(item.title));
    }

    // else if (item.flow[0].name == "الاخبار" && item.flow[0].layout_id == 2){
    //   // Go to Forms  Module
    //   this.router.navigate(['/cmsforms'], { queryParams: { formparams: JSON.parse(item.flow[0].flow[1]) } });

    // }
  }

  navigateToForms() {
    $("body").removeClass(" nb-theme-default");
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cmsforms`], {
        queryParams: { formparams: localStorage.getItem("form") },
      });
    });
  }
}
