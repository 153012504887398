<div class="row" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>

<div class="cards-slider" 
  *ngIf="cards_slider_data.length > 0" [appMyStyles]="article" style="margin-top: 20px;">

  <p-carousel
  *ngIf="cards_slider_data.length > 0"
  [value]="slides"
  [circular]="false"
  [autoplayInterval]="5000"
  [numVisible]="visible"
  [numScroll]="scroll"
  [responsiveOptions]="responsiveOptions"
>
  <ng-template let-slide pTemplate="item">
    <div class="swiper-slide mx-sm-3">
      <div class="card mx-sm-2" [appGridStyle]="article">
        <img *ngIf="slide.type == 'image'" src="{{ slide.image }}" />
        <h6
          [ngStyle]="{
            'font-size': article?.title_size,
            color: article?.title_color,
            'font-weight': article?.data_title_weight
          }"
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
          [innerHtml]="slide.title"
        ></h6>
        <p
          *ngIf="article.show_cms_description == 1 && article.text_as_html == 1"
          [innerHtml]="slide.description"
        ></p>
        <h6
          [ngStyle]="{
            'font-size': article?.title_size,
            color: article?.title_color,
            'font-weight': article?.data_title_weight
          }"
          *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
        >
          {{ slide.title }}
        </h6>
        <p
          *ngIf="article.show_cms_description == 1 && article.text_as_html == 0"
        >
          {{ slide.description }}
        </p>
      </div>
    </div>
  </ng-template>
</p-carousel>

</div>

<div
class="alert alert-warning text-center"
role="alert"
*ngIf="slides?.length == 0"
>
<p><b>#{{article?.id}}</b> {{ "InfoAlarm" | translate }}</p> 
</div>