<div
  class="d-flex flex-row parralax justify-content-center align-items-center"
  [ngStyle]="{
    'background-image': 'url(' + Adertising_Data[0]?.image + ')'
  }"
  
  [appMyStyles]="article"
>
  <div class="d-flex flex-column justify-content-start col-10">
    <app-header-title-desc [article]="article"></app-header-title-desc>

    <div class="d-flex flex-column">
      <h2
     
      [ngStyle]="{'font-size':article?.title_size, 
      'color':article?.title_color,
      'font-weight':article?.data_title_weight,
          'z-index':1
        }"
        [ngClass]="[article?.component_title_position=='center'?'text-center':'text-start w-100',
          article?.content_position_vertical=='bottom'?'w-100':'w-75'
         ]">
        {{ Adertising_Data[0]?.title }}
      </h2>
  
      <h6
       
        class="component-desc"
        [ngStyle]="{
          color: article.description_color,
          'font-size': article.description_size,
          'font-weight': article?.data_desc_weight
        }"
         [ngClass]="[article?.Style_content_position=='0'?'text-center':'text-start',
           article?.content_position_vertical=='bottom'?'w-100':'w-50'
         ]" 
      >
      {{ Adertising_Data[0]?.description }}
  
      </h6>
    </div>
    <div class="search-container col-md-7 my-5 mx-0 p-0">
      <input type="text" class="search-input" placeholder="{{'search'|translate}}" />
      <span class="search-icon">
        <i class="fa fa-search"></i>
      </span>
    </div>    
    
  </div>
</div>
