<div class="container-fluid pt-5 pb-5" [appMyStyles]="article">
  <div
    class="row justify-content-center w-100"
    
  >
  <div class="subscribe-container">
    <h3
    [ngStyle]="{ 'color': article?.title_font_color, 'font-size': article?.title_font_size ,'font-weight':article?.component_title_weight}"

    > {{ article?.component_title }}</h3>
    <div class="subscribe-input">
      <input
        type="email"
        [placeholder]="('AUTH.FORGET_PASSWORD.enter_email' | translate) "
       
      />
      <button class="btn Subscribe"
      [ngStyle]="{
        'background-color':
          article?.component_buttons[0]?.button_background_color,
        color: article?.component_buttons[0]?.button_color,
        'font-size':
          article?.component_buttons[0]?.button?.font_size,
        'font-weight':
          article?.component_buttons[0]?.button?.font_weight,
          'border-color':article?.component_buttons[0]?.button_color
      }">Subscribe</button>
    </div>
  </div>
  
  </div>
</div>
