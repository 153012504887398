import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

interface StyleConfig {
  border: string;
  flexDirection: string;
  backgroundColor: string;
  borderColor: string;
  textAlign:string;
  boxShadow:string;
  borderRadius:string;
  [key: string]: string;
}

@Directive({
  selector: '[appGridStyle]'
})
export class GridStyleDirective {

  private _styleConfig: StyleConfig;

  @Input("appGridStyle")
  set appGridStyle(grid: any) {
    const conditions = {
      flexDirection: grid?.Style_content_position === 0 ? "column" : "row", 
      border: grid?.Style_border === 0 ? "none" : "1px solid",
      borderColor: grid?.border_color ? grid.border_color : "transparent",
      backgroundColor: grid?.Style_card_background? grid?.Style_card_background: "transparent",
      textAlign:grid?.Style_content_position === 0 ? "center" : "start",
      boxShadow: grid?.Style_border === 0 ? "none" : "0px 5px 6px 0px #9c9c9c1f",
      borderRadius: grid?.component_style === "Curved" ? "15px" :grid?.component_style === "both"?  "30px 0px":'0px',
    };

    const {
      flexDirection,
      border,
      backgroundColor,
      borderColor,
      textAlign,
      boxShadow,
      borderRadius
    } = conditions;
    // Update the private property and apply styles
    this._styleConfig = {
      flexDirection,
      border,
      backgroundColor,
      borderColor,
      textAlign,
      boxShadow,
      borderRadius
    
    };
    this.applyStyles();
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  private applyStyles() {
    // Reset styles before applying new styles
    this.resetStyles();
    // Iterate over the keys in the styleConfig object
    for (const prop in this._styleConfig) {
      if (this._styleConfig.hasOwnProperty(prop)) {
        const value = this._styleConfig[prop];
        // Apply styles based on the property and value
        this.renderer.setStyle(this.el.nativeElement, prop, value);
      }
    }
  }

  private resetStyles() {
    // Reset all styles before applying new styles
    for (const prop in this._styleConfig) {
      if (this._styleConfig.hasOwnProperty(prop)) {
        this.renderer.removeStyle(this.el.nativeElement, prop);
      }
    }
  }
}
