<section class="main__list" [appMyStyles]="article">
  
  <section class="main__list__header">
    <h1 class="main__list__header-title">{{ "Everyone" | translate }}</h1>
    <p class="main__list__header-desc">
      {{ total_items }} {{ "result" | translate }}
    </p>
    <h1 class="main__list__header-filter">{{ "Filter" | translate }}</h1>
  </section>

  
  <section class="main__list__content">
    <section class="main__list__content__items">
      <section
        class="main__list__content__items__item"
        *ngFor="let new of News_list_Data"
      >
        <section class="main__list__content__items__item__img">
          <img
            (click)="openNew(new)"
            class="main__list__content__items__item__img-img"
            [src]="new.image"
            alt="img"
          />
        </section>
        <section class="main__list__content__items__item__content">
          <h1
            class="main__list__content__items__item__content-title"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
            [innerHtml]="new.title_detailed"
          ></h1>
          <p
            class="main__list__content__items__item__content-desc"
            *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
            [innerHtml]="new.description_detailed"
          >
            <span class="main__list__content__items__item__content-desc-span">
              {{ "see more" | translate }}
            </span>
          </p>

          <h1
            class="main__list__content__items__item__content-title"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
          >
            {{ new.title }}
          </h1>
          <p
            class="main__list__content__items__item__content-desc"
            *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
          >
            {{ new.description }}

            <span class="main__list__content__items__item__content-desc-span">
              {{ "see more" | translate }}
            </span>
          </p>

          <section class="main__list__content__items__item__content__footer">
            <div
              class="main__list__content__items__item__content__footer__time"
            >
              4 {{ "hours" | translate }}
            </div>
            <button
              class="main__list__content__items__item__content__footer-share"
            >
              {{ "share" | translate }}
            </button>
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
