<div [appMyStyles]="article" class="mb-3">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="row d-flex justify-content-center">
    <div class="col-lg-6 col-sm-12" >
        <form
      [formGroup]="ReservationForm"
      (ngSubmit)="submitForm(ReservationForm.value)"
      class="d-flex justify-content-center flex-column align-items-center  mt-3 margin"
      
    >
      <input
        type="text"
        class="form-control m-3 shadow"
        placeholder="name "
        placeholder="{{ 'Name' | translate }}"
        [ngStyle]="{'font-size':article?.title_size, 
        'color':article?.title_color,
        'font-weight':article?.data_title_weight
            
          }"
      />
      <input
        type="text"
        class="form-control m-3 shadow"
        placeholder="{{ 'Email' | translate }}"
        formControlName="email"
        [ngStyle]="{'font-size':article?.title_size, 
        'color':article?.title_color,
        'font-weight':article?.data_title_weight
            
          }"
      />
      <input
        type="text"
        class="form-control m-3 shadow"
        placeholder="{{ 'Mobile' | translate }}"
        formControlName="mobile"
        [ngStyle]="{'font-size':article?.title_size, 
        'color':article?.title_color,
        'font-weight':article?.data_title_weight
            
          }"
      />
      <textarea
        type="text"
        class="form-control m-3 shadow message"
        placeholder="{{ 'GENERAL_PAGES.CONTACT.MESSAGE' | translate }}"
        formControlName="message"
        [ngStyle]="{'font-size':article?.title_size, 
        'color':article?.title_color,
        'font-weight':article?.data_title_weight
            
          }"
      ></textarea>
      <button
        class="btn px-5 py-2"
       
        type="submit"
        [ngStyle]="{
          'background-color': websiteSecColor,
           color: Button_Color,
          'border-color': websiteSecColor
          }"
      >
        Send
      </button>
    </form>
    </div>
  
    <div class="col-lg-6 col-sm-12 d-flex justify-content-center"  *ngIf="ImageTitleData?.length>0">
    <img
      
      class="img-style"
      src="{{ ImageTitleData[0].image }}"
    />
    </div>

  </div>
</div>
