<div
  [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
  class="row parralax d-flex"
  [ngStyle]="{
    'background-image': 'url(' + component.component_background_image + ')'
  }"
  [ngClass]="
    image== null ? 'no-image' : ''"
  [appMyStyles]="component" [appSliderStyle]="component" 
>
  <div class="general title-btn" 
  [ngClass]="[
    image!= null ? 'col-lg-8 col-md-6 col-sm-8' : 'col-md-12 col-sm-12 col-xs-12'
]">
    <h2
     
      [ngStyle]="{
        'font-size': component?.title_font_size,
        color: component?.title_font_color,
        'font-weight': component?.component_title_weight
      }"
    >
      {{ component?.component_title }}
    </h2>

    <h6
     
      class="component-desc"
      [ngStyle]="{
        color: component.description_color,
        'font-size': component.description_size,
        'font-weight': component?.data_desc_weight
      }"
    >
      {{ component_description }}
    </h6>
   
    <div>
      <button
        class="volunteer-btn"
        (click)="navigateToShow(sliderData[0]?.id)"
        [ngStyle]="{
          'background-color':
            component?.component_buttons[0]?.button_background_color,
          'color': component?.component_buttons[0]?.button_color,
          'font-size': component?.component_buttons[0]?.button?.font_size,
          'font-weight': component?.component_buttons[0]?.button?.font_weight
        }"
      >
        <ng-container *ngIf="lang == 'ar_container'"
          >{{ component?.component_buttons[0]?.button_text }}
        </ng-container>
        <ng-container *ngIf="lang == 'en_container'"
          >{{ component?.component_buttons[0]?.button_text }}
        </ng-container>
      </button>
    </div>
  </div>
  
  <ng-container *ngIf="image!= null">
    <div class="col-lg-4 col-md-6 col-sm-4 px-0">
      <img [src]="sliderData[0]?.image" 
      [ngClass]="component.Style_image_shape=='1'?'slider-image-side':'slider-image-circle'"
       [ngStyle]="{'border-radius':lang == 'ar_container' && component.Style_image_shape=='1'?'0px 16px 16px 0px':''}">
    </div>
  </ng-container>
  <ng-container *ngIf="sliderData.length>1">
      <div class="col-lg-4 col-md-4 col-sm-6 py-lg-2 p-md-0" *ngFor="let item of sliderData | slice:1">
        <img class="icon-details" [src]="item.image">
        <span class="px-2"   [ngStyle]="{
          color: component.desc_font_color,
          'font-size': component.desc_font_size,
          'font-weight': component?.component_desc_weight
        }"> {{item.title}}</span>
      </div>
 
  </ng-container>
</div>

<!-- with popup quotaion  -->
<!-- <div *ngIf="main_id == 16023"
  class="row parralax general-3"
  [ngStyle]="{ 'background-image': 'url(' + image+ ')' }"

>
  

  <div class="col-md-7 col-sm-12 col-xs-12" 
  [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
  >
    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_title == 1 && component.text_as_html == 1"
      [innerHtml]="sliderData[0]?.title_detailed"
    ></p>

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_title == 1 && component.text_as_html == 0"
    >
      {{ sliderData[0]?.title }}
    </p>

   

    <p
      style="
        color: #ffffff;
        font-size: 20px;
        margin: 15px 0px 20px 0px;
        text-align: start;
      "
      *ngIf="component.show_cms_description== 1 && component.text_as_html == 0"
    >
      {{ sliderData[0]?.description }}
    </p>

    

  </div>

  <div class="col-md-5 col-sm-12 col-xs-12 " >
  </div>

  <div class="col-md-12 col-sm-12 col-xs-12 " style="text-align: center;"
  [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
  >
    <button
      style="text-align: center"
      class="volunteer-btn"
      (click)="OpenPopup(sliderData[0]?.id)"
      style="
          color: #ffffff;
          border:1px solid #ffffff;
          background-color: transparent;
      "
    >
      {{ "Get quotation for sewing machines" | translate }}
    </button>
  </div>

 
</div> -->
