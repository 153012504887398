<div
  class="background"
  [appMyStyles]="article"
 
>
<app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="row" *ngFor="let item of ImageTitleDescriptionHorizontalData ; index as i">
    <div *ngIf="i%2 ==0"
      class="col-md-6 col-sm-12 col-xs-12 mb-4"
      
    
     
    >
      <img
        class="image-style"
        src="{{ item?.image }}"
      />
      <p
        [ngStyle]="{ color: websiteMainColor }"
        [innerHtml]="item?.content"
        class="price"
      ></p>
      <button *ngIf=" article?.component_buttons?.length>0"  class="btn"
        [ngStyle]="{
          'background-color':
            article?.component_buttons[0]?.button_background_color,
          'color': article?.component_buttons[0]?.button_color,
          'font-size': article?.component_buttons[0]?.button?.font_size,
          'font-weight': article?.component_buttons[0]?.button?.font_weight
        }"
        >
        {{ "Book Now" | translate }}
      </button>
    </div>

    <div *ngIf="i%2 ==0"
      class="col-md-6 col-sm-12 col-xs-12  mb-4"
      
    
      (click)="navigateToShow(item?.id)"
    >
     
      <h3
        class="blue-text"
        [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}"
      >
        {{ item?.title }}
      </h3>


      <p
        class="main-paragraph border-style-left"
        [ngStyle]="{ 'border-left-color': websiteSecColor ,'font-size':article?.description_size,'text-transform':'lowercase', 'color':article?.description_color,'font-weight':article?.data_desc_weight }"
        *ngIf="currentLang == '1'"
       
      >{{item?.description}}</p>
      <p
        class="main-paragraph border-style-right"
        [ngStyle]="{ 'border-right-color': websiteSecColor ,'font-size':article?.description_size,'text-transform':'lowercase', 'color':article?.description_color,'font-weight':article?.data_desc_weight}"
        *ngIf=" currentLang == '2'"
       
      >{{item?.description}}</p>
    </div>

    <div *ngIf="i%2 !=0 "
      class="col-md-6 col-sm-12 col-xs-12  mb-4"
      
    
      (click)="navigateToShow(item?.id)"
    >
     
      <h3
        class="blue-text"
        [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}"
       
      >
        {{ item?.title }}
      </h3>

      <!-- <p class="city">
        <i class="fas fa-map-marker-alt"></i>
        Egypt
      </p> -->
      <p
      class="main-paragraph border-style-left"
      [ngStyle]="{ 'border-left-color': websiteSecColor,'font-size':article?.description_size,'text-transform':'lowercase', 'color':article?.description_color,'font-weight':article?.data_desc_weight }"
      *ngIf="currentLang == '1'"
     
    >{{item?.description}}</p>
    <p
      class="main-paragraph border-style-right"
      [ngStyle]="{ 'border-right-color': websiteSecColor,'font-size':article?.description_size,'text-transform':'lowercase', 'color':article?.description_color,'font-weight':article?.data_desc_weight }"
      *ngIf=" currentLang == '2'"
     
    >{{item?.description}}</p>
    </div>

    <div *ngIf="i%2 !=0"
      class="col-md-6 col-sm-12 col-xs-12  mb-4"
      
    
      (click)="navigateToShow(item?.id)"
    >
      <img
        class="image-style"
        src="{{ item?.image }}"
      />
      <p
        [ngStyle]="{ color: websiteMainColor }"
        [innerHtml]="item?.content"
        class="price"
      ></p>
      <button *ngIf=" article?.component_buttons?.length>0"  class="btn"
        [ngStyle]="{
          'background-color':
            article?.component_buttons[0]?.button_background_color,
          'color': article?.component_buttons[0]?.button_color,
          'font-size': article?.component_buttons[0]?.button?.font_size,
          'font-weight': article?.component_buttons[0]?.button?.font_weight
        }" (click)="Navigate(6030)">
        {{ "Book Now" | translate }}
      </button>
    </div>

    
  </div>
</div>
