import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";

import { CmsComponent1Component } from "../cms-component1/cms-component1.component";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { DomSanitizer } from "@angular/platform-browser";
import { CmsComponent1GeneralComponent } from "../cms-component1-general/cms-component1-general.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-cms-component4-general',
  templateUrl: './cms-component4-general.component.html',
  styleUrls: ['./cms-component4-general.component.scss']
})
export class CmsComponent4GeneralComponent implements OnInit {

  @Input() component: any;

  Hide_Date;

  // Show_Components_Data;
  param;
  component_list_Data;
  lang;
  currentLang: string;
  websiteMainColor: any;
 
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected homeService: HomeService,
    protected langService: LangService,
    public sanitizer: DomSanitizer,
    protected translateService: TranslateService,

  ) {
    // super(route, router, homeService, langService , sanitizer , translateService);
  }

  ngOnInit(): void {
    
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));

    this.homeService
    .getHomeComponentsData(this.component)
    .subscribe((res: any) => {
      this.component_list_Data = res.content;
      console.log("border",this.component);
      
    });

  this.translateService.onLangChange.subscribe((res) => {
    this.currentLang = localStorage.getItem("lang");
   
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.component_list_Data = res.content;
      });
  });

  }

  navigateToListShow(item) {
    if (this.component.can_navigate == "1") {
      if (localStorage.getItem("End_Point") == "/category_values") {
       
        localStorage.removeItem("End_Point");

        this.router.navigate(["/cms/1"], {
          queryParams: {
            module: item.more[2].value[1].id,
            Category_id: item.id,
          },
        });
      } else {
        this.router.navigate([`cms/1/${item.id}`], {
          queryParams: { module: this.param },
        });
      }
    }

    if (localStorage.getItem("End_Point") == "/category_values") {
      // this have sublist so it need to navigate to list again
     
      localStorage.removeItem("End_Point");

      this.router.navigate(["/cms/1"], {
        queryParams: { module: item.more[2].value[1].id, Category_id: item.id },
      });
    } 
    // else {
      // this.langService.getLang().subscribe((res) => {
      //   this.router.navigate([`${res.key}/cms/1/${item.id}`], {
      //     queryParams: { module: this.param },
      //   });
      // });
      //  this.router.navigate([`cms/1/${item.id}`] ,{ queryParams: { module: this.param } })
    // }

    // else if (JSON.parse(localStorage.getItem('End_Point')) ==  '2' ){
    //   this.sublist  = JSON.parse(localStorage.getItem('sublist'));
    //   localStorage.setItem('apiparameter',JSON.stringify(this.sublist))
    //   this.router.navigate(['/cms/2'],{ queryParams: { module: JSON.parse(localStorage.getItem('sublist_moduleNo')) } });
    // }
  }

}
