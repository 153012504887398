<div class="container-fluid" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 web-slider" style="width: 100%">
      <div class="swiper">
        <div class="swiper-container" *ngIf="slides.length" [swiper]="config">
          <div class="swiper-wrapper">
            <div *ngFor="let slide of slides; index as i" class="swiper-slide">
              <div class="slide-img">
                <img [src]="slide.image" />
              </div>
            </div>
          </div>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev">
            <i class="fas fa-arrow-left"></i>
        </div>
        <div class="swiper-button-next">
            <i class="fas fa-arrow-right"></i>
        </div>
        

        </div>
      </div>
    </div>
  </div>
</div>