import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() totalItems!: number;
  @Input() itemsPerPage!: number;
  @Input() currentPage!: number;
  @Input() eventList!: any[];
  @Output() pageChange = new EventEmitter<number>(); 
  paginatedList: any[] = [];

  ngOnInit() {
    this.updatePaginatedList();
  }

  ngOnChanges() {
    this.updatePaginatedList();
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.pageChange.emit(page);
    this.updatePaginatedList();
  }

  updatePaginatedList(): void {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.paginatedList = this.eventList.slice(start, end);
  }
}
