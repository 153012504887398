<footer class="page-footer font-small"
[ngStyle]="{
  'background-color': backgroundImage ? 'transparent' : footer_bg_color,
  'background-image': backgroundImage ? 'url(' + backgroundImage + ')' : 'none',
  'background-size': 'cover',
  'background-position': 'center'
}">
  <div>
    <div class="row text-center text-md-left py-5" [ngStyle]="{ 'background-color': footer_bg_color }"
      *ngIf="APP_ID != null">
      <!-- Grid column -->
      <div class="col-md-4 col-lg-4 col-xs-12 text-center logo-center d-flex flex-column">
        <a class="footer-brand" [ngClass]="logo_shape=='circle'?'footer-brand-circle':'footer-brand-square'"
          routerLink="/"><img [src]="footerlogo" /></a>
        <div class="text-center">
          <p *ngIf="data_position == 'left' && website_description" class="desc mt-5"
            [ngStyle]="{'color':footer_color,'font-size':Footer_font_size}">
            {{website_description}}
          </p>
         
          <div class="social mb-5 text-center" *ngIf="data_position == 'left'">
            <ng-container
            *ngIf="(contactData?.email  != null || contactData?.mobile  != null  || contactData?.address  != null ) && data_position == 'left'">
            <!-- <h4 [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'Contact Info'|translate}}</h4> -->
            <div class="lines">

              <div class="line"></div>
            </div>



            <p *ngIf="contactData?.address  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
              {{'Address'|translate}} :
              {{ contactData?.address }}
            </p>



            <p *ngIf="contactData?.mobile  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
              {{'PROFILE.PHONE_NUMBER'|translate}} :
              {{ contactData?.mobile }}
              <span style="padding:0px 3px">|</span>

              {{contactData?.landline}}
            </p>

            <p *ngIf="contactData?.email  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
              {{'PROFILE.EMAIL'|translate}} :
              {{ contactData?.email }}
            </p>
          </ng-container>
            <ul class="list-unstyled list-inline pt-4">
              <li class="list-inline-item" *ngFor="let link of SocialLinks">
                <a *ngIf="link.value != null && link.footer == 1" class="btn-floating btn-sm rgba-white-slight" href="{{ link?.value}}"
                  target="_blank">
                  <img class="icon" [src]="link?.icon" alt="">

                </a>
              </li>
            </ul> 
          </div>
        </div>
      </div>

      <!-- Grid column -->
      <div class="col-xs-12 text-left"
      [ngClass]="data_position == 'left'?'col-md-6 col-lg-8':'col-md-4 col-lg-4'" >

        <ul class="edit">
          <li class=" my-2" *ngFor="let item of footeritems" (click)="navigatetoModule(item)">
            <a class="links" [ngStyle]="{ color: footer_color, 'font-size': Footer_font_size }">
              {{ item.name }}
            </a>
          </li>
        </ul>
      </div>

      <!-- Grid column -->
      <hr class="w-100 clearfix d-md-none" />

      <!-- Grid column -->
      <div class="col-md-4 col-lg-4 col-xs-12 text-left" style="padding: 0px">

        <ng-container
          *ngIf="(contactData?.email  != null || contactData?.mobile  != null  || contactData?.address  != null ) && data_position != 'left'">
          <p *ngIf="contactData.address != null" [ngStyle]="{ color: footer_color, 'font-size': Footer_font_size }">
            {{ "Address" | translate }} :
            {{ contactData.address }}
          </p>

          <p *ngIf="contactData.mobile != null" [ngStyle]="{ color: footer_color, 'font-size': Footer_font_size }">
            {{ "PROFILE.PHONE_NUMBER" | translate }} :
            {{ contactData.mobile }}
            <span style="padding: 0px 3px" *ngIf="contactData.landline != null">|</span>

            {{ contactData.landline }}
          </p>

          <p *ngIf="contactData.email != null" [ngStyle]="{ color: footer_color, 'font-size': Footer_font_size }">
            {{ "PROFILE.EMAIL" | translate }} :
            {{ contactData.email }}
          </p>
        </ng-container>
        <div class="social" *ngIf="data_position != 'left'">
          <ul class="list-unstyled list-inline" *ngIf="SocialLinks">
            <li class="list-inline-item" *ngFor="let link of SocialLinks">
              <a *ngIf="link.value != null && link.footer == 1" class="btn-floating btn-sm rgba-white-slight" href="{{ link?.value }}"
                target="_blank">
                <img class="icon" [src]="link?.icon" alt="" />
              </a>
            </li>



            <li></li>
          </ul>
        </div>
      </div>

      <!-- Grid column -->
    </div>

    <app-links-footer [parentStyle]=passStyle *ngIf="copyWriteFooter == 1"></app-links-footer>
  </div>
</footer>