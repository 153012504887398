<div class="row">
  <div
    class="col-md-12 col-sm-12 col-xs-12 text-center"
    *ngIf="article?.heading_title_position == 'center'"
  >
    <ng-container *ngIf="article?.component_title && article?.show_title == 1">
      <h4
        class="mb-3"
        [ngStyle]="{
          color: article?.title_font_color,
          'font-size': article?.title_font_size,
          'font-weight': article?.component_title_weight
        }"
      >
        {{ article?.component_title }}
      </h4>
    </ng-container>
    <h6 class="mb-4"
      *ngIf="article?.component_description && article?.hide_description == 0"
      [ngStyle]="{
        color: article?.desc_font_color,
        'font-size': article?.desc_font_size,
        'font-weight': article?.component_desc_weight
      }"
    >
      {{ article?.component_description }}
    </h6>
  </div>

  <div
    class="col-md-12 col-sm-12 col-xs-12"
    *ngIf="article?.heading_title_position != 'center'"
    [ngClass]="article?.full_width == 1 ? 'pInline':''"
  >
    <div class="separator">
      <ng-container
        *ngIf="article?.component_title && article?.show_title == 1"
      >

        <h4
          class="mb-3"
          [ngStyle]="{
            color: article?.title_font_color,
            'font-size': article?.title_font_size,
            'font-weight': article?.component_title_weight
          }"
        >
          {{ article?.component_title }}
        </h4>
         <span class="line mb-2" *ngIf="article?.heading_border==1"  [ngStyle]="{'background-color': article?.heading_border_color !=null ? article?.heading_border_color : websiteSecColor}"
   ></span>
      
      </ng-container>
    </div>
    <h6
    class="mb-4 desc"
      *ngIf="article?.component_description  && article?.hide_description == 0"
      [ngStyle]="{
        color: article?.desc_font_color,
        'font-size': article?.desc_font_size,
        'font-weight': article?.component_desc_weight
      }"
    >
      {{ article?.component_description }}
    </h6>
  </div>
</div>
