<div [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>


  <div
    class="row justify-content-center align-items-center"
    
  >
    <div class="col-md-1 col-sm-1 col-xs-1 d-flex justify-content-center">
      <i class="fas fa-chevron-circle-left icon" (click)="prev()"></i>
    </div>

    <!-- Mobile/Tablet View -->
    <div class="col-md-6 col-sm-9 col-xs-9 m-3 comment mobile-ipad-only"
    [ngStyle]="{'background-color':component?.Style_card_background? component?.Style_card_background:'transparent' }"
      *ngFor="let item of imageTitleData | slice: i: i + 1">
      <div class="d-flex pt-4 align-items-center">
        <img class="icon-img" [src]="item?.image" />
        <h5 class="px-2 mb-0" [ngStyle]="{
            'font-size': component?.title_size,
            color: component?.title_color,
            'font-weight': component?.data_title_weight,
        'text-transform':'none'

          }">
          {{ item?.title }}
        </h5>
      </div>

      <div>
        <p class="ellipsis" [ngStyle]="{
            'font-size': component?.description_size,
            'text-transform':'lowercase',
            'color': component?.description_color,
            'font-weight': component?.data_desc_weight
          }">
          {{ item?.description }}
        </p>
      </div>
      <div class="time" [ngStyle]="{ 'font-size': component?.date_size, color: component?.description_color }">
        {{ item?.created_at | date: 'short' }}
      </div>
    </div>

    <!-- Desktop View -->
    <div class="col-lg-3 m-3 comment desktop-only" 
    [ngStyle]="{'background-color':component?.Style_card_background? component?.Style_card_background:'transparent' }"
    *ngFor="let item of imageTitleData | slice: i: i + 3">
      <div class="d-flex pt-4 align-items-center">
        <img class="icon-img" [src]="item?.image" />
        <h5 class="px-3 mb-0" [ngStyle]="{
            'font-size': component?.title_size,
            color: component?.title_color,
            'font-weight': component?.data_title_weight,
        'text-transform':'none'

          }">
          {{ item?.title }}
        </h5>
      </div>

      <div>
        <p class="ellipsis" [ngStyle]="{
            'font-size': component?.description_size,
            'color': component?.description_color,
            'text-transform':'lowercase',
            'font-weight': component?.data_desc_weight
          }">
          {{ item?.description }}
        </p>
      </div>
      <div class="time" [ngStyle]="{ 'font-size': component?.date_size, color: component?.description_color }">
        {{ item?.created_at | date: 'short' }}
      </div>
    </div>

    <div class="col-md-1 col-sm-1 col-xs-1 d-flex justify-content-center">
      <i class="ms-5 fas fa-chevron-circle-right icon" (click)="next()"></i>
    </div>
  </div>

  <app-alert *ngIf="imageTitleData?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
    [message]="'InfoAlarm' | translate" [type]="'warning'">
  </app-alert>
</div>