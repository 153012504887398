import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "src/app/shared/shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

declare var $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent implements OnInit {
  @Input() component: any;
  faqData;
  lang: string;
  currentLang: string;
  mainImg:string;
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    
    // this.APIParameters =JSON.parse(localStorage.getItem('circles3-apiparameters'));

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.faqData = res.content;
        this.catchImg(this.faqData);
        console.log("faq,",this.catchImg(this.faqData));

      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.faqData = res.content;
          this.catchImg(this.faqData);
          
        });
    });
  }
  catchImg(dataArray): string | null {
    for (const obj of dataArray) {
      if (obj.image !== null && obj.image !== undefined) {
        this.mainImg =  obj.image;
     
        return this.mainImg;
      }
    }
    return null; // Return null if no valid image is found
  }
  showAnswer(questionIndex, gpIndex) {
    
    if ($(`#ans_q${questionIndex}_g${gpIndex}`).hasClass('display--none')) {
      $(`#ans_q${questionIndex}_g${gpIndex}`).removeClass('display--none');
      $(`#plus_${questionIndex}_${gpIndex}`).addClass('display--none');
      $(`#minus_${questionIndex}_${gpIndex}`).removeClass('display--none');
    } else {
      $(`#ans_q${questionIndex}_g${gpIndex}`).addClass('display--none');
      $(`#plus_${questionIndex}_${gpIndex}`).removeClass('display--none');
      $(`#minus_${questionIndex}_${gpIndex}`).addClass('display--none');
    }
  }

}
