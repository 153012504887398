<section
  class="img__wrapper"
  
  [appMyStyles]="article"
>
  <img
    class="img__wrapper--img"
    src="{{ About_Us_Header_Data[0].image }}"
    alt="Image Of US"
  />
  <section
    class="img__wrapper__icon"
    [ngStyle]="{
      right: language == 'en' ? '10%' : 'unset',
      left: language == 'ar' ? '10%' : 'unset'
    }"
  >
    <img
      class="img__wrapper__icon--icon"
      src="../../assets/images/Subtraction 15.svg"
      alt="icon"
    />
  </section>
</section>
