import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input() message: string = '';
  @Input() componentId: number | string = ''; 
  @Input() mainId: number | string = ''; 
  @Input() type: 'success' | 'danger' | 'warning' | 'info' = 'warning';
  @Input() isVisible: boolean = true; // Show/Hide alert
}
