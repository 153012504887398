<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>
<div class="container-fluid">
  <div [appMyStyles]="article" class="row mx-3">
  
    <div class="col-md-6 col-sm-12 col-xs-12" *ngIf="TwoTitleDescriptionCardData?.length>0">
  
  
      <h2 [ngStyle]="{
                         'font-size': article.title_font_size,
                         color: article.title_font_color,
                         'font-weight': article?.component_title_weight
                       }">
        {{ TwoTitleDescriptionCardData[0]?.title }}
      </h2>
      <p [ngStyle]="{
                          'font-size': article.description_size,
                          color: article.descriptiont_color
              }">
        {{ TwoTitleDescriptionCardData[0]?.description }}
      </p>
  
      <h3 id="header2" class="mt-3">Ora Development Projects inside Egypt:</h3>
  
      <ul>
        <li id="item1">Compound Zed East New Cairo</li>
        <li id="item2">Nile City Towers</li>
        <li id="item3">Pyramid Hills Compound</li>
        <li id="item4">Silversands North Coast</li>
        <li id="item45">Solana East - New cairo</li>
        <li id="item46">Solana west - New zayed</li>
        <li id="item47">Zed west - New zayed</li>
      </ul>
  
  
      <h3 id="header2" class="mt-3">Ora Development Projects outside Egypt:</h3>

      <ul>
        <li id="item5">Twenty Grosvenor Square London</li>
        <li id="item6">The Silversands Resort, Grenada</li>
        <li id="item7">Ayia Napa Marina, Cyprus</li>
        <li id="item8">Great Britain Project</li>
        <li id="item9">Eighteen Project in Pakistan</li>
      </ul>

    </div>
  
  
    <div class="col-md-6 col-sm-12 col-xs-12" *ngIf="TwoTitleDescriptionCardData?.length>0">
      <img class="main-img" [src]="TwoTitleDescriptionCardData[0]?.image " />
    </div>
  
  </div>
</div>


<app-alert *ngIf="TwoTitleDescriptionCardData?.length == 0" [componentId]="article?.id" [mainId]="article?.main_id"
  [message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>