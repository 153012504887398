<app-academy-register-no-bg
  *ngIf="viewRegister"
  [overlay]="true"
  (closePopup)="register(false)"
></app-academy-register-no-bg>

<nav class="navbar navbar-expand-lg navbar-dark" [ngStyle]="{ 'background-color': Header_Bg_Color }" [ngClass]="menu?.Menu?.full_coverage==1?'header-full-width':''"> 
  <a class="navbar-brand" [ngStyle]="{'width':website_logo?'unset':'50px'}">
     <img  alt="" class="logo" [src]="website_logo" *ngIf="website_logo" />     
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerMenuSide" aria-controls="navbarTogglerMenuSide" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarTogglerMenuSide">
    <ul class="navbar-nav m-auto mt-2 mt-lg-0">
    <div *ngIf="isMobile; else desktopNav">
        <ng-container
          *ngFor="let item of MenuItems ; let i = index"
        >
          <li class="nav-item" *ngIf="item.menu_design.header == 1">
            <a
              class="nav-link"
              [routerLinkActive]="'active-link'"
              (click)="navigatetoModule(item)"
              (mouseover)="hoverHeaderItem = i"
              (mouseleave)="hoverHeaderItem = -1"
              [ngStyle]="{
                'color':  hoverHeaderItem == i || itemSelectedFromHeader == item.name ? websiteSecColor : Header_Color,
               'font-size' : Header_font_size ,
                'white-space': 'nowrap',
                'text-transform': Text_Style,
                'font-family':text_type,
                'font-weight': text_weight
               
              }"
            >
              {{ item.name }}
   
            </a>
          </li>
        </ng-container>
    </div>
      
    <ng-template #desktopNav>
        <ng-container
        *ngFor="let item of MenuItems | slice : 0 : 6; let i = index"
      >
        <li class="nav-item" *ngIf="item.menu_design.header == 1">
          <a
            class="nav-link"
            [routerLinkActive]="'active-link'"
            (click)="navigatetoModule(item)"
            (mouseover)="hoverHeaderItem = i"
            (mouseleave)="hoverHeaderItem = -1"
            [ngStyle]="{
              'color':  hoverHeaderItem == i || itemSelectedFromHeader == item.name ? websiteSecColor : Header_Color,
             'font-size' : Header_font_size ,
              'white-space': 'nowrap',
              'text-transform': Text_Style,
              'font-family':text_type,
              'font-weight': text_weight
             
            }"
          >
            {{ item.name }}
 
          </a>
        </li>
      </ng-container>

    </ng-template>
      </ul>
      <div class="form-inline my-2 my-lg-0" >
        <div class="searchbar desktop-only" *ngIf="menu?.Menu?.can_search">
          <span class="search-button">
            <div
              [ngStyle]="{
                'color':Header_Color, 
                'background-color': websiteMainColor ,
                'border-color': Header_Color,
                'text-transform': Text_Style,
                'font-family':text_type,
                'font-weight': text_weight
              }"
              class="nav-link bold search-toggle bold"
              aria-hidden="true"
              (click)="clickEvent()"
            >
            <i class="fa fa-search p-1"></i>
  
            </div>
          </span>
          <div class="togglesearch" [ngClass]="status ? 'show' : 'hide'">
            <div class="container">
              <input
                type="text"
                placeholder="{{ 'search' | translate }}"
                [(ngModel)]="searchQuery"
                [ngModelOptions]="{ standalone: true }"
              />
              <button
                [ngStyle]="{
                  left: lang.key == 'en' ? '8%' : 'unset',
                  right: lang.key == 'ar' ? '8%' : 'unset'
                }"
                [ngStyle]="{ 'background-color': websiteMainColor , 'text-transform': Text_Style,'color':Header_Color, 
                'font-family':text_type,
                'font-weight': text_weight }"
                class="search-btn-new"
                (click)="search()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="lang-section">
  
    
          <i class="fa fa-thin fa-globe p-1" aria-hidden="true"  [ngStyle]="{
              color: websiteSecColor }"></i>
          <a 
          (click)="lang.key == 'ar' ? changeLang('en') : changeLang('ar')"
            [ngStyle]="{
              color: websiteSecColor,
             'font-size' : Header_font_size ,
             
             'font-family':text_type,
             'font-weight': text_weight,
             'white-space': 'nowrap'
             }"> {{ 'languageType'  | translate}} </a>
           </div> 
        <div class="side-nav desktop-only" [ngStyle]="{
          'color': websiteSecColor ,
          'text-transform': Text_Style,
          'font-family':text_type,
          'font-weight': text_weight
        }" (click)="openMenu()">
  
        <i class="fa fa-bars"></i>
        </div>
    
     
      </div>
    </div>
    <div
  id="mySidebar"
  class="sidebar"
  
  [ngStyle]="{ display: overlay == true ? 'block' : 'none' }"
>
  <a
    *ngFor="let item of MenuItems ; let i = index"
    (mouseover)="hoverHeaderItem = i "
    (mouseleave)="hoverHeaderItem = -1"
    [ngStyle]="{
      'color':  hoverHeaderItem == i || itemSelectedFromHeader == item.name ? websiteSecColor : Header_Color,
     'font-size' : Header_font_size ,
      'white-space': 'nowrap',
      'text-transform': Text_Style,
      'font-family':text_type,
      'font-weight': text_weight
     
    }"
    [routerLinkActive]="'active-link'"
    (click)="navigatetoModule(item)"
  >
    {{ item.name }}
  </a>
</div> 
   
</nav>
<div
  class="overlay"
  
  (click)="closeMenu()"
  [ngStyle]="{ display: overlay == true ? 'block' : 'none' }"
></div>


