import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-slider-image-sign-content',
  templateUrl: './slider-image-sign-content.component.html',
  styleUrls: ['./slider-image-sign-content.component.scss']
})
export class SliderImageSignContentComponent implements OnInit {
  @Input() component: any;
  slides: any[] = [];
  visible = 3;
  scroll = 1;
  lang: string | null = '';
  showCarousel = false; // متغير جديد للتحكم في إعادة التحميل
  responsiveOptions: any[];

  constructor(private homeService: HomeService, private cd: ChangeDetectorRef) {
    this.responsiveOptions = [
      { breakpoint: '1440px', numVisible: 3, numScroll: 2 },
      { breakpoint: '1024px', numVisible: 2, numScroll: 2 },
      { breakpoint: '768px', numVisible: 1, numScroll: 1 },
      { breakpoint: '430px', numVisible: 1, numScroll: 1 }
    ];
  }

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");

    this.homeService.getHomeComponentsData(this.component).subscribe(
      (res: any) => {
        this.refreshCarousel(res.content);
      },
      (error) => console.error('API Request Failed:', error)
    );
  }

  refreshCarousel(data: any[]) {
    this.showCarousel = false; // إخفاء `p-carousel` مؤقتًا
    this.cd.detectChanges(); // إجبار Angular على تحديث الواجهة

    setTimeout(() => {
      this.slides = [...data]; // تحميل البيانات بعد فترة قصيرة
      this.showCarousel = true; // إظهار `p-carousel` مجددًا
      this.cd.detectChanges(); // فرض إعادة التحديث
    }, 50);
  }
}
