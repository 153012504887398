import { Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChildren } from "@angular/core";

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { HomeService } from "../../../general-pages/pages/home/home.service";

import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {DonationPopupComponent} from '../donation-popup/donation-popup.component';
import { NavigationService } from "../../shared_services/navigation.service";
import { DivideRowStyleService } from "../../shared_services/divide-row-style.service";

@Component({
  selector: "app-button-cards4",
  templateUrl: "./button-cards4.component.html",
  styleUrls: ["./button-cards4.component.scss"],
})
export class ButtonCards4Component implements OnInit {
  @Input() component: any;
  @ViewChildren('videoFrame') videoFrames: QueryList<ElementRef<HTMLIFrameElement>>;
  FourbuttoncardsData: any[] = [];
  currentIndex: number = 0;
  visibleItems: any[] = [];
  itemsToShow: number = 4;
  APIParameters;
  websiteMainColor: any;
 
  lang: string;
  currentLang: string;

  video_array: any[] = [];

  Button_Color: any;
  Button_Background_Color: any;
  main_id: any;
  websiteSecColor: any;

  col: string;
  currentlyPlaying: number | null = null;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private navigationService:NavigationService,
    private colStyleService:DivideRowStyleService

  ) {}

  ngOnInit(): void {

    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

    this.main_id = this.component.main_id;

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Button_Color = this.component.button_color;
    this.Button_Background_Color = this.component.button_background_color

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.FourbuttoncardsData = res.content;
        console.log("FourbuttoncardsData",this.FourbuttoncardsData);
        
        this.col=  this.colStyleService.getColClass(res.content, this.component); 

        this.FourbuttoncardsData = res.content.map(item => ({
          ...item,
          safeUrl: this.sanitizeUrl(item.link != null ? this.transformYouTubeUrl(item.link): '')
        }));

        this.updateItemsToShow();
        this.updateVisibleItems();
      });
    
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
        
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.FourbuttoncardsData = res.content;
        this.col=  this.colStyleService.getColClass(res.content, this.component); 

        console.log("2459",this.FourbuttoncardsData);
        this.FourbuttoncardsData = res.content.map(item => ({
          ...item,
          safeUrl: this.sanitizeUrl(this.transformYouTubeUrl(item.link))
        }));
        this.updateItemsToShow();
        this.updateVisibleItems();
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.component,id,1);

  }

  OpenPopup(data){
    const modalRef = this.modalService.open(DonationPopupComponent, {
    });
    modalRef.componentInstance.fromParent = data;
  }
  updateVisibleItems(): void {
    if (this.FourbuttoncardsData && this.FourbuttoncardsData.length) {
      this.visibleItems = this.FourbuttoncardsData.slice(
        this.currentIndex,
        this.currentIndex + this.itemsToShow
      );
    }
  }

  updateItemsToShow(): void {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 576) {
      this.itemsToShow = 1;
    } else if (screenWidth <= 768) {
      this.itemsToShow = 2;
    } else if (screenWidth <= 992) {
      this.itemsToShow = 3;
    } else {
      this.itemsToShow = 4;
    }
  }
  @HostListener('window:resize')
    onResize(): void {
      this.updateItemsToShow();
      this.updateVisibleItems();
    }
  transformYouTubeUrl(url: string): string {
    let videoId = url.split("watch?v=")[1]?.split("&")[0]; // استخراج ID الفيديو فقط
    return `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&enablejsapi=1&rel=0`;
  }
  
  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  deactivateAllIframes(): void {
    this.currentlyPlaying = null;
  }

  activateIframe(index: number): void {
  console.log('Activating iframe at index:', index);
  this.deactivateAllIframes();
  this.currentlyPlaying = index;

  setTimeout(() => {
    const iframe = this.videoFrames?.toArray()[index]?.nativeElement;
    if (iframe) {
      const originalSrc = this.FourbuttoncardsData[index].safeUrl as string;
      iframe.src = ''; // إزالة المصدر مؤقتًا
      setTimeout(() => {
        iframe.src = originalSrc; // إعادة تعيين المصدر لإجبار إعادة تحميل الفيديو
      }, 100);
    }
  }, 100);
}

  deactivateIframe(index: number): void {
    if (this.currentlyPlaying === index) {
      this.currentlyPlaying = null;
    }
  }

  openFullscreenIframe(index: number): void {
    if (this.videoFrames) {
      const iframe = this.videoFrames.find((_, i) => i === index);
      if (iframe && iframe.nativeElement) {
        if (iframe.nativeElement.requestFullscreen) {
          iframe.nativeElement.requestFullscreen();
        }
      }
    }
  }

  isPlaying(index: number): boolean {
    return this.currentlyPlaying === index;
  }
}
