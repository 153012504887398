import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  @Input() component: any;

  imageTitleData;
  
  visibleItems: any[] = [];
  currentIndex: number = 0;
  itemsToShow: number = 3;
  lang: string;
  currentLang: string;
  outData: any;
  websiteMainColor: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService
    ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.imageTitleData = res.content;
        this.updateItemsToShow();
        this.updateVisibleItems();
        
      });
      this.outData = this.imageTitleData;
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.imageTitleData = res.content;
        });

    });
    this.updateItemsToShow();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.updateItemsToShow();
    this.updateVisibleItems();
  }

  next(): void {
    const maxIndex = this.imageTitleData.length - this.itemsToShow;
    if (this.currentIndex < maxIndex) {
      this.currentIndex++;
      this.updateVisibleItems();
    }
  }

  prev(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateVisibleItems();
    }
  }

  updateVisibleItems(): void {
    this.visibleItems = this.imageTitleData.slice(
      this.currentIndex,
      this.currentIndex + this.itemsToShow
    );
  }

  updateItemsToShow(): void {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 576) {
      this.itemsToShow = 1;
    } else if (screenWidth <= 768) {
      this.itemsToShow = 1;
    } else {
      this.itemsToShow = 3;
    }
  }

}
