<div class="full-width">
    <div class="page-title">
        <div class="container">
            <h3>
            {{'Search Results'|translate}}
        </h3>
        </div>
    </div>
</div>

    <div class="container">
        <div class="row">
            <div *ngFor="let result of SearchList" class="col-md-4 col-sm-6 col-xs-12" style="height: 350px;margin-bottom: 30px !important;">
                <a (click)="navigateToShow(result?.data?.id)">
                    <div class="card"  style="height: 350px;">
                        <img class="card-img-top" [src]="result?.data?.image">
                        <div class="card-body">
                            <div style ="display: flex;">
                                <h6 class="card-title">{{result?.data?.title}}</h6>
                            </div>
             
                                <p class="p-date" *ngIf="result?.data?.module_calendar == 1 ">{{result?.data?.start_date}}</p>
                                <p class="p-date" *ngIf="result?.data?.module_calendar == 2 ">{{result?.data?.start_date_hijri}}</p>
                                <p >{{result?.data?.description}}</p>
               
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <!-- <h3 *ngIf="SearchList && !SearchList.length" class="main-title text-center">{{'No Results'|translate}}</h3> -->
    </div>

