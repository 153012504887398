import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-big-video",
  templateUrl: "./big-video.component.html",
  styleUrls: ["./big-video.component.scss"],
})
export class BigVideoComponent implements OnInit {
  @Input() article: any;

  ComponentTitle: any;
  lang: string;
  currentLang: string;
  main_id;
  videoId: string = null;
  showVideo: boolean = false;
  safeUrl: SafeResourceUrl;
  
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.loadVideoData();

    this.translateService.onLangChange.subscribe(() => {
      this.currentLang = localStorage.getItem("lang");
      this.loadVideoData();
    });
  }

  loadVideoData(): void {
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        console.log("Video data received:", res);
        
        if (res.content && res.content.length > 0 && res.content[0].link) {
          const videoLink = res.content[0].link;
          this.videoId = this.extractVideoId(videoLink);
          if (this.videoId) {
            // Pre-sanitize the URL that will be used when the play button is clicked
            this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              `https://www.youtube.com/embed/${this.videoId}?autoplay=1`
            );
          }
        }

        this.ComponentTitle = this.article.component_title;
        this.main_id = this.article.main_id;
      });
  }

  extractVideoId(url: string): string {
    if (!url) return null;
    
    // Different patterns for YouTube URLs
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    
    return (match && match[2].length === 11) ? match[2] : null;
  }

  playVideo(): void {
    this.showVideo = true;
  }

  navigateToShow(id): void {
    this.navigationService.navigateToShow(this.article, id, 2);
  }
} 