<div [appMyStyles]="component">
    <app-header-title-desc [article]="component"></app-header-title-desc>
</div>
<div [appMyStyles]="component" class="row mb-4">
    <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center"
        *ngIf="component.image_position == 'left'">
        <img class="img-style" src="{{contactImage}}" alt="">
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 custom">
        <form class="full-width" [formGroup]="contactForm" (ngSubmit)="submit(contactForm.value)"
            enctype="multipart/form-data">
            <div class="form-group">
                <input type="text" formControlName="name" class="form-control" placeholder="{{ 'Name' | translate }}"
                    [ngStyle]="{'font-size':component?.title_size, 
                        'color':component?.title_color,
                        'font-weight':component?.data_title_weight
                            
                          }" />
                <span *ngIf="
                            contactForm.get('name').touched && contactForm.get('name').invalid
                        ">
                    <small *ngIf="contactForm.get('name').errors.required" class="form-text required">
                        {{ "VALIDATORS.REQUIRED" | translate }}
                    </small>
                </span>
            </div>

            <div class="form-group">
                <input type="email" formControlName="email" class="form-control" placeholder="{{ 'Email' | translate }}"
                    [ngStyle]="{'font-size':component?.title_size, 
                        'color':component?.title_color,
                        'font-weight':component?.data_title_weight
                            
                          }" />
                <!--Validation-->
                <span *ngIf="
                            contactForm.get('email').touched && contactForm.get('email').invalid
                        ">
                    <small *ngIf="contactForm.get('email').errors.required" class="form-text required">
                        {{ "VALIDATORS.REQUIRED" | translate }}
                    </small>
                    <small *ngIf="contactForm.get('email').errors.pattern" class="form-text required">
                        {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
                    </small>
                </span>
            </div>

            <div class="form-group">
                <input [ngStyle]="{'font-size':component?.title_size, 
                        'color':component?.title_color,
                        'font-weight':component?.data_title_weight
                          }" type="text" (keypress)="acceptOnlyNumbers($event)" formControlName="mobile" maxlength="11"
                    class="form-control" placeholder="{{ 'Mobile' | translate }}" />

                <span *ngIf="
                            contactForm.get('mobile').touched &&
                            contactForm.get('mobile').invalid
                        ">
                    <small *ngIf="contactForm.get('mobile').errors.required" class="form-text required">
                        {{ "VALIDATORS.REQUIRED" | translate }}
                    </small>
                    <small *ngIf="contactForm.get('mobile').errors.pattern" class="form-text required">
                        {{ "phoneLength" | translate }}
                    </small>
                </span>
            </div>

            <div class="form-group">
                <textarea class="form-control" formControlName="message"
                    placeholder="{{ 'GENERAL_PAGES.CONTACT.MESSAGE' | translate }}" [ngStyle]="{'font-size':component?.title_size, 
                        'color':component?.title_color,
                        'font-weight':component?.data_title_weight
                            
                          }"></textarea>
            </div>

            <button type="button" class="btn-custom mb-4" type="submit" [ngStyle]="{
                        'background-color':websiteSecColor ,
                         color: Button_Color,
                        'border-color': websiteSecColor
                        }">
                {{ "GENERAL_PAGES.CONTACT.SEND" | translate }}
            </button>
        </form>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center"
        *ngIf="component.image_position != 'left'">
        <img class="img-style" src="{{contactImage}}" alt="">
    </div>



</div>