<section [appMyStyles]="article"
  class="main__content"
  
>
<section class="main__content__title mb-3">
  <div class="main__content__title-border">
    <h1
      class="main__content__title-title m-0"
      [ngStyle]="{ color: title_font_color, 'font-size': title_font_size , 'font-weight': article?.component_title_weight}"
    >
      {{ ComponentTitle }}
    </h1>
  </div>
</section>


  
<h6 *ngIf="article?.component_description && article?.hide_description == 0" [ngStyle]="{ 'color': article?.desc_font_color, 'font-size': article?.desc_font_size ,'font-weight':article?.component_desc_weight}">
  {{ article?.component_description  }}
</h6>
  <p
    class="main__content__desc"
    *ngIf="hide_title == 0"
    [innerHtml]="News_Title_Data[0]?.title_detailed"
  ></p>
</section>
