<div class="container-fluid" [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>
<div
  
  class="row parralax d-flex"
  [ngStyle]="{
    'background-color':component?.component_background_color,
    'background-image': component?.bg_source == 'component_Bg' && component?.component_background_color==null ?  'url(' + component?.component_background_image+ ')' :
    component?.bg_source != 'component_Bg' && component?.component_background_color==null ? 'url(' + sliderData[0]?.image + ')' :''
  }"
  
  [appMyStyles]="component" 
>
  <div class="general title-btn col-lg-6 col-md-6 col-sm-12" 
  
  [ngClass]="[
   
    component?.content_position_vertical=='bottom' ? 'justify-content-end' : component?.content_position_vertical=='top' ?  'justify-content-start' : 'justify-content-center'
]">
    <h2
     
    [ngStyle]="{'font-size':component?.title_size, 
    'color':component?.title_color,
    'font-weight':component?.data_title_weight,
        'z-index':1
      }"
      [ngClass]="[component?.component_title_position=='center'?'text-center':'text-start w-100',
        component?.content_position_vertical=='bottom'?'w-100':'w-75'
       ]">
      {{ sliderData[0]?.title }}
    </h2>

    <h6
     
      class="component-desc"
      [ngStyle]="{
        color: component.description_color,
        'font-size': component.description_size,
        'font-weight': component?.data_desc_weight
      }"
       [ngClass]="[component?.Style_content_position=='0'?'text-center':'text-start',
         component?.content_position_vertical=='bottom'?'w-100':'w-50'
       ]" 
    >
    {{ sliderData[0]?.description }}

    </h6>
    <div class="d-flex buttons" *ngIf=" component?.component_buttons?.length>0">
      <button  *ngFor="let button of component?.component_buttons | slice:2"
      class="volunteer-btn "
    
      [ngStyle]="{
        'background-color': button?.button_background_color,
                    color: button?.button_color,
                    'border-color':button?.button_background_color,
                    'font-size':button?.button?.font_size,
                    'font-weight':button?.button?.font_weight

      }"
      [ngClass]="[component?.Style_content_position=='0'?'text-center':'text-start']"
    >
      <ng-container *ngIf="lang == 'ar_container'"
        >{{ component?.component_buttons[0]?.button_text }}
      </ng-container>
      <ng-container *ngIf="lang == 'en_container'"
        >{{ component?.component_buttons[0]?.button_text }}
      </ng-container>
    </button>
    </div>
    <div class="overlay" *ngIf="component?.content_position_vertical=='bottom'"></div>
  
  </div>
  

 <div class="col-lg-6 col-md-6 col-sm-12"  *ngIf="sliderData?.length>0" [ngClass]="component?.Style_image_shape=='1'?'img-square':'img-circle'">
  
    <div class="col-lg-12 col-md-12 col-sm-12 py-lg-2 p-md-0 pb-2 mt-2WQ text-center">
      <img class="icon-details" [src]="sliderData[0]?.image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 py-lg-2 p-md-0 pb-2 sec-row-image">
      <img class="icon-details" [src]="sliderData[1]?.image">
      <img class="icon-details" [src]="sliderData[2]?.image">

    </div>
 </div>
 
</div>
<app-alert *ngIf="sliderData?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
[message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>
