import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "./../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-getintouch",
  templateUrl: "./getintouch.component.html",
  styleUrls: ["./getintouch.component.scss"],
})
export class GetintouchComponent implements OnInit {
  @Input() article: any;
  componentData;
  websiteSecColor: any;
  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    console.log(this.article);
    
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
    
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.componentData = res.content;
      
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.componentData = res.content;
        
        });
    });
  }

  // navigateToShow(id) {
  //   // if (this.article.can_navigate == "1") {
  //   this.langService.getLang().subscribe((res) => {
  //     this.router.navigate([`${res.key}/cms/1/${id}`]);
  //   });
  //   // }
  // }
}
