<div [appMyStyles]="component">
  <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="component.component_title_position != 'center'">
    <h4 *ngIf="lang == 'en_container'" class="component-title" [ngStyle]="{
      'border-left-color': websiteMainColor,
      color: component.title_font_color,
      'font-size': component.title_font_size
    }">
      {{ component.component_title }}
    </h4>
    <h4 *ngIf="lang == 'ar_container'" class="component-title" [ngStyle]="{
      'border-right-color': websiteMainColor,
      color: component.title_font_color,
      'font-size': component.title_font_size
    }">
      {{ component.component_title }}
    </h4>
    <div class="title-decoration">
      <span class="line"></span>
      <span class="dots">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </div>
  </div>
  
  <div class="carousel-container position-relative mt-3">
    <!-- Left Navigation -->
    <button class="carousel-button prev" (click)="prev()">
      <i class="fas fa-chevron-left"></i>
    </button>

    <!-- Carousel Items -->
    <div class="row justify-content-center">
      <div
        class="col-lg-4 col-md-12 col-sm-12 media"
        *ngFor="let item of visibleItems"
      >
        <div
          class="bg-image"
          [ngStyle]="{ 'background-image': 'url(' + item?.image + ')' }"
        >
          <div class="text">
            <p class="author"
            [ngStyle]="{
              'font-size': component?.title_size,
              color: component?.title_color,
              'font-weight': component?.data_title_weight
            }">{{ item?.title }}</p>
            <i class="fab fa-instagram"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Navigation -->
    <button class="carousel-button next" (click)="next()">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>

<div
  class="alert alert-warning text-center"
  role="alert"
  *ngIf="imageTitleData?.length == 0"
>
  <p>
    <b>#{{ component?.id }}</b> {{ "InfoAlarm" | translate }}
  </p>
</div>
