import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: 'app-img-title-dec-grid',
  templateUrl: './img-title-dec-grid.component.html',
  styleUrls: ['./img-title-dec-grid.component.scss']
})
export class ImgTitleDecGridComponent implements OnInit {

  @Input() component: any;
  
  Adertising_Data: any[] = [];

  websiteSecColor: any;

  lang: string;
  currentLang: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    private navigationService:NavigationService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.Adertising_Data = res.content;
       
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.Adertising_Data = res.content;
          
        });
    });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.component,id,2);
  }

  NavigateToVoluteer() {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cmsforms`]);
    });
  }

}
