<div class="" [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>


  <div *ngIf="Adertising_Data?.length != 0 &&  Adertising_Data?.length>2" class="row no-padding"
    >
    <div class="col-md-3 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[0]?.id)">
      <img src="{{ Adertising_Data[0]?.image }}" />
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12 " (click)="navigateToShow(Adertising_Data[0]?.id)">
      <h1 class="main-title"
        [ngStyle]="{'font-size':component?.title_size,
         'text-transform':'none', 'color':component?.title_color,'font-weight':component?.data_title_weight}">
        {{Adertising_Data[0]?.title}}</h1>
      <div class="lines">
        <div class="line"></div>
      </div>
      <h5 class="main-paragraph"
        [ngStyle]="{'font-size':component?.description_size,'text-transform':'lowercase', 'color':component?.description_color,'font-weight':component?.data_desc_weight}">
        {{Adertising_Data[0]?.description}}</h5>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[1]?.id)">
      <img src="{{ Adertising_Data[1]?.image }}" />
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12 " (click)="navigateToShow(Adertising_Data[1]?.id)">
      <h1 class="main-title"
        [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}">
        {{Adertising_Data[0]?.title}}</h1>
      <div class="lines">

        <div class="line"></div>
      </div>
      <h5 class="main-paragraph"
        [ngStyle]="{'font-size':component?.description_size,'text-transform':'lowercase', 'color':component?.description_color,'font-weight':component?.data_desc_weight}">
        {{Adertising_Data[0]?.description}}</h5>
    </div>

    <div class="col-md-3 col-sm-6 col-xs-12 padd" (click)="navigateToShow(Adertising_Data[2]?.id)">
      <h1 class="main-title"
        [ngStyle]="{'font-size':component?.title_size, 
         'text-transform':'none','color':component?.title_color,'font-weight':component?.data_title_weight}">
        {{Adertising_Data[0]?.title}}</h1>
      <div class="lines">
        <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
        <div class="line"></div>
      </div>
      <h5 class="main-paragraph"
        [ngStyle]="{'font-size':component?.description_size,'text-transform':'lowercase', 'color':component?.description_color,'font-weight':component?.data_desc_weight}">
        {{Adertising_Data[0]?.description}}</h5>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[2]?.id)">
      <img src="{{ Adertising_Data[2]?.image }}" />
    </div>

    <div class="col-md-3 col-sm-6 col-xs-12 " (click)="navigateToShow(Adertising_Data[3]?.id)">
      <h1 class="main-title"
        [ngStyle]="{'font-size':component?.title_size,
         'text-transform':'none', 'color':component?.title_color,'font-weight':component?.data_title_weight}">
        {{Adertising_Data[0]?.title}}</h1>
      <div class="lines">
        <!-- <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div> -->
        <div class="line"></div>
      </div>
      <h5 class="main-paragraph"
        [ngStyle]="{'font-size':component?.description_size,'text-transform':'lowercase', 'color':component?.description_color,'font-weight':component?.data_desc_weight}">
        {{Adertising_Data[0]?.description}}</h5>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[3]?.id)">
      <img src="{{ Adertising_Data[3]?.image }}" />
    </div>
  </div>
  <div *ngIf="Adertising_Data?.length != 0  &&  Adertising_Data?.length<=2" class="row no-padding"
    >
    <div class="col-md-6 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[0]?.id)">
      <img src="{{ Adertising_Data[0]?.image }}" />
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 " (click)="navigateToShow(Adertising_Data[0]?.id)">
      <h1 class="main-title"
        [ngStyle]="{'font-size':component?.title_size,
         'text-transform':'none', 'color':component?.title_color,'font-weight':component?.data_title_weight}">
        {{Adertising_Data[0]?.title}}</h1>
      <div class="lines">
        <div class="line"></div>
      </div>
      <h5 class="main-paragraph"
        [ngStyle]="{'font-size':component?.description_size,'text-transform':'lowercase', 'color':component?.description_color,'font-weight':component?.data_desc_weight}">
        {{Adertising_Data[0]?.description}}</h5>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 no-padding" (click)="navigateToShow(Adertising_Data[1]?.id)">
      <img src="{{ Adertising_Data[1]?.image }}" />
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12 " (click)="navigateToShow(Adertising_Data[1]?.id)">
      <h1 class="main-title"
        [ngStyle]="{'font-size':component?.title_size, 
         'text-transform':'none','color':component?.title_color,'font-weight':component?.data_title_weight}">
        {{Adertising_Data[0]?.title}}</h1>
      <div class="lines">

        <div class="line"></div>
      </div>
      <h5 class="main-paragraph"
        [ngStyle]="{'font-size':component?.description_size,'text-transform':'lowercase', 'color':component?.description_color,'font-weight':component?.data_desc_weight}">
        {{Adertising_Data[0]?.description}}</h5>
    </div>


  </div>
</div>

<app-alert *ngIf="Adertising_Data?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
[message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>