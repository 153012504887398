<div [appMyStyles]="component">

  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>
  <div
  [appMyStyles]="component"
    class="d-flex align-items-around"
    
    [ngClass]="{ 'flex-row-reverse': component?.image_position!='left' }"
   
  >
    <div 
      class="col-lg-6 col-md-5 col-sm-12 col-xs-12 background"
      [ngStyle]="{
        'background-image': isDefaultBackground(component?.component_background_image) ? 'none' : 'url(' + component?.component_background_image + ')'
      }" [ngClass]="{ 'reverse': component?.image_position!='left', 'mx-3':component?.full_width === 0 }">
      <!-- <img *ngIf="imageTitleData?.length>0" [ngClass]="{ 'mt-4':component?.component_background_image }" [ngStyle]="{
          'rounded-sm': isCurve
        }" class="main-img" src="{{ imageTitleData[0]?.image }}" alt="" /> -->
    </div>

    <div class="col-lg-6 col-md-7 col-sm-12 col-xs-12 px-4">

      <div class="row" *ngFor="let item of imageTitleData | slice : 0 : component.items_number"
        style="margin-bottom: 20px;">
        <div class="col-md-3 col-sm-4 col-xs-4 img-bg"  *ngIf="item.image != null" >
          <img class="icon_benfit rounded-lg  bg-white rounded" src="{{ item.image }}">
        </div>
        <div class="col-md-1 col-sm-1 col-xs-1 pt-1" *ngIf="item.image == null" >
          <i class="fa fa-circle" aria-hidden="true" [ngStyle]="{'color':websiteMainColor}"></i>
        </div>

        <div class="col-md-9 col-sm-8 col-xs-8 align-items-center content">
          <p [ngStyle]="{'font-size':component?.title_size, 
          'color':component?.title_color,
          'font-weight':component?.data_title_weight,
              'z-index':1
            }" [ngClass]="[component?.component_title_position=='center'?'text-center':'text-start w-100',
              component?.content_position_vertical=='bottom'?'w-100':'w-75'
             ]">
            {{item.title}}
          </p>
          <span class="dis w-100" [ngStyle]="{
            color: component.description_color,
            'font-size': component.description_size,
            'font-weight': component?.data_desc_weight
          }" [ngClass]="[component?.Style_content_position=='0'?'text-center':'text-start'
           ]">
            {{item.description}}
          </span>
        </div>

      </div>

    </div>
  </div>


  <app-alert *ngIf="imageTitleData?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
    [message]="'InfoAlarm' | translate" [type]="'warning'">
  </app-alert>
