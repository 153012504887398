import { Component, Input, OnInit } from "@angular/core";

import { DomSanitizer } from "@angular/platform-browser";
import { HomeService } from "../../../general-pages/pages/home/home.service";

import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {DonationPopupComponent} from '../donation-popup/donation-popup.component';
import { NavigationService } from "../../shared_services/navigation.service";
import { DivideRowStyleService } from "../../shared_services/divide-row-style.service";

@Component({
  selector: "app-button-cards4",
  templateUrl: "./button-cards4.component.html",
  styleUrls: ["./button-cards4.component.scss"],
})
export class ButtonCards4Component implements OnInit {
  @Input() component: any;

  APIParameters;
  FourbuttoncardsData;
  websiteMainColor: any;
 
  lang: string;
  currentLang: string;

  video_array: any[] = [];

  Button_Color: any;
  Button_Background_Color: any;
  main_id: any;
  websiteSecColor: any;


  col: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private navigationService:NavigationService,
    private colStyleService:DivideRowStyleService

  ) {}

  ngOnInit(): void {

    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

    this.main_id = this.component.main_id;

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Button_Color = this.component.button_color;
    this.Button_Background_Color = this.component.button_background_color

    // this.APIParameters =JSON.parse(localStorage.getItem('button-cards4-apiparameters'));
    //  

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.FourbuttoncardsData = res.content;
        this.col=  this.colStyleService.getColClass(res.content);
        console.log("FourbuttoncardsData ",this.component);
      });
    
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
         
        });
    });
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.component,id,1);

  }

  OpenPopup(data){
    const modalRef = this.modalService.open(DonationPopupComponent, {
      // scrollable: true,
      // windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
    modalRef.componentInstance.fromParent = data;
  }

}
