<div class="position-relative py-3">
  <!-- Title Section -->
  <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="component.component_title_position != 'center'">
    <h4 *ngIf="lang == 'en_container'" class="component-title-left" [ngStyle]="{
      'border-left-color': websiteMainColor,
      color: component.title_font_color,
      'font-size': component.title_font_size
    }">
      {{ component.component_title }}
    </h4>
    <h4 *ngIf="lang == 'ar_container'" class="component-title-right" [ngStyle]="{
      'border-right-color': websiteMainColor,
      color: component.title_font_color,
      'font-size': component.title_font_size,
      'text-transform':'none'
    }">
      {{ component.component_title }}
    </h4>
  </div>

  <!-- Carousel Container -->
  <div class="mt-3 mb-5 position-relative">
    <div
      class="row p-5"
      [ngStyle]="{
        'background-image': component?.component_background_image !== 'https://limberx.com/test/limberx_generator_api/public/img/Default_background.png' 
          ? 'url(' + component?.component_background_image + ')' 
          : 'none'
      }"
      
      [appMyStyles]="component"
    >
      <div
        class="review-card col-lg-3 col-md-6 col-sm-6 d-flex flex-column align-items-center p-3"
        *ngFor="let item of visibleItems"
      >
        <div class="review-bubble p-3">
          <div class="description-box">
            <p class="review-text mb-3" [ngStyle]="{
              'font-size': component?.description_size,
              'text-transform':'lowercase',
              color: component?.description_color,
              'font-weight': component?.data_desc_weight
            }">
              {{ item.description }}
            </p>
            <div class="stars mb-3">
              <span *ngFor="let star of [1, 2, 3, 4, 5]; let i = index">
                <i [class]="i < item.rating ? 'fas fa-star' : 'far fa-star'"></i>
              </span>
            </div>
          </div>
          <div class="arrow-down"></div>
        </div>
        <div class="profile">
          <img [src]="item.image" alt="Profile" class="profile-img" />
          <p class="profile-name" [ngStyle]="{
            'font-size': component?.title_size,
            color: component?.title_color,
            'font-weight': component?.data_title_weight
          }">
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>

    <div class="carousel-button next" (click)="next()">
      <i class="fas fa-chevron-right"></i>
    </div>
  </div>
</div>


<app-alert *ngIf="FourbuttoncardsData?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
[message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>