<div class="container" [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>
<div
  [appMyStyles]="component"
  *ngIf="gridCardsData?.length > 0"
  class="row align-items-stretch row-content mb-5"
  
>
 
  <div class="col-md-6 col-sm-6 col-xs-12 h-100">
    <div class="row h-100">
      <div
        *ngFor="let news of gridCardsData | slice : 1 : 5; index as i"
        class="col-md-6 col-sm-6 col-xs-12 mb-3"
        (click)="navigateToShow(news.id)"
      >
        <div class="card card2">
          <div
            *ngIf="news.type == 'image'"
            class="card-img-top"
            [style]="{ 'background-image': 'url(' + news.image + ')' }"
          ></div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="event-desc text-left">
                  <h4
                    class="card-title"
                    [ngStyle]="{
                      'font-size': component?.title_size,
                      color: component?.title_color,
                      'font-weight': component?.data_title_weight,
        'text-transform':'none'

                    }"
                  >
                    {{ news.title }}
                  </h4>
                  <p
                  [ngStyle]="{
                    'font-size': component?.description_size,
                    'text-transform':'lowercase',
                    color: component?.description_color,
                    'font-weight': component?.data_desc_weight
                  }"
                  class="card-paragraph"
                >
                  {{ news.description }}
                </p>
                <a
                  class="more"
                  [ngStyle]="{
                    'background-color':
                      component?.component_buttons[0]?.button_background_color,
                    color: component?.component_buttons[0]?.button_color,
                    'border-color':
                      component?.component_buttons[0]?.button_background_color,
                    'font-size':
                      component?.component_buttons[0]?.button?.font_size,
                    'font-weight':
                      component?.component_buttons[0]?.button?.font_weight
                  }"
                >
                  <ng-container *ngIf="lang == 'ar_container'"
                    >{{ component?.component_buttons[0]?.button_text }}
                  </ng-container>
                  <ng-container *ngIf="lang == 'en_container'"
                    >{{ component?.component_buttons[0]?.button_name }}
                  </ng-container>
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-6 col-xs-12 h-100">
    <div class="card" (click)="navigateToShow(gridCardsData[0].id)">
      <div
        *ngIf="gridCardsData[0].type == 'image'"
        class="card-img-top"
        [style]="{
          'background-image': 'url(' + gridCardsData[0].image + ')'
        }"
      ></div>
      <div class="card-body" pt-2>
        <div class="row h-100">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="text-left">
              <h4
                class="card-title"
                [ngStyle]="{
                  'font-size': component?.title_size,
                  color: component?.title_color,
                  'font-weight': component?.data_title_weight,
        'text-transform':'none'

                }"
              >
                {{ gridCardsData[0].title }}
              </h4>
              <p
                class="card-paragraph"
                [ngStyle]="{
                  'font-size': component?.description_size,
                  'text-transform':'lowercase',
                  color: component?.description_color,
                  'font-weight': component?.data_desc_weight
                }"
              >
                {{ gridCardsData[0].description }}
              </p>

              <a
                class="more one"
                [ngStyle]="{
                  'background-color':
                    component?.component_buttons[0]?.button_background_color,
                  color: component?.component_buttons[0]?.button_color,
                  'font-size':
                    component?.component_buttons[0]?.button?.font_size,
                  'font-weight':
                    component?.component_buttons[0]?.button?.font_weight
                }"
              >
                <ng-container *ngIf="lang == 'ar_container'"
                  >{{ component?.component_buttons[0]?.button_text }}
                </ng-container>
                <ng-container *ngIf="lang == 'en_container'"
                  >{{ component?.component_buttons[0]?.button_name }}
                </ng-container>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="alert alert-warning text-center"
  role="alert"
  *ngIf="gridCardsData?.length == 0"
>
  <p>
    <b>#{{ component?.id }}</b> {{ "InfoAlarm" | translate }}
  </p>
</div>

<app-alert *ngIf="gridCardsData?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
[message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>