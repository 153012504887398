<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
</div>
<div class="container-fluid">
  <div [appMyStyles]="article" class="row mx-3">

    <div class="col-md-6 position-relative" *ngIf="TwoTitleDescriptionCardData?.length>0">
      <div class="tooltip-container">
        <img class="main-img" [src]="TwoTitleDescriptionCardData[0]?.image" alt="Main Image">
    
        <!-- Tooltip Image -->
        <div class="tooltip-content">
          <img [src]="TwoTitleDescriptionCardData[1]?.image" alt="Tooltip Image">
        </div>
      </div>
    </div>    

    <div class="col-md-6 col-sm-12 col-xs-12" *ngIf="TwoTitleDescriptionCardData?.length>0">


      <h2 [ngStyle]="{
          'font-size': article.title_font_size,
          color: article.title_font_color,
          'font-weight': article?.component_title_weight
        }">
        {{ TwoTitleDescriptionCardData[0]?.title }}
      </h2>
      <p [ngStyle]="{
          'font-size': article.description_size,
          color: article.descriptiont_color
        }">
        {{ TwoTitleDescriptionCardData[0]?.description }}
      </p>

      <h3 id="header2" class="mt-3">Nearby Landmarks of Zed Towers Compound El Sheikh Zayed:</h3>

      <ul>
        <li id="item1_1">Americana Plaza is conveniently located just 8 minutes from Zed Towers El Sheikh Zayed,
          offering residents an easy getaway for leisure and shopping.</li>
        <li id="item2_1">Zed Sheikh Zayed Project’s access to major roads, including Nozha Road, the 26th of July Axis,
          and the Cairo-Alexandria Desert Road, facilitate smooth travel to and from the city, making it an ideal
          location for those commuting to different parts of Cairo and beyond.</li>
        <li id="item3_1">Al-Ahly Club is positioned close to Zed Towers Ora, allowing residents to enjoy premium sports
          facilities and social clubs within a short distance, enhancing the community’s lifestyle.</li>
        <li id="item4_1">Compound Zed Towers El Sheikh Zayed enjoys a prime location, with Hyper One and Arkan Plaza
          only a 3-minute away, making shopping and dining experiences easily accessible for residents.</li>
        <li id="item5_1">Zed Sheikh Zayed Compound ensures seamless connectivity to Heliopolis and the Fifth Settlement,
          two of Cairo’s most prestigious neighborhoods, known for their upscale amenities, schools, and business hubs.
        </li>
        <li id="item6_1">Zed Towers Compound El Sheikh Zayed neighbors the esteemed Ivoire Compound Sheikh Zayed and La
          Colina Compound Sheikh Zayed, offering residents the advantage of being part of a well-established community.
        </li>
      </ul>

    </div>


  </div>
</div>


<app-alert *ngIf="TwoTitleDescriptionCardData?.length == 0" [componentId]="article?.id" [mainId]="article?.main_id"
  [message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>