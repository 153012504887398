<div class="row footer" [ngStyle]="{ 'background-color': Footer_Bg_Color }">
  <div class="col-lg-2 col-md-1"></div>

  <div class="col-lg-8 col-md-10 col-sm-12 text-center">
    <!-- Social Links -->
    <div class="social">
      <ul class="list-unstyled list-inline" *ngIf="SocialLinks">
        <li class="list-inline-item" *ngFor="let link of SocialLinks">
          <a *ngIf="link.value && link.footer == 1" href="{{ link.value }}" target="_blank">
            <img class="icon" [src]="link.icon" alt="Social Icon">
          </a>
        </li>
      </ul>
    </div>

    <!-- Footer Menu -->
    <div class="footer-menu">
      <ul class="edit">
        <ng-container *ngFor="let item of MenuItems">
          <li *ngIf="item.menu_design.footer == 1"
              (click)="navigatetoModule(item)"
              class="list-item"
              [ngStyle]="{'font-size': footer_size, 'color': footer_color}">
            <a>{{ item.name }}</a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <div class="col-lg-2 col-md-1"></div>
</div>

<!-- Copyright Footer -->
<app-links-footer [parentStyle]="passStyle" *ngIf="copyWriteFooter == 1"></app-links-footer>
