import { Component, Input, OnInit } from '@angular/core';
import { HomeService } from '../../../general-pages/pages/home/home.service';
import { LangService } from '../../shared_services/lang.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviourSubjectService } from 'src/app/services/behaviour-subject.service';

@Component({
  selector: 'app-image-title-description',
  templateUrl: './image-title-description.component.html',
  styleUrls: ['./image-title-description.component.scss'],
})
export class ImageTitleDescriptionComponent implements OnInit {
  @Input() component: any;
  imageTitleDescriptionData;
  websiteMainColor: any;
  websiteSecColor: any;
  lang: string;
  currentLang: string;
  Data;

  // Static calendar-specific variables
  currentDate = new Date();
  currentMonth: string;
  currentYear: number;
  daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  calendarDays: Date[] = [];
  selectedDate: Date | null = null;
  staticAppointments = [
    { date: '2023-12-04', time: '10:00 AM - 12:00 PM' },
    { date: '2023-12-10', time: '2:00 PM - 3:00 PM' },
    { date: '2023-12-15', time: '11:00 AM - 1:00 PM' },
    { date: '2023-12-20', time: '3:00 PM - 4:30 PM' },
    { date: '2023-12-30', time: '9:00 AM - 5:00 PM' },
  ];

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    protected behaviorService: BehaviourSubjectService
  ) {}

  ngOnInit(): void {
    // Language and color initialization
    this.lang = localStorage.getItem('container_lang');
    this.currentLang = localStorage.getItem('lang');
    this.websiteMainColor = JSON.parse(localStorage.getItem('mainColor'));
    this.websiteSecColor = JSON.parse(localStorage.getItem('secColor'));

    // Fetch component data
    this.fetchComponentData();

    // Initialize calendar
    this.updateCalendar();
  }

  fetchComponentData(): void {
    this.homeService.getHomeComponentsData(this.component).subscribe((res: any) => {
      this.imageTitleDescriptionData = res.content[0];
      this.Data = res.content;
    });
  }

  // Calendar logic
  updateCalendar(): void {
    const firstDayOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);

    this.currentMonth = firstDayOfMonth.toLocaleString('default', { month: 'long' });
    this.currentYear = firstDayOfMonth.getFullYear();

    this.calendarDays = [];
    const startDay = firstDayOfMonth.getDay() || 7;
    const startDate = new Date(firstDayOfMonth);
    startDate.setDate(startDate.getDate() - (startDay - 1));

    for (let i = 0; i < 42; i++) {
      this.calendarDays.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }
  }

  prevMonth(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.updateCalendar();
  }

  nextMonth(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.updateCalendar();
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }
  
  getAppointmentsForDate(date: Date): any[] {
    return this.staticAppointments.filter(
      (appointment) => new Date(appointment.date).toDateString() === date.toDateString()
    );
  }

  selectDate(date: Date): void {
    const appointmentsForDate = this.getAppointmentsForDate(date);
    if (appointmentsForDate.length) {
      alert(`Appointments on ${date.toDateString()}:\n${appointmentsForDate.map((a) => a.time).join('\n')}`);
    } else {
      alert('No appointments available on this date.');
    }
  }
}
