<div class="row" [ngStyle]="{ 'background-color': Footer_Bg_Color }">
  <div class="col-md-4 col-sm-12 col-xs-12 text-center">
    <p
      *ngIf="website_name != null && APP_ID != 359 && APP_ID != 384"
      style="color: white; padding: 30px 0px; text-align: center"
      [ngStyle]="{ 'font-size':Footer_font_size}"
    >
      {{ website_name}}
      <!-- <span *ngIf="contactData.address  != null  || contactData.address  != '' " 
             style="color: white; padding: 0px 5px"
             [ngStyle]="{ 'font-size':Footer_font_size}"
        >
        <i class="fa fa-map-marker"></i>
        </span> -->
      <!-- {{ contactData?.address }} -->
    </p>
  </div>

  <div class="col-md-4 col-sm-12 col-xs-12 text-center">
    <p class="text-center" style="color: white; padding: 30px 0px">
      <a class="footer-link" style="color: white;" >{{ "FOOTER.COPY_RIGHT" | translate }}</a>
    </p>
  </div>

  <div class="col-md-4 col-sm-12 col-xs-12 text-center">
    <div class="social">
      <ul class="list-unstyled list-inline" *ngIf="SocialLinks">
        <li class="list-inline-item" *ngFor="let link of SocialLinks">
          <a
            *ngIf="link.value != null"
            class="btn-floating btn-sm rgba-white-slight"
            href="{{ link?.value}}"
            target="_blank"
          >
           <img [src]="link?.icon" alt=""> 
         
          </a>
        </li>
      </ul>
    </div>
  </div>
  
</div>
