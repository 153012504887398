<div class="container" [appMyStyles]="article">
  <div
    class="col-md-12 col-sm-12 col-xs-12"
    
    style="text-align: center; margin-bottom: 20px"
    *ngIf="component_title_position == 'center'"
  >
    <h3
      style="font-weight: bold"
      [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
    >
      {{ ComponentTitle }}
    </h3>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>

  <div
    class="col-md-12 col-sm-12 col-xs-12"
    
    *ngIf="component_title_position != 'center'"
  >
    <h4
      *ngIf="lang == 'en_container'"
      class="component-title-left"
      [ngStyle]="{
        'border-left-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h4
      *ngIf="lang == 'ar_container'"
      class="component-title-right"
      [ngStyle]="{
        'border-right-color': websiteMainColor,
        color: title_font_color,
        'font-size': title_font_size
      }"
    >
      {{ ComponentTitle }}
    </h4>
    <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
      {{ component_sub_title }}
    </h6>
  </div>

  <div
    class="row"
    
  >
    <div class="col-md-5 col-sm-5 col-xs-12"></div>

    <div class="col-md-7 col-sm-7 col-xs-12">
      <p
        class="paragraph"
        (click)="navigateToShow(Text_Card_Data[0].id)"
        *ngIf="
          hide_title == 1 &&
          Text_Card_Data[0].title_detailed &&
          html_content == 1
        "
        [innerHtml]="Text_Card_Data[0]?.title_detailed"
      ></p>
      <p
        *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
        [innerHtml]="Text_Card_Data[0].description_detailed"
      ></p>

      <p
        class="paragraph"
        (click)="navigateToShow(Text_Card_Data[0].id)"
        *ngIf="hide_title == 1 && Text_Card_Data[0].title && html_content == 0"
      >
        {{ Text_Card_Data[0]?.title }}
      </p>
      <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
        {{ Text_Card_Data[0].description }}
      </p>
    </div>
  </div>
</div>
