<div [appMyStyles]="article">
  <h3
    *ngIf="hide_title"
    class="p-2 pt-5"
    [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
    [ngClass]="{ 'text-center': component_title_position }"
  >
    {{ ComponentTitle }}
  </h3>
  <div [class]="article?.animations?.length > 1 ? article?.animations[1] : ''">
    <div class="d-flex row justify-content-center mt-2">
      <button
        class="btn rounded-0 btn-light px-5 py-3"
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color
        }"
      >
        Clothes
      </button>
      <button
        class="btn rounded-0 btn-light px-5 py-3"
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color
        }"
      >
        Clothes
      </button>
      <button
        class="btn rounded-0 btn-light px-5 py-3"
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color
        }"
      >
        Clothes
      </button>
      <button
        class="btn rounded-0 btn-light px-5 py-3"
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color
        }"
      >
        Clothes
      </button>
    </div>
    <div class="d-flex row flex-wrap justify-content-start mx-5">
      <img
        *ngFor="let item of imageTitleData | slice : 0 : article.items_number"
        class="border_radius img my-3 col-md-4 col-xs-12 col-sm-12 col-12"
        src="{{ item.image }}"
      />
    </div>
  </div>
</div>
