<div [appMyStyles]="component">

  <app-header-title-desc [article]="component"></app-header-title-desc>

  <div class="row mt-4 d-flex align-items-center">
    <div [ngClass]="mainImg== null?'col-lg-12 col-sm-12':'col-lg-9 col-sm-8'">
      <div *ngFor="let item of faqData; index as g" id="g_{{ g }}">
        <ng-container *ngIf="item?.title != null && item?.description !=null">
          <div class="separator-short"></div>
          <div class="accordion">
            <div class="accordion-item" (click)="showAnswer(g, g)">
              <div class="accordion-order"></div>
              <span class="target-fix" id="accordion-2-1"></span>
              <i class="fa fa-angle-down icon" aria-hidden="true"></i>
              <a id="open-accordion-2-1" title="open"
                [ngStyle]="{'font-size':component?.title_size, 
        'text-transform':'none'
        ,'color':component?.title_color,'font-weight':component?.data_title_weight}">
                {{item?.title}}
              </a>
              <span class="display--none answer" id="ans_q{{g}}_g{{g}}">
                <p
                  [ngStyle]="{'font-size':component?.description_size,'text-transform':'lowercase', 'color':component?.description_color,'font-weight':component?.data_desc_weight}">
                  {{item?.description}}
                </p>

              </span>
            </div>

          </div>
        </ng-container>

      </div>
    </div>
    <div class="col-lg-3 col-sm-4" *ngIf="mainImg!= null">
      <img class="mainImg" [src]="mainImg">
    </div>
  </div>
</div>

<app-alert *ngIf="faqData?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
  [message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>