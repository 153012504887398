<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div
    class="d-flex row justify-content-around"
    
  >
  <div class="col-lg-5 col-md-5 col-sm-6 row justify-content-start m_bottom">
    <img class="col-lg-8 col-md-8 col-sm-12 first-img" src="{{ ImageTitleData[0]?.image }}" />
    <img class="sec-image col-lg-5 col-md-5 col-sm-6" src="{{ ImageTitleData[1]?.image }}" />
  </div>
    <div class="col-lg-5 col-md-5 px-1">
      <h3
        class="color py-1"
        [ngStyle]="{
          'font-size': article?.title_size,
          color: article?.title_color,
          'font-weight': article?.data_title_weight,
          'text-transform':'none'
        }"
      >{{ImageTitleData[0]?.title}}</h3>
      <p
        class="py-1"
        [ngStyle]="{
          'font-size': article?.description_size,
          'text-transform':'lowercase',

          color: article?.description_color,
          'font-weight': article?.data_desc_weight
        }"
      
      >{{ImageTitleData[0]?.description}}</p>
    </div>
  </div>
  <div
    class="d-flex row justify-content-around flex-row-reverse"
    
  >
  <div class="col-lg-5 col-md-5 col-sm-6 row justify-content-start m_bottom mr-3">
    <img class="col-lg-8 col-md-8 col-sm-12 first-img" src="{{ ImageTitleData[2]?.image }}" />
    <img class="sec-image col-lg-5 col-md-5 col-sm-6" src="{{ ImageTitleData[0]?.image }}" />
  </div>
    <div class="col-lg-5 col-md-5 px-1">
      <h3
      class="color py-1"
      [ngStyle]="{
        'font-size': article?.title_size,
        color: article?.title_color,
        'font-weight': article?.data_title_weight,
        'text-transform':'none'
      }"
    >{{ImageTitleData[1]?.title}}</h3>
    <p
      class="py-1"
      [ngStyle]="{
        'font-size': article?.description_size,
        'text-transform':'lowercase',

        color: article?.description_color,
        'font-weight': article?.data_desc_weight
      }"
    
    >{{ImageTitleData[1]?.description}}</p>
    </div>
  </div>
</div>
<app-alert *ngIf="ImageTitleData?.length == 0" [componentId]="article?.id" [mainId]="article?.main_id"
[message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>