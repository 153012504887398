<div class="row">
  <div class="col-md-9 col-sm-9 col-xs-9"     
      
    >
    <h4
      *ngIf="lang == 'en_container'"
      class="component-title-left"
      [ngStyle]="{ 'border-left-color': websiteMainColor }"
    >
      {{ ComponentTitle }}
    </h4>
    <h4
      *ngIf="lang == 'ar_container'"
      class="component-title-right"
      [ngStyle]="{ 'border-right-color': websiteMainColor }"
    >
      {{ ComponentTitle }}
    </h4>
  </div>
</div>

<div class="row" [appMyStyles]="article">
  <div
    class="col-md-4 col-sm-12 col-xs-12"
    
    *ngFor="let item of LocationCardsData | slice : 0 : 6"
  >
    <a (click)="navigateToShow(item.id)">
      <div
        class="card"
        onMouseOver="this.style.outline='thin websiteMainColor solid'"
        onMouseOut="this.style.outline='thin transparent solid'"
      >
        <div
          class="card-img-top"
          [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }"
        ></div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3 col-sm-4 xol-xs-4" *ngIf="item.end_date != ''">
              <time
                datetime=""
                class="icon"
                *ngIf="lang == 'ar_container' && item.end_date != ''"
              >
                <em>{{ item.end_date | dateFormatter : "ddd" : "ar" }}</em>
                <strong
                  [ngStyle]="{ 'background-color': websiteMainColor }"
                  >{{ item.end_date | dateFormatter : "MMM" : "ar" }}</strong
                >
                <span>{{ item.end_date | dateFormatter : "D" : "ar" }}</span>
              </time>

              <time
                datetime=""
                class="icon"
                *ngIf="lang == 'en_container' && item.end_date != ''"
              >
                <em>{{ item.end_date | dateFormatter : "ddd" : "en" }}</em>
                <strong
                  [ngStyle]="{ 'background-color': websiteMainColor }"
                  >{{ item.end_date | dateFormatter : "MMM" : "en" }}</strong
                >
                <span>{{ item.end_date | dateFormatter : "D" : "en" }}</span>
              </time>
            </div>
            <div class="col-md-9 col-sm-9 col-xs-9" *ngIf="item.end_date != ''">
              <div class="event-desc text-left">
                <h4 class="card-title">
                  {{ item.title }}
                </h4>
                <!-- <p> <i class="fa fa-map-marker"></i>&nbsp;  </p> -->
                <a [ngStyle]="{ color: websiteMainColor }" class="more"
                  >{{ "HOME.VIEW_DETAILS" | translate }}
                  <i class="fa fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>

            <div
              class="col-md-12 col-sm-12 col-xs-12"
              *ngIf="item.end_date == ''"
            >
              <div class="event-desc text-left">
                <h4 class="card-title">
                  {{ item.title }}
                </h4>
                <!-- <p> <i class="fa fa-map-marker"></i>&nbsp;  </p> -->

                <a [ngStyle]="{ color: websiteMainColor }" class="more"
                  >{{ "HOME.VIEW_DETAILS" | translate }}
                  <i class="fa fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
