<div class="container-fluid">
  <div
    class="row"
    
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      style="text-align: center; margin-bottom: 20px"
      *ngIf="component_title_position == 'center'"
    >
      <h3
        style="font-weight: bold"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h3>
    </div>

    <div
      class="col-md-12 col-sm-12 col-xs-12"
      *ngIf="component_title_position != 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        [ngStyle]="{
          'border-left-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
    </div>
  </div>

  <div class="row">
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      
      style="text-align: center"
    >
      <div class="tabbed">
        <ng-container *ngFor="let item of Gallery_Data | slice: 0:article?.items_number; index as i">
          <input type="radio" id="tab{{ i }}" name="css-tabs" [checked]="i === 0" />
        </ng-container>

        <ul class="tabs">
          <li class="tab" *ngFor="let item of Gallery_Data | slice: 0:article?.items_number; index as i" [class.active]="i === 0">
            <label
              [ngStyle]="{'font-size': article?.title_size, 'color': article?.title_color, 'font-weight': article?.data_title_weight}"
              for="tab{{ i }}"
            >
              {{ item.title }}
            </label>
          </li>
        </ul>

        <div class="tab-content" *ngFor="let item of Gallery_Data | slice: 0:article?.items_number; index as i" [hidden]="i !== 0">
          <div class="row" [appMyStyles]="article">
            <div class="col-md-4 col-sm-6 col-xs-12" *ngFor="let galleryItem of Gallery_Data">
              <div class="home-article hover-style">
                <a
                  class="cover-img"
                  (click)="openNew(galleryItem)"
                  [ngStyle]="{ 'background-image': 'url(' + galleryItem.image + ')' }"
                >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
