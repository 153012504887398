<div class="row cards">
  <div *ngFor="let event of paginatedList" class="col-md-4 col-sm-6 col-xs-12">
    <app-card-event [eventDetails]="event"></app-card-event>
  </div>
</div>

<!-- Pagination Controls -->
<div class="has-text-centered">
  <pagination-controls *ngIf="totalItems > itemsPerPage" 
                       (pageChange)="onPageChange($event)" 
                       directionLinks="true" 
                       responsive="true" 
                       previousLabel="السابق" 
                       nextLabel="التالى">
  </pagination-controls>
</div>
