import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string): SafeResourceUrl {
    if (!url) return '';

    // Convert normal YouTube links to embed links
    let embedUrl = url.replace('watch?v=', 'embed/');

    return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }
}
