<header class="fixed-header" [ngStyle]="{ 'background-color': Header_Bg_Color }">
  <div class="full-width d-flex justify-content-end align-items-center">
    <ul class="navbar-nav lang-switcher">
      <li
        *ngIf="lang.key == 'ar'"
        (click)="changeLang('en')"
        class="lang-button"
      >
        <a class="d-flex"> English </a>
      </li>

      <li
        *ngIf="lang.key == 'en'"
        (click)="changeLang('ar')"
        class="lang-button"
      >
        <a class="d-flex"> العربية </a>
      </li>
    </ul>
  </div>
</header>
