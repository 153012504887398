
  <div [appMyStyles]="article"
    class="row padd mt-0"
    [ngClass]="isDefaultBackground(ContactImg) ? 'justify-content-center':''"
  >

    <div class="col-md-6 col-sm-12 col-xs-12"
   >
      <form
        class="custom full-width form-padding"
        [formGroup]="contactForm"
        (ngSubmit)="submit(contactForm.value)"
        enctype="multipart/form-data"
      >
        <div class="form-group">
          <input
            type="text"
            formControlName="name"
            class="form-control"
            placeholder="{{ 'Name' | translate }}"
            [ngStyle]="{'font-size':article?.title_size, 
                        'color':article?.title_color,
                        'font-weight':article?.data_title_weight
                          }"
          />
          <span
            *ngIf="
              contactForm.get('name').touched && contactForm.get('name').invalid
            "
          >
            <small
              *ngIf="contactForm.get('name').errors.required"
              class="form-text required"
            >
              {{ "VALIDATORS.REQUIRED" | translate }}
            </small>
          </span>
        </div>

        <div class="form-group">
          <input
            type="email"
            formControlName="email"
            class="form-control"
            placeholder="{{ 'Email' | translate }}"
            [ngStyle]="{'font-size':article?.title_size, 
                        'color':article?.title_color,
                        'font-weight':article?.data_title_weight
                          }"
          />
          <!--Validation-->
          <span
            *ngIf="
              contactForm.get('email').touched && contactForm.get('email').invalid
            "
          >
            <small
              *ngIf="contactForm.get('email').errors.required"
              class="form-text required"
            >
              {{ "VALIDATORS.REQUIRED" | translate }}
            </small>
            <small
              *ngIf="contactForm.get('email').errors.pattern"
              class="form-text required"
            >
              {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
            </small>
          </span>
        </div>

        <div class="form-group">
          <input
            type="text"
            (keypress)="acceptOnlyNumbers($event)"
            formControlName="mobile"
            maxlength="11"
            class="form-control Numbers-font"
            placeholder="{{ 'Mobile' | translate }}"
            [ngStyle]="{'font-size':article?.title_size, 
                        'color':article?.title_color,
                        'font-weight':article?.data_title_weight
                          }"
          />

          <span
            *ngIf="
              contactForm.get('mobile').touched &&
              contactForm.get('mobile').invalid
            "
          >
            <small
              *ngIf="contactForm.get('mobile').errors.required"
              class="form-text required"
            >
              {{ "VALIDATORS.REQUIRED" | translate }}
            </small>
            <small
              *ngIf="contactForm.get('mobile').errors.pattern"
              class="form-text required"
            >
              {{ "phoneLength" | translate }}
            </small>
          </span>
        </div>

        <div class="form-group">
          <textarea
            class="form-control"
            formControlName="message"
            placeholder="{{ 'GENERAL_PAGES.CONTACT.MESSAGE' | translate }}"
            [ngStyle]="{'font-size':article?.title_size, 
                        'color':article?.title_color,
                        'font-weight':article?.data_title_weight
                          }"
          ></textarea>
        </div>

        <button
          type="button"
          class="btn-custom mb-4"
          type="submit"
          [ngStyle]="{
            'background-color': Button_Background_Color,
             color: Button_Color,
            'border-color': Button_Color
          }"
        >
          {{ "GENERAL_PAGES.CONTACT.SEND" | translate }}
        </button>
      </form>
    </div>

    <div class="col-md-6 col-sm-12 col-xs-12" *ngIf="!isDefaultBackground(ContactImg)">
      <img class="contact-us-img" [src]="ContactImg"
       />
    </div>

  </div>




