<div
  class="row parralax d-flex"
  [ngStyle]="calculateStyles()"
 
  [ngClass]="[mainImage == null ? 'no-image' : '',component?.full_width == 1 ? 'bg-style':''] "
  [appMyStyles]="component"
  [appSliderStyle]="component"
>
  <!-- Title and Content -->
  <div
    class="general title-btn"
    [ngClass]="[
      sliderData?.length > 1 && component?.slider_description == 1?'general2':'',
      mainImage != null ? 'col-lg-7 col-md-6 col-sm-8' : 'col-md-12 col-sm-12 col-xs-12',
      component?.image_position == 'left' && mainImage != null? 'order-2 align-items-center' : 'order-1',
      component?.content_position_vertical == 'bottom' ? 'justify-content-end' : component?.content_position_vertical == 'top' ?  'justify-content-start' : 'justify-content-center'
    ]"
  >
  
    <h2 class="px-2"
      [ngStyle]="{
        'font-size': component?.title_size,
        color: component?.title_color,
        'font-weight': component?.data_title_weight,
        'z-index': 1,
        'text-transform':'none'
      }"
      [ngClass]="[
        component?.component_title_position == 'center' ? 'text-center' : 'text-start',
        component?.content_position_vertical == 'bottom' ? 'w-100' : 'w-90'
      ]"
    >
      {{ sliderData[0]?.title }}
    </h2>

    <h6
      class="component-desc px-2"
      [ngStyle]="{
        color: component.description_color,
        'font-size': component.description_size,
        'font-weight': component?.data_desc_weight,
        'text-transform':'lowercase'
      }"
      [ngClass]="[
        component?.Style_content_position == '0' ? 'text-center' : 'text-start',
        component?.content_position_vertical == 'bottom' ? 'w-100' : 'w-75'
      ]"
    >
      {{ sliderData[0]?.description }}
    </h6>
    <div *ngIf="component?.component_buttons?.length > 0">
      <button
        class="volunteer-btn"
        (click)="navigateToShow(sliderData[0]?.id)"
        [ngStyle]="{
          'background-color': component?.component_buttons[0]?.button_background_color ? component.component_buttons[0].button_background_color : 'transparent',
          color: component?.component_buttons[0]?.button_color,
          'border-color': component?.component_buttons[0]?.border_color,
          'font-size': component?.component_buttons[0]?.button?.font_size,
          'font-weight': component?.component_buttons[0]?.button?.font_weight
        }"
        [ngClass]="[
          component?.button_position == 'center' ? 'text-center' : component?.button_position == 'left'? 'text-start':'text-end']"
      >
        <ng-container *ngIf="lang == 'ar_container'">
          {{ component?.component_buttons[0]?.button_text }}
        </ng-container>
        <ng-container *ngIf="lang == 'en_container'">
          {{ component?.component_buttons[0]?.button_text }}
        </ng-container>
      </button>
    </div>
    <div class="overlay" *ngIf="component?.content_position_vertical == 'bottom'"></div>
  </div>

  <!-- Image Section -->
  <ng-container *ngIf="mainImage != null">
    <div
      class="col-lg-5 col-md-6 col-sm-4 px-0 text-center "
      [ngClass]="[component?.image_position === 'left' ? 'order-1' : 'order-2']"
    >
      <img
        [src]="sliderData[0]?.image"
        [ngClass]="component.Style_image_shape == '1' ? 'slider-image-side' : 'slider-image-circle'"
        [ngStyle]="{
          'border-radius': lang == 'ar_container' && component.Style_image_shape == '1'
            ? '0px 16px 16px 0px'
            : ''
        }"
      />
    </div>
  </ng-container>

  <!-- Slider Items (Set order to bottom) -->
  <ng-container *ngIf="sliderData?.length > 1 && component?.slider_description == 1">
    <div
      class="col-lg-3 col-md-4 col-sm-6  py-lg-2 p-md-0 pb-2 slider-description"
      *ngFor="let item of sliderData | slice: 1:4"
      style="order: 3;"
    >
      <img class="icon-details" [src]="item.image" />
      <span
        class="px-2"
        [ngStyle]="{
          color: component.sub_color,
          'font-size': component.Sub_size,
          'font-weight': component?.data_desc_weight,
          'text-transform':'none'
        }"
      >
        {{ item.title }}
      </span>
    </div>
  </ng-container>
</div>
