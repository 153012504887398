<div [appMyStyles]="component" >
 
  <app-header-title-desc [article]="component"></app-header-title-desc>

 
<div *ngIf="aboutUsOverviewData?.length > 0">

  <div class="overview__header mar"   [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
    >
   

    <h1
      (click)="chooseType('mission')"
     
      class="overview__header--title"
      [ngStyle]="{
        color: type == 'mission' ? websiteSecColor : component?.title_color,
        'border-bottom': type == 'mission'  ? '3px solid' + websiteSecColor : 'none',
        'font-size': component.title_size

      }"
    >
      {{ aboutUsOverviewData[0]?.title }}
    </h1>

   

    <h1
      (click)="chooseType('vision')"
     
      class="overview__header--title"
      [ngStyle]="{
        color: type == 'vision' ? websiteSecColor : component?.title_color,
        'border-bottom': type == 'vision'  ? '3px solid' + websiteSecColor : 'none',
        'font-size': component.title_size
        

      }"
    >
      {{ aboutUsOverviewData[1]?.title }}
    </h1>

   
    <h1
      (click)="chooseType('values')"
      
      class="overview__header--title"
      [ngStyle]="{
        color: type == 'values' ? websiteSecColor : component?.title_color,
        'border-bottom': type == 'values'  ? '3px solid' + websiteSecColor : 'none',
        'font-size': component.title_size
      }"
    >
      {{ aboutUsOverviewData[2]?.title }}
    </h1>
  </div>

  <div
    class="overview__body"
    *ngIf="type == 'mission'"
    (click)="Navigatetoshow(aboutUsOverviewData[0]?.id)"
  >
   
    <h1
      class="overview__body--title mar"
      [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"

    >
      {{ aboutUsOverviewData[0]?.description }}
    </h1>
    <!-- <p
      class="overview__body--desc"
      [innerHTML]="aboutUsOverviewData[0]?.content"
    ></p> -->
    <img class="overview__body--desc" src="{{aboutUsOverviewData[0]?.image}}">
  </div>

  <div
    class="overview__body"
    *ngIf="type == 'vision'"
    (click)="Navigatetoshow(aboutUsOverviewData[1]?.id)"
  >
  
    <h1
      class="overview__body--title mar"
      [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"

    >
      {{ aboutUsOverviewData[1]?.description }}
    </h1>
    <!-- <p
      class="overview__body--desc"
      [innerHTML]="ImageTitleData[1].content"
    ></p> -->
    <img class="overview__body--desc" src="{{aboutUsOverviewData[1]?.image}}">

  </div>

  <div
    class="overview__body"
    *ngIf="type == 'values'"
    (click)="Navigatetoshow(aboutUsOverviewData[2]?.id)"
  >
   
    <h1
      class="overview__body--title"
      [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"

    >
      {{ aboutUsOverviewData[2]?.description }}
    </h1>
    
    <img class="overview__body--desc" src="{{aboutUsOverviewData[2]?.image}}">

  </div>
</div>
<div class="alert alert-warning text-center" role="alert"  *ngIf="aboutUsOverviewData?.length == 0">
  <p>this design need some data to be visible  </p>
</div>
</div>

