import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from "ngx-swiper-wrapper";
import { MatSelectModule } from "@angular/material/select";
import { DropdownModule } from "primeng/dropdown";
import { AboutUsDescriptionComponent } from "./components/about-us-description/about-us-description.component";
import { AboutUsHeaderComponent } from "./components/about-us-header/about-us-header.component";
import { AboutUsOverviewComponent } from "./components/about-us-overview/about-us-overview.component";
import { AcademyMenuComponent } from "./components/academy-menu/academy-menu.component";
import { AcademyRegisterNoBgComponent } from "./components/academy-register-no-bg/academy-register-no-bg.component";
import { AdvertisingComponent } from "./components/advertising/advertising.component";
import { ArticleCardComponent } from "../articles/components/article-card/article-card.component";
import { AuthenticationModule } from "../authentication/authentication.module";
import { BigVideoComponent } from "./components/big-video/big-video.component";
import { ButtonCards4Component } from "./components/button-cards4/button-cards4.component";
import { Card3WhiteBgComponent } from "./components/card3-white-bg/card3-white-bg.component";
import { CardEventComponent } from "../events/components/card-event/card-event.component";
import { CardLocationComponent } from "./components/card-location/card-location.component";
import { CardOpportunityComponent } from "./card-opportunity/card-opportunity.component";
import { Cards4ObjectivesCardComponent } from "./components/cards4-objectives-card/cards4-objectives-card.component";
import { Cards6SliderComponent } from "./components/cards6-slider/cards6-slider.component";
import { CardsListComponent } from "./components/cards-list/cards-list.component";
import { CarouselModule } from "primeng/carousel";
import { CmsComponent1Component } from "./components/cms-component1/cms-component1.component";
import { CmsComponent2Component } from "./components/cms-component2/cms-component2.component";
import { CmsComponent3Component } from "./components/cms-component3/cms-component3.component";
import { CmsComponent4Component } from "./components/cms-component4/cms-component4.component";
import { CmsComponent5Component } from "./components/cms-component5/cms-component5.component";

import { CmsGeneralCardComponent } from "./components/cms-general-card/cms-general-card.component";
import { CommonModule } from "@angular/common";
import { DateFormatterPipe } from "./pipes/date-formatter.pipe";
import { DocumentaryComponent } from "./components/documentary/documentary.component";
import { EtaamFooterComponent } from "./components/etaam-footer/etaam-footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { EventBigCardComponent } from "./components/event-big-card/event-big-card.component";
import { FaqHeaderComponent } from "./components/faq-header/faq-header.component";
import { FaqQuestionsComponent } from "./components/faq-questions/faq-questions.component";
import { Footer1Component } from "./components/footer1/footer1.component";
import { Footer2Component } from "./components/footer2/footer2.component";
import { Footer3Component } from "./components/footer3/footer3.component";
import { Footer4Component } from "./components/footer4/footer4.component";
import { FourLogosImageComponent } from "./components/four-logos-image/four-logos-image.component";
import { Gallery6Component } from "./components/gallery6/gallery6.component";
import { GalleryComponent } from "./components/gallery/gallery.component";
import { GetintouchComponent } from "./components/getintouch/getintouch.component";
import { GridCards5Component } from "./components/grid-cards5/grid-cards5.component";
import { Header1Component } from "./components/header1/header1.component";
import { Header2Component } from "./components/header2/header2.component";
import { Header3Component } from "./components/header3/header3.component";
import { Header4Component } from "./components/header4/header4.component";
import { Header5Component } from "./components/header5/header5.component";
import { IconBgTitleDescCard3Component } from "./components/icon-bg-title-desc-card3/icon-bg-title-desc-card3.component";
import { ImagePararaphComponent } from "./components/image-pararaph/image-pararaph.component";
import { ImageTitleDescHorizontzalCardsComponent } from "./components/image-title-desc-horizontzal-cards/image-title-desc-horizontzal-cards.component";
import { ImageTitleDescriptionComponent } from "./components/image-title-description/image-title-description.component";
import { ImgTitleDescriptionCardComponent } from "./components/img-title-description-card/img-title-description-card.component";
import { LinksFooterComponent } from "./components/links-footer/links-footer.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { LoaderService } from "../shared/shared_services/loader.service";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatStepperModule } from "@angular/material/stepper";
import { MenuTransparentComponent } from "./components/menu-transparent/menu-transparent.component";
import { MyStylesDirective } from "./Directives/my-styles-directive.directive";
import { NewsHeaderComponent } from "./components/news-header/news-header.component";
import { NewsListComponent } from "./components/news-list/news-list.component";
import { NewsTagsComponent } from "./components/news-tags/news-tags.component";
import { NgModule } from "@angular/core";
import { NgxPaginationModule } from "ngx-pagination";
import { NumberFormatterPipe } from "./pipes/numberFormatter.pipe";
import { RouterModule } from "@angular/router";
import { SliderImageSignContentComponent } from "./components/slider-image-sign-content/slider-image-sign-content.component";
import { SocialMediaSharePopupComponent } from "./components/soical-media-share-popup/social-media-share-popup.component";
import { SpecialSpeakerHeaderComponent } from "./components/special-speaker-header/special-speaker-header.component";
import { SpecialSpeakersDetailsComponent } from "./components/special-speakers-details copy/special-speakers-details.component";
import { TextCardComponent } from "./components/text-card/text-card.component";
import { TitleBgCardComponent } from "./components/title-bg-card/title-bg-card.component";
import { TranslateModule } from "@ngx-translate/core";
import { TwoImageBigCardsComponent } from "./components/two-image-big-cards/two-image-big-cards.component";
import { TwoLogoComponent } from "./components/two-logo/two-logo.component";
import { WebSliderComponent } from "./components/web-slider/web-slider.component";
import { HeaderSideComponent } from "./components/header-side/header-side.component";
import { AgmCoreModule } from "@agm/core";
import { TitleSideDescriptionCardComponent } from "./components/title-side-description-card/title-side-description-card.component";
import { CmsComponent4GeneralComponent } from "./components/cms-component4-general/cms-component4-general.component";
import { CmsComponent5GeneralComponent } from "./components/cms-component5-general/cms-component5-general.component";
import { CmsComponent6GeneralComponent } from "./components/cms-component6-general/cms-component6-general.component";
import { SliderModule } from "@syncfusion/ej2-angular-inputs";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { BackgroundpopupComponent } from "./components/backgroundpopup/backgroundpopup.component";
import { DonationPopupComponent } from "./components/donation-popup/donation-popup.component";
import { ImgTitleDecGridComponent } from "./components/img-title-dec-grid/img-title-dec-grid.component";
import { ImagesGridComponent } from "./components/images-grid/images-grid.component";

import { DialogModule } from "primeng/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { CommentsWithCardsComponent } from './components/comments-with-cards/comments-with-cards.component';
import { ImageWithBenfitsComponent } from './components/image-with-benfits/image-with-benfits.component';
import { Contactus2Component } from './components/contactus2/contactus2.component';
import { Footer5Component } from './components/footer5/footer5.component';
import { Contactus3Component } from './components/contactus3/contactus3.component';
import { Contactus4Component } from './components/contactus4/contactus4.component';
import { LogoComponent } from './components/logo/logo.component';
import { ContactUsWithImageComponent } from './components/contact-us-with-image/contact-us-with-image.component';
import { TwoImageWithDescriptionComponent } from './components/two-image-with-description/two-image-with-description.component';
import { FlagsComponent } from './components/flags/flags.component';
import { BlogsComponent } from './components/blogs/blogs.component';
import { ContactUs7Component } from './components/contact-us7/contact-us7.component';
import { HeaderBackgroundComponent } from './components/header-background/header-background.component';
import { Contactus5Component } from './components/contactus5/contactus5.component';
import { DateCards4Component } from './components/date-cards4/date-cards4.component';
import { AboutUs3Component } from './components/about-us3/about-us3.component';
import { FAQComponent } from './components/faq/faq.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { HeaderButtonComponent } from './components/header-button/header-button.component';
import { Contactus6Component } from './components/contactus6/contactus6.component';
import { Footer6Component } from './components/footer6/footer6.component';
import { SliderDescSearchComponent } from './components/slider-desc-search/slider-desc-search.component';
import { CardsFilterComponent } from './components/cards-filter/cards-filter.component';
import { ImagesListComponent } from './components/images-list/images-list.component';
import { BrandsCarsComponent } from './components/brands-cars/brands-cars.component';
import { ImageTitleComponent } from './components/image-title/image-title.component';
import { FooterCopyrightComponent } from './components/footer-copyright/footer-copyright.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { TitleDescriptionSideImageComponent } from './components/title-description-side-image/title-description-side-image.component';
import { Contactus7Component } from './components/contactus7/contactus7.component';
import { GetTouchComponent } from './components/get-touch/get-touch.component';
import { TitleDescImage3Component } from './components/title-desc-image3/title-desc-image3.component';
import { HeaderWithTwoButtonComponent } from './components/header-with-two-button/header-with-two-button.component';
import { CardProfileComponent } from "../card-profile/card-profile.component";
import { HeaderTitleDescComponent } from './components/header-title-desc/header-title-desc.component';
import { HowtoImageDescComponent } from "./components/how-to-image-desc/how-to-image-desc.component";

import { SeparatorComponent } from './components/separator/separator.component';
import { EditComponentPopupComponent } from './edit-component-popup/edit-component-popup.component';

import { DynamicDialogModule } from 'primeng/dynamicdialog';
import {ButtonModule} from 'primeng/button';
import { GridStyleDirective } from './Directives/grid-style.directive';
import { CardBgLabelComponent } from './components/card-bg-label/card-bg-label.component';
import { SliderStyleDirective } from './Directives/slider-style.directive';
import { ChunkPipe } from "./chunk.pipe";
import { StepperComponent } from './components/stepper/stepper.component';
import { Projects3WithSharpImageComponent } from "./components/projects3-with-sharp-image/projects3-with-sharp-image.component";
import { ReviewsComponent } from "./components/reviews/reviews.component";
import { FactsComponent } from "./components/facts/facts.component";
import { SliderImagesTitleComponent } from './components/slider-images-title/slider-images-title.component';
import { BabysitterProfileComponent } from "./components/babysitter-profile/babysitter-profile.component";
import { VideosSliderComponent } from "./components/videos-slider/videos-slider.component";
import { AlertComponent } from "./components/alert/alert.component";
import { CardsSliderComponent } from "./components/cards-slider/cards-slider.component";
import { WebSliderNewComponent } from "./components/web-slider-new/web-slider-new.component";
import { Contactus8Component } from "./components/contactus8/contactus8.component";
import { AboutUs7Component } from "./components/about-us7/about-us7.component";
import { NoHeaderComponent } from "./components/no-header/no-header.component";
import { AboutUs8Component } from "./components/about-us8/about-us8.component";
import { AutomaticSliderComponent } from "./components/automatic-slider/automatic-slider.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { ButtonCardsSideComponent } from "./components/button-cards-side/button-cards-side.component";
import { ImageWithTwoListComponent } from "./components/image-with-two-list/image-with-two-list.component";
import { SafeUrlPipe } from "./pipes/safe-url.pipe";
import { Images6Component } from './components/images6/images6.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
};
@NgModule({
  declarations: [
    SocialMediaSharePopupComponent,
    EtaamFooterComponent,
    ImgTitleDecGridComponent,
    ImagesGridComponent,
    DonationPopupComponent,
    BackgroundpopupComponent,
    HeaderComponent,
    CardOpportunityComponent,
    DateFormatterPipe,
    CardEventComponent,
    ArticleCardComponent,
    LoaderComponent,
    NumberFormatterPipe,
    CardsSliderComponent,
    ButtonCards4Component,
    ButtonCardsSideComponent,
    ImgTitleDescriptionCardComponent,
    Header1Component,
    Header2Component,
    Header3Component,
    Footer1Component,
    Footer2Component,
    Footer3Component,
    Card3WhiteBgComponent,
    
    CmsGeneralCardComponent,
    CmsComponent1Component,
    CmsComponent2Component,
    CmsComponent3Component,
    CmsComponent4Component,
    CmsComponent5Component,

    TwoImageBigCardsComponent,
    
    IconBgTitleDescCard3Component,
    
    ImageTitleDescriptionComponent,
    
    BigVideoComponent,
    
    AdvertisingComponent,
    
    EventBigCardComponent,
    TwoLogoComponent,
    
    DocumentaryComponent,
    GalleryComponent,
    
    Cards6SliderComponent,
    TitleBgCardComponent,
    TextCardComponent,
    Cards4ObjectivesCardComponent,
    ImageTitleDescHorizontzalCardsComponent,
    NewsHeaderComponent,
    NewsListComponent,
    NewsTagsComponent,
    FaqHeaderComponent,
    FaqQuestionsComponent,
    
    FourLogosImageComponent,
    ImagePararaphComponent,
    SpecialSpeakerHeaderComponent,
    SpecialSpeakersDetailsComponent,
    AboutUsHeaderComponent,
    AboutUsDescriptionComponent,
    AboutUsOverviewComponent,
    
    CardLocationComponent,
    Header4Component,
    Header5Component,
    NoHeaderComponent,
    WebSliderComponent,
    WebSliderNewComponent,
    Gallery6Component,
    GetintouchComponent,
    Footer4Component,
    
    CardsListComponent,
    CardBgLabelComponent,
    
    AcademyMenuComponent,
    AcademyRegisterNoBgComponent,
    MenuTransparentComponent,
    SliderImageSignContentComponent,
    
    LinksFooterComponent,
    GridCards5Component,

    MyStylesDirective,
    
    HeaderSideComponent,

    AlertComponent,
    PaginationComponent,
 
    TitleSideDescriptionCardComponent,
    
    CmsComponent4GeneralComponent,
    CmsComponent5GeneralComponent,
    CmsComponent6GeneralComponent,

    CommentsWithCardsComponent,
    ImageWithBenfitsComponent,
    Contactus2Component,
    Contactus8Component,
    Footer5Component,
    Contactus3Component,
    Contactus4Component,
    LogoComponent,
    ContactUsWithImageComponent,
    TwoImageWithDescriptionComponent,
    FlagsComponent,
    AutomaticSliderComponent,
    BlogsComponent,
    VideosSliderComponent,
    ContactUs7Component,
    HeaderBackgroundComponent,
    Contactus5Component,
    DateCards4Component,
    AboutUs3Component,
    AboutUs7Component,
    AboutUs8Component,
    FAQComponent,
    ProductDetailsComponent,
    Projects3WithSharpImageComponent,
    ReviewsComponent,
    FactsComponent,
    BabysitterProfileComponent,
    HeaderButtonComponent,
    Contactus6Component,
    Footer6Component,
    SliderDescSearchComponent,
    CardsFilterComponent,
    ImagesListComponent,
    BrandsCarsComponent,
    ImageTitleComponent,
    FooterCopyrightComponent,
    BreadcrumbComponent,
    TitleDescriptionSideImageComponent,
    Contactus7Component,
    HowtoImageDescComponent,
    GetTouchComponent,
    TitleDescImage3Component,
    HeaderWithTwoButtonComponent,
    CardProfileComponent,
    HeaderTitleDescComponent,
    SeparatorComponent,
    EditComponentPopupComponent,
    GridStyleDirective,
    CardBgLabelComponent,
    SliderStyleDirective,
    ChunkPipe,
    StepperComponent,
    SliderImagesTitleComponent,
    ImageWithTwoListComponent,
    SafeUrlPipe,
    Images6Component

  ],
  exports: [
    SafeUrlPipe,
    MyStylesDirective,
    CarouselModule,
    TranslateModule,
    MatStepperModule,
    FormsModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AgmCoreModule,
    CardsSliderComponent,
    SocialMediaSharePopupComponent,
    EtaamFooterComponent,
    HeaderComponent,
    CardOpportunityComponent,
    CardEventComponent,
    ArticleCardComponent,
    NgxPaginationModule,
    DateFormatterPipe,
    NumberFormatterPipe,
    LoaderComponent,
    
    ButtonCards4Component,
    ButtonCardsSideComponent,
    ImgTitleDescriptionCardComponent,

    Card3WhiteBgComponent,
    TwoImageBigCardsComponent,
    
    IconBgTitleDescCard3Component,
    SpecialSpeakerHeaderComponent,
    Header1Component,
    Header2Component,
    Header3Component,
    Footer1Component,
    Footer2Component,
    Footer3Component,
    CmsGeneralCardComponent,
    CmsComponent1Component,
    CmsComponent2Component,
    CmsComponent3Component,
    CmsComponent4Component,
    CmsComponent5Component,

    ImageTitleDescriptionComponent,
    
    BigVideoComponent,
    
    AdvertisingComponent,
    
    EventBigCardComponent,
    TwoLogoComponent,
    
    DocumentaryComponent,
    GalleryComponent,
    
    Cards6SliderComponent,
    TitleBgCardComponent,
    TextCardComponent,
    Cards4ObjectivesCardComponent,
    ImageTitleDescHorizontzalCardsComponent,
    NewsListComponent,
    NewsTagsComponent,
    NewsHeaderComponent,
    FaqHeaderComponent,
    FaqQuestionsComponent,
    
    FourLogosImageComponent,
    ImagePararaphComponent,
    SpecialSpeakersDetailsComponent,
    AboutUsHeaderComponent,
    AboutUsDescriptionComponent,
    AboutUsOverviewComponent,
    
    CardLocationComponent,
    Header4Component,
    Header5Component,
    NoHeaderComponent,
    WebSliderComponent,
    WebSliderNewComponent,
    Gallery6Component,
    GetintouchComponent,
    Footer4Component,
    
    CardsListComponent,
  
    AcademyMenuComponent,
    AcademyRegisterNoBgComponent,
    MenuTransparentComponent,
    
    SliderImageSignContentComponent,
    
    LinksFooterComponent,
    GridCards5Component,
    StepperComponent,
    
    HeaderSideComponent,
    
    AlertComponent,
    PaginationComponent,
    
    TitleSideDescriptionCardComponent,
    
    CmsComponent4GeneralComponent,
    CmsComponent5GeneralComponent,
    CmsComponent6GeneralComponent,
    ImgTitleDecGridComponent,
    ImagesGridComponent,
    
    CommentsWithCardsComponent,
    CommentsWithCardsComponent,
    ImageWithBenfitsComponent,
    Contactus2Component,
    Contactus8Component,
    Footer5Component,
    Contactus3Component,
    Contactus4Component,
    LogoComponent,
    ContactUsWithImageComponent,
    TwoImageWithDescriptionComponent,
    FlagsComponent,
    AutomaticSliderComponent,
    BlogsComponent,
    VideosSliderComponent,
    ContactUs7Component,
    HeaderBackgroundComponent,
    Contactus5Component,
    DateCards4Component,
    AboutUs3Component,
    AboutUs7Component,
    AboutUs8Component,
    FAQComponent,
    ProductDetailsComponent,
    Projects3WithSharpImageComponent,
    ReviewsComponent,
    FactsComponent,
    BabysitterProfileComponent,
    HeaderButtonComponent,
    Contactus6Component,
    Footer6Component,
    SliderDescSearchComponent,
    CardsFilterComponent,
    ImagesListComponent,
    BrandsCarsComponent,
    ImageTitleComponent,
    FooterCopyrightComponent,

    BreadcrumbComponent,
    TitleDescriptionSideImageComponent,
    Contactus7Component,
    HowtoImageDescComponent,
    GetTouchComponent,
    TitleDescImage3Component,
    HeaderWithTwoButtonComponent,
    CardProfileComponent,
    HeaderTitleDescComponent,
    SeparatorComponent,
    EditComponentPopupComponent,
    CardBgLabelComponent,
    ChunkPipe ,
    SliderImagesTitleComponent,
    ImageWithTwoListComponent
  ],
  imports: [
    CommonModule,

    SwiperModule,
    DialogModule,
    DynamicDialogModule,
    ButtonModule,
    CarouselModule,
    AuthenticationModule,
    MatStepperModule,
    MatDatepickerModule,
    SliderModule,
    RouterModule,
    MatSelectModule,
    DropdownModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatOptionModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyD3HziFPDuj312mPv_J7jkTqsidehDPX6M",
      // libraries: ['places'],
    }),
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    LoaderService,
  ],
})
export class SharedModule {}
