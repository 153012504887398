<div
  [appMyStyles]="article"
  [ngClass]="{ 'background-color': article?.component_background_color }"
  class="py-2 overflow-hidden"
>
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="brand-slider py-5">
    <div class="brand-track">
      <div *ngFor="let item of ImageTitleData" class="brand-item">
        <img class="brand-img" [src]="item.image" />
      </div>
      <!-- Duplicate to create infinite effect -->
      <div *ngFor="let item of ImageTitleData" class="brand-item">
        <img class="brand-img" [src]="item.image" />
      </div>
        <!-- Duplicate to create infinite effect -->
        <div *ngFor="let item of ImageTitleData" class="brand-item">
          <img class="brand-img" [src]="item.image" />
        </div>
    </div>
  </div>
</div>
