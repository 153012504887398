import { Component, Input, OnInit } from "@angular/core";

import { DomSanitizer } from "@angular/platform-browser";
import { HomeService } from "../../../general-pages/pages/home/home.service";

import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NavigationService } from "../../shared_services/navigation.service";
import { DivideRowStyleService } from "../../shared_services/divide-row-style.service";

@Component({
  selector: "app-reviews",
  templateUrl: "./reviews.component.html",
  styleUrls: ["./reviews.component.scss"],
})
export class ReviewsComponent implements OnInit {
  @Input() component: any;

  APIParameters;
  FourbuttoncardsData;
  websiteMainColor: any;
 
  lang: string;
  currentLang: string;

  video_array: any[] = [];

  Button_Color: any;
  Button_Background_Color: any;
  main_id: any;
  websiteSecColor: any;

  currentIndex: number = 0;
  itemsToShow: number = 4; 

  visibleItems = [];
  col: string;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private navigationService:NavigationService,
    private colStyleService:DivideRowStyleService

  ) {}

  ngOnInit(): void {

    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang");

    this.main_id = this.component.main_id;

    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.FourbuttoncardsData = res.content;
        this.col = this.colStyleService.getColClass(res.content, this.component);
        this.updateVisibleItems();
      });
    
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
         
        });
    });
  }


  next(): void {
    const maxIndex = this.FourbuttoncardsData.length - this.itemsToShow;
    if (this.currentIndex < maxIndex) {
      this.currentIndex++;
      this.updateVisibleItems();
    }
  }


  updateVisibleItems(): void {
    this.visibleItems = this.FourbuttoncardsData.slice(
      this.currentIndex,
      this.currentIndex + this.itemsToShow
    );
  }
  
}
