<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div
    class="row"
    
  >
    <div class="col-md-7 col-sm-12 col-xs-12 my-2 mx-4 row">
      <div class="form-group row col-4">
        <label class="col-form-label col-5">price</label>
        <select class="form-control form-control-sm mx-1 col">
          <option selected>EG</option>
        </select>
      </div>
      <div class="form-group row col-4">
        <label class="col-form-label col-5">price</label>
        <select class="form-control form-control-sm mx-1 col">
          <option selected>EG</option>
        </select>
      </div>
      <div class="form-group row col-4">
        <label class="col-form-label col-5">price</label>
        <select class="form-control form-control-sm mx-1 col">
          <option selected>EG</option>
        </select>
      </div>
    </div>
  </div>


  <div
    class="row"
    
  >

    <div class="col-md-7 col-sm-12 col-xs-12 ">

      <div class="row">
        <div
        *ngFor="let item of ImageTitleData"
        class="col-md-4 col-sm-6 col-xs-12 "
        (click)="navigateToShow(item.id)"
      >
        <div class="s_card ">
          <img src="{{ item.image }}" />
          <div
            class="d-flex flex-column align-items-center justify-content-between"
          >
            <h6
              class="mx-3 my-2"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="item.title_detailed"
            ></h6>
            <button
              class="btn px-3 py-2 ditals my-3"
              [ngStyle]="{
                'background-color': Button_Background_Color,
                color: Button_Color
              }"
            >
              {{'Details'|translate}}
            </button>
          </div>

        </div>
        
        </div>
      </div>
      
    </div>

    <div class="col-md-4 col-sm-12 col-xs-12 ">
      <div class="align-items-center border ">
        <h5 class="m-3">Show all categories</h5>
      </div>
      <div class="align-items-center border ">
        <h5 class="m-3">Show all categories</h5>
      </div>
      <div class="align-items-center border ">
        <h5 class="m-3">Show all categories</h5>
      </div>
      <div class="align-items-center border ">
        <h5 class="m-3">Show all categories</h5>
      </div>
      <div class="align-items-center border ">
        <h5 class="m-3">Show all categories</h5>
      </div>
      <div class="align-items-center border ">
        <h5 class="m-3">Show all categories</h5>
      </div>
      <div class="align-items-center border ">
        <h5 class="m-3">Show all categories</h5>
      </div>
      <div class="align-items-center border ">
        <h5 class="m-3">Show all categories</h5>
      </div>
      <div class="align-items-center border ">
        <h5 class="m-3">Show all categories</h5>
      </div>
      <div class="align-items-center border ">
        <h5 class="m-3">Show all categories</h5>
      </div>
    </div>

  </div>


</div>
