import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseHttpService } from "src/app/services/base-http.service";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { formatDate } from "@angular/common";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class FormService {


  header = new HttpHeaders({
    "access-token":
      "Y09LRWkzbXRDVmRVMUM1RzlWTmk5dmR3ZFlIUElhcXpIYjlKR1J2SHhaNlo1a3Fsd25hWjY1WExwRzdG6571ce08f071d",
  });
  constructor(
    private baseHttpService: BaseHttpService,
    private localizeRouterService: LocalizeRouterService,
    private httpClient: HttpClient,
  ) {}



  storeFormData(data:any){
    var formData: any = new FormData();
    formData.append('form_name',data.formName);
    formData.append('form_data',JSON.stringify(data.fields) );
    formData.append('lang_id',1);
    formData.append('application_id ',188);

    return this.baseHttpService.post("/api/forms/store", formData  );

  }

  getForm(id) {
    let body = {
      lang_id: 1,
      application_id: localStorage.getItem("Application_ID"),
      id: id,
    };
    return this.baseHttpService.post(`/api/forms/show`, body);
  }

  GetAllForms() {
    let body = {
      lang_id: 1,
      application_id: localStorage.getItem("Application_ID"),
    };

    return this.baseHttpService.post(`/api/forms`, body);
  }

  SubmitFormData(body, id) {
 

    var formData: any = new FormData();
    formData.append("form_data", JSON.stringify(body));
    formData.append("lang_id", 1);
    formData.append("application_id", localStorage.getItem("Application_ID"));
    formData.append("form_id", id);
    formData.append("user_id", localStorage.getItem("User_ID"));

    return this.baseHttpService.post("/api/form_data/store", formData);
  }

  ReservationForm1(body){
    body = {
      ...body,
      lang_id:
        this.localizeRouterService.parser.currentLang == "en" ? "1" : "2",
    };
    return this.baseHttpService.post( "/api/scheduling_appointments/store", body, { headers: this.header,});

  }

  // ======================= Start form generator functions =======================

  newCreate(){

    let body = {
      lang_id: 1,
      application_id: localStorage.getItem("Application_ID"),
      module_id: 3679 ,
      template_id: 11028
    };

    return this.baseHttpService.post(`/api/cms/create`, body);
  }
  
}
