<div 
[ngClass]="component?.Style_content_position == 1 ? 'background-top':'background-center'"
[ngStyle]="{
  'background-image': component?.component_background_image !== 'https://limberx.com/test/limberx_generator_api/public/img/Default_background.png' 
    ? 'url(' + component?.component_background_image + ')' 
    : 'none'
}">
<div [appMyStyles]="component" class="pt-3">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>

<div class="top-container w-100 h-100" [appMyStyles]="component"
 >


  <div *ngIf="imageTitleDescriptionData?.length > 0 " class="row mt-2 mb-5 w-100"
    [ngStyle]="{'border':component?.border == 0 ?'1px solid':'none','border-color':component?.Style_border != null ?component?.Style_border : websiteMainColor }"
    
   >
  <div *ngIf="component?.image_position == 'left' && imageTitleDescriptionData.length == 2"
    class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center">
    <img class="program-img2" src="{{ imageTitleDescriptionData[0]?.image }}" />
    <img class="program-img" src="{{ imageTitleDescriptionData[1]?.image }}" />

  </div>
  <div *ngIf="component?.image_position == 'left' && imageTitleDescriptionData.length == 1 && imageTitleDescriptionData[0]?.image"
    class=" d-flex justify-content-center align-items-center"
    [ngClass]="component?.Style_image_is_icon == 1 ?'col-md-3 col-sm-12 col-xs-12':'col-md-6 col-sm-12 col-xs-12'">
    <img class="program-img3" src="{{ imageTitleDescriptionData[0]?.image }}" />
  </div>

    <div class="padd"
      [ngClass]="[component?.Style_content_position == 0   ? 'text-center' : '',
        component?.Style_image_is_icon == 1 ?'col-md-9 col-sm-12 col-xs-12':'col-md-6 col-sm-12 col-xs-12'
      ]">



      <p [ngStyle]="{'font-size':component?.title_size,
       'text-transform':'none','color':component?.title_font_color,'font-weight':component?.component_title_weight,
       'padding-inline-start':component?.Style_border == 1 ? '10px':''
}">{{ imageTitleDescriptionData[0]?.title }}</p>
        <div class="with-border" *ngIf="component?.Style_border == 1" >
          <div class="accent-line"></div>

          <p class="desc"
          [ngStyle]="{'font-size':component?.description_size,'text-transform':'none','color': component?.description_color, 'font-weight':component?.component_desc_weight}">
          {{ imageTitleDescriptionData[0]?.description }}
        </p>   
  
        </div>
        <p class="desc" *ngIf="component?.Style_border == 0"
        [ngStyle]="{'font-size':component?.description_size,'line-height': 'normal','text-transform':'none','color': component?.description_color, 'font-weight':component?.component_desc_weight}">
        {{ imageTitleDescriptionData[0]?.description }}
      </p> 
      <button *ngIf="component?.component_buttons?.length>0" [ngStyle]="{
          'background-color': component?.component_buttons[0]?.button_background_color,
                      color: component?.component_buttons[0]?.button_color,
                      'border-color':component?.component_buttons[0]?.border_color,
                      'font-size':component?.component_buttons[0]?.button?.font_size,
                      'font-weight':component?.component_buttons[0]?.button?.font_weight
  
        }" class="read-more">
        <ng-container *ngIf="lang == 'ar_container'">{{component?.component_buttons[0]?.button_text}} </ng-container>
        <ng-container *ngIf="lang == 'en_container'">{{component?.component_buttons[0]?.button_text}} </ng-container>

      </button>
    </div>

    <div *ngIf="component?.image_position == 'right' && imageTitleDescriptionData.length == 2"
      class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center">
      <img class="program-img2" src="{{ imageTitleDescriptionData[0]?.image }}" />
      <img class="program-img" src="{{ imageTitleDescriptionData[1]?.image }}" />

    </div>
    <div *ngIf="component?.image_position == 'right' && imageTitleDescriptionData.length == 1"
      class="d-flex justify-content-center align-items-center"
      [ngClass]="component?.Style_image_is_icon == 1 ?'col-md-3 col-sm-12 col-xs-12':'col-md-6 col-sm-12 col-xs-12'">
      <img class="" src="{{ imageTitleDescriptionData[0]?.image }}" />
    </div>

  </div>
</div>
</div>
<app-alert *ngIf="imageTitleDescriptionData?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
  [message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>