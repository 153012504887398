<div [appMyStyles]="component" class="interactive-reels-container">
  <div class="title-container" *ngIf="component.component_title">
    <h4 class="component-title" [ngStyle]="{
        color: component.title_font_color,
        'font-size': component.title_font_size
      }">
      {{ component.component_title }}
    </h4>
    <div class="title-line" *ngIf="component?.slider_icon == 1"></div> 
  </div>

  <div class="carousel-container position-relative mt-3">
    <button 
      class="carousel-button prev" 
      (click)="prev()" 
      *ngIf="component?.slider_icon == 1 && imageTitleData?.length > itemsToShow"
      aria-label="Previous videos">
      <i class="fas fa-chevron-left"></i>
    </button>

    <div class="row justify-content-center reels-row">
      <div [ngClass]="col" class="media" *ngFor="let item of isSlider(); let i = index">
        <div class="video-container" [class.playing]="isPlaying(i)">
          <iframe 
            #videoFrame
            class="video-content" 
            [src]="isPlaying(i) ? (transformYouTubeUrl(item?.link) | safeUrl) : null" 
            frameborder="0" 
            allow="autoplay; encrypted-media"
            allowfullscreen
            [title]="'Video: ' + (item?.title || 'Untitled')"
            *ngIf="isPlaying(i)"
          ></iframe>
          
          <div class="overlay" (click)="activateIframe(i)" *ngIf="!isPlaying(i)">
            <img [src]="item?.image" class="video-content" *ngIf="item?.image" alt="Video thumbnail" />
            <button class="play-button" (click)="$event.stopPropagation(); activateIframe(i)">
              <i class="fas fa-play"></i>
            </button>
          </div>
          
          <div class="overlay playing" (click)="deactivateIframe(i)" *ngIf="isPlaying(i)">
            <button class="pause-button" (click)="$event.stopPropagation(); deactivateIframe(i)">
              <i class="fas fa-pause"></i>
            </button>
          </div>
          
          <div class="fullscreen-button" (click)="openFullscreenIframe(i)" *ngIf="isPlaying(i)">
            <i class="fas fa-expand"></i>
          </div>
        
          <div class="info-section">
            <div class="profile-info">
              <img [src]="item?.image" class="profile-image" *ngIf="item?.image" alt="Profile" />
              <div class="text-container">
                <p class="title"
                  [ngStyle]="{
                    'font-size': component?.title_size,
                    color: component?.title_color || '#fff',
                    'font-weight': component?.data_title_weight,
                    'text-transform': 'none'
                  }">
                  {{ item?.title }}
                </p>
                <p class="subtitle"
                  [ngStyle]="{
                    'font-size': component?.description_size,
                    color: component?.description_color || '#eee',
                    'font-weight': component?.data_description_weight
                  }">{{ item?.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button 
      class="carousel-button next" 
      (click)="next()" 
      *ngIf="component?.slider_icon == 1 && imageTitleData?.length > itemsToShow"
      aria-label="Next videos">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>

<div class="alert alert-warning text-center" role="alert" *ngIf="!imageTitleData || imageTitleData?.length == 0">
  <p>
    <b>#{{ component?.id }}</b> {{ "InfoAlarm" | translate }}
  </p>
</div>
