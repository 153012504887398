<div [appMyStyles]="component">
    <app-header-title-desc [article]="component"></app-header-title-desc>
  </div>
<div class="row" [appMyStyles]="component">
  <div class="stepper-container">
    <!-- Horizontal Line with Circles -->
    <div class="stepper-line">
      <div
        class="step-circle"
        *ngFor="let step of steps; let i = index"
        [class.active]="i === currentStep"
      >
     
      </div>
    </div>
  
    <!-- Step Content Blocks -->
    <div class="step-content-container">
      <div class="step" *ngFor="let step of steps; let i = index" [ngStyle]="{'background-color':component?.Style_card_background?component?.Style_card_background:'#fff'}">

        <div class="step-header">

          <span class="step-number"  [ngStyle]="{
            'font-size': component?.description_size,
            'text-transform':'lowercase',
            color: component?.description_color,
            'font-weight': component?.data_desc_weight
          }">0{{ i + 1 }}.
          </span>

          <span class="step-title"
          *ngIf="i==0"
          [ngStyle]="{
            'font-size': component?.title_size,
            color: websiteSecColor,
            'font-weight': 'bold',
            'text-transform':'none'
          }">
          {{ step.title }}
        </span>
        <span *ngIf="i!=0" class="step-title"
          [ngStyle]="{
            'font-size': component?.title_size,
            color: component?.title_color,
            'font-weight': component?.data_title_weight,
            'text-transform':'none'
          }">
          {{ step.title }}
        </span>
        </div>


        <div class="step-content">
          <p [ngStyle]="{
            'font-size': component?.description_size,
            'text-transform':'lowercase',
            color: component?.description_color,
            'font-weight': component?.data_desc_weight
          }">{{ step.description }}</p>
        </div>
      </div>
    </div>
  </div>  
</div>

  