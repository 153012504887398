import { Component, Input, OnInit } from "@angular/core";

import { Article } from "src/app/articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-gallery6",
  templateUrl: "./gallery6.component.html",
  styleUrls: ["./gallery6.component.scss"],
})
export class Gallery6Component implements OnInit {
  @Input() article: any;

  galleryData;
 
  lang: string;
  currentLang: string;
  websiteSecColor: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    public sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));


    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.galleryData = res.content;

      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.galleryData = res.content;
    
        });
    });
  }

  navigateToShow(id) {
    // if (this.article.can_navigate == "1") {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cms/1/${id}`],
      { queryParams:
        {module: this.article.parameter.module_id}
      });
    });
    // }
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
