<div class="row" [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>

<div
  class="row mb-5"
  [class]="component?.animations?.length > 1 ? component?.animations[1] : ''"
  [appMyStyles]="component"
  
>
  <div 
    class="col-md-12 col-sm-12 col-xs-12 mar-b"
    *ngFor="let item of component_list_Data | slice : 0 : component.items_number"
  >
    <a *ngIf="item.type == 'image'" (click)="navigateToListShow(item)">
      <div class="card" [appGridStyle]="component" [ngStyle]="{'border':component.Style_border==1?'1px solid rgba(0,0,0,.125)':'none'}">
        <div class="row" style="height: 100%">
          <div class="col-md-2 col-sm-3 col-xs-3">
            <img class="img" [src]="item.image" [ngClass]="component.Style_image_shape=='1'?'card-img-side':'card-img-center'"/>
          </div>
          <div class="col-md-7 col-sm-7 col-xs-7">
            <h5 class="main-title" 
    [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
    > {{item.title}}</h5>

            <p
              class="p-date"
              *ngIf="
                item.module_calendar == 1 &&
                Hide_Date == 0 &&
                item.end_date != ''
              "
            >
              {{ item.end_date }}
            </p>
            <p
              class="p-date"
              *ngIf="
                item.module_calendar == 2 &&
                Hide_Date == 0 &&
                item.end_date != ''
              "
            >
              {{ item.end_date_hijri }}
            </p>

          
            <p class="paragraph"  
    [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"

           >{{item.description}}</p>
          </div>
          <div class="col-md-3 col-sm-3 col-xs-3 right-border txt-center">
            <button
              class="show-more"
              [ngStyle]="{ 'background-color': websiteMainColor }"
            >
              {{ "HOME.READ_MORE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>
