<div [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>
<div class="row" [appMyStyles]="component">

  <div class="image-container" [ngClass]="col" 
  [ngStyle]="{'height':item?.title== null ? '300px':'400px'}"
  [appGridStyle]="component"
    *ngFor="let item of ImageTitleData | slice : 0 : component.items_number">

    <img class="w-100" src="{{ item?.image }}" [ngClass]="item?.title== null ? 'h-100':'h-75'" />

    <p *ngIf="item?.title!= null" [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
      class="my-2">{{item?.title}}</p>
  </div>


</div>
<div class="row w-100 d-flex justify-content-center mb-5">

  <button [ngStyle]="{
      color: websiteSecColor
    }" class="btn see-more">
    <i class="fas fa-chevron-down" aria-hidden="true"></i>

    {{'see more' | translate}}

  </button>
</div>

<app-alert *ngIf="ImageTitleData?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
  [message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>