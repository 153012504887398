import { RouterModule, Routes } from "@angular/router";

import { AboutComponent } from "./general-pages/pages/about/about.component";
import { ChangePasswordComponent } from "./authentication/pages/change-password/change-password.component";
import { CmsLoginComponent } from "./cms/pages/cms-login/cms-login.component";
import { ContactComponent } from "./general-pages/pages/contact/contact.component";
import { HomeComponent } from "./general-pages/pages/home/home.component";
import { LoginPageComponent } from "./authentication/pages/login-page/login-page.component";
import { NgModule } from "@angular/core";
import { PrivacyPolicyComponent } from "./general-pages/pages/privacy-policy/privacy-policy.component";
import { RegisterVerificationComponent } from "./register-verification/register-verification.component";
import { RegistrationComponent } from "./authentication/pages/registration/registration.component";
import { ResetPassword1Component } from "./authentication/pages/reset-password1/reset-password1.component";
import { ResetPassword2Component } from "./authentication/pages/reset-password2/reset-password2.component";
import { ResetPasswordComponent } from "./authentication/pages/reset-password/reset-password.component";
import { SearchComponent } from "./general-pages/pages/search/search.component";
import { TermsComponent } from "./general-pages/pages/terms/terms.component";
import { TestFormGeneratorComponent } from "./test-form-generator/test-form-generator.component";
import { FormGeneratorViewComponent } from "./form-generator/components/form-generator-view/form-generator-view.component";
import { ProfileComponent } from "./authentication/pages/profile/profile.component";
import { IframeComponent } from "./iframe/iframe.component";

export const routes: Routes = [
  { path: "", component: CmsLoginComponent },
  { path: "", redirectTo: "cms", pathMatch: "full" },

  // { path: "", component: HomeComponent },
  // { path: "", redirectTo: "cms", pathMatch: "full" },

  { path: "home", component: HomeComponent },
  { path: "search", component: SearchComponent },

  { path: "registration", component: RegistrationComponent },
  { path: "login", component: LoginPageComponent },
  { path: "Email-Verification", component: RegisterVerificationComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "reset-password1", component: ResetPassword1Component },
  { path: "new-password", component: ResetPassword2Component },
  { path: "profile", component: ProfileComponent },

  { path: "change-password", component: ChangePasswordComponent },
  { path: "about", component: AboutComponent },
  { path: "contact", component: ContactComponent },

  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "terms&conditions", component: TermsComponent },

  { path: "form", component: TestFormGeneratorComponent },
  { path: "list-form", component: FormGeneratorViewComponent },
  { path: "iframe", component: IframeComponent },


  {
    path: "event",
    loadChildren: () =>
      import("./events/events.module").then((m) => m.EventsModule),
  },

  {
    path: "articles",
    loadChildren: () =>
      import("./articles/articles.module").then((m) => m.ArticlesModule),
  },

  {
    path: "cms",
    loadChildren: () => import("./cms/cms.module").then((m) => m.CmsModule),
  },
  {
    path: "cmsforms",
    loadChildren: () =>
      import("./cms-forms/cms-forms.module").then((m) => m.CmsFormsModule),
  },
  {
    path: "cmspages",
    loadChildren: () =>
      import("./cms-general-pages/cms-general-pages.module").then(
        (m) => m.CmsGeneralPagesModule
      ),
  },
  {
    path: "news",
    loadChildren: () =>
      import("./general-pages/pages/news/news.module").then(
        (m) => m.NewsModule
      ),
  },
  {
    path: "about-us",
    loadChildren: () =>
      import("./general-pages/pages/about-us/about-us.module").then(
        (m) => m.AboutUsModule
      ),
  },
  {
    path: "forms",
    loadChildren: () => import("./form/form.module").then((m) => m.FormModule),
  },
  {
    path: "chat",
    loadChildren: () => import("./chat/chat.module").then((m) => m.ChatModule),
  },
  {
    path: "payment",
    loadChildren: () =>
      import("./payment/payment.module").then((m) => m.PaymentModule),
  },
  {
    path: "new",
    loadChildren: () =>
      import("./settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "registration",
    loadChildren: () =>
      import("./registration/registration.module").then(
        (m) => m.RegistrationModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "disabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
