<div class="container-fluid pt-5 pb-5" [appMyStyles]="article">
  <div class="row" >
    <app-header-title-desc [article]="article"></app-header-title-desc>
  </div>

  <div class="row d-flex align-items-stretch" [appMyStyles]="article">
    <!-- First Row: Large Image -->
    <div class="col-lg-3 col-sm-12" *ngIf="galleryData?.length > 0">
      <img *ngIf="galleryData[0]?.type == 'image'" class="cover-img img-fluid rounded" src="{{ galleryData[0]?.image }}"
        (click)="navigateToShow(galleryData[0]?.id)" />
    </div>

    <div class="col-lg-6 col-sm-12" *ngIf="galleryData?.length > 4">
      <div class="row">
        <!-- Second Row: Smaller Images -->
        <div class="col-lg-6 col-sm-6 mb-3" *ngIf="galleryData[1]">
          <img *ngIf="galleryData[1]?.type == 'image'" class="cover-img img-fluid rounded"
            src="{{ galleryData[1]?.image }}" (click)="navigateToShow(galleryData[1]?.id)" />
        </div>

        <div class="col-lg-6 col-sm-6 mb-3" *ngIf="galleryData[2]">
          <img *ngIf="galleryData[2]?.type == 'image'" class="cover-img img-fluid rounded"
            src="{{ galleryData[2]?.image }}" (click)="navigateToShow(galleryData[2]?.id)" />
        </div>

        <div class="col-lg-6 col-sm-6 mt-3" *ngIf="galleryData[3]">
          <img *ngIf="galleryData[3]?.type == 'image'" class="cover-img img-fluid rounded"
            src="{{ galleryData[3]?.image }}" (click)="navigateToShow(galleryData[3]?.id)" />
        </div>

        <div class="col-lg-6 col-sm-6 mt-3" *ngIf="galleryData[4]">
          <img *ngIf="galleryData[4]?.type == 'image'" class="cover-img img-fluid rounded"
            src="{{ galleryData[4]?.image }}" (click)="navigateToShow(galleryData[4]?.id)" />
        </div>

      </div>
    </div>

    <div class="col-lg-3 col-sm-6" *ngIf="galleryData?.length > 5">
      <img *ngIf="galleryData[5]?.type == 'image'" class="cover-img img-fluid rounded" src="{{ galleryData[5]?.image }}"
        (click)="navigateToShow(galleryData[5]?.id)" />
    </div>
  </div>


  <app-alert *ngIf="galleryData?.length == 0" [componentId]="article?.id" [mainId]="article?.main_id"
    [message]="'InfoAlarm' | translate" [type]="'warning'">
  </app-alert>

  <app-alert *ngIf="galleryData?.length < 6 " [componentId]="article?.id" [mainId]="article?.main_id"
    [message]="'NumberAlarm' | translate" [type]="'warning'">
  </app-alert>