<div
  [appMyStyles]="article"
  [ngClass]="{ 'background-color': article?.component_background_color }"
  class="py-2"
>
<app-header-title-desc [article]="article"></app-header-title-desc>

  <div
  *ngIf="article?.slider_icon === 1"
    class="row d-flex justify-content-between my-3 brand"
    
  >
    <div class="col-1 d-flex justify-content-center align-items-center arrow-left" >
      <i class="fa fa-solid fa-caret-left" (click)="prev()"></i>
    </div>

    <div
      *ngFor="let item of ImageTitleData | slice : i : ImageTitleData?.length<=4? i + (ImageTitleData?.length): i+ (ImageTitleData?.length - 1)"
      class=" px-3 py-1 d-flex flex-column brand-container"
    >
      <img class="brand-img" src="{{ item.image }}" />
      <h5
        class="color px-5 py-1 "
        [ngStyle]="{
          'font-size': article.title_size,
           color: article.title_color
        }"
      >{{item.title}}</h5>
    </div>

    <div class="col-1 d-flex justify-content-center align-items-center arrow-right">
      <i class="ms-5 fa fa-light fa-caret-right" (click)="next()"></i>
    </div>

  </div>

  <div
    *ngIf="article?.slider_icon !== 1"
    class="row d-flex justify-content-between my-3 brand"
    
  >
    <div
      *ngFor="let item of ImageTitleData"
      class="px-3 py-1 d-flex flex-column brand-container"
    >
      <img class="brand-img" src="{{ item.image }}" />
      <h5
        class="color px-5 py-1"
        [ngStyle]="{
          'font-size': article.title_size,
          color: article.title_color
        }"
      >
        {{ item.title }}
      </h5>
    </div>
  </div>
</div>
