<div class="row footer-padding" [ngStyle]="{ 'background-color': Footer_Bg_Color }" *ngIf="parentStyle==null">
  
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
      <p class="text-center" [ngStyle]="{ 'color': footer_color ,'font-size':footer_size }">
        <a class="footer-link" [ngStyle]="{ 'color': footer_color ,'font-size':footer_size }" >{{ "FOOTER.COPY_RIGHT" | translate }}</a>
      </p>
    </div>
     
    
</div>
<div class="row footer-padding" [ngStyle]="{ 'background-color': parentStyle.background }" *ngIf="parentStyle!=null">
  
  <div class="col-md-12 col-sm-12 col-xs-12 text-center">
    <p class="text-center" [ngStyle]="{ 'color': parentStyle.color ,'font-size':footer_size }">
      <a class="footer-link" [ngStyle]="{ 'color':  parentStyle.color,'font-size':footer_size }" >{{ "FOOTER.COPY_RIGHT" | translate }}</a>
    </p>
  </div>
   
  
</div>