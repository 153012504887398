<div
  class="background"
  [appMyStyles]="article"
  [ngStyle]="{
    'background-image': 'url(' + article.component_background_image + ')'
  }"
>
<app-header-title-desc [article]="article"></app-header-title-desc>


  <div
    class="row"
    
  ></div>
</div>
