<section
  class="aboutus"
  (click)="navigateToShow(About_Us_Desc_Data.id)"
  [appMyStyles]="article"
>
  <div
    class="row"
    
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      *ngIf="component_title_position == 'center'"
      style="text-align: center; margin-bottom: 20px"
    >
      <h3
        style="font-weight: bold"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h3>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>

    <div
      class="col-md-12 col-sm-12 col-xs-12"
      *ngIf="component_title_position != 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        [ngStyle]="{
          'border-left-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>
  </div>

  <div
    class="aboutus__title"
    
  >
    <p
      class="aboutus__title--title"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
      [innerHtml]="About_Us_Desc_Data.title_detailed"
    ></p>
    <h1
      class="aboutus__title--desc"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
      [innerHtml]="About_Us_Desc_Data.description_detailed"
    ></h1>

    <p
      class="aboutus__title--title"
      *ngIf="article.show_cms_title == 1 && article.text_as_html == 0"
    >
      {{ About_Us_Desc_Data.title }}
    </p>
    <h1
      class="aboutus__title--desc"
      *ngIf="article.show_cms_description== 1 && article.text_as_html == 0"
    >
      {{ About_Us_Desc_Data.description }}
    </h1>
  </div>

  <p
    class="aboutus--desc"
    
    [innerHTML]="About_Us_Desc_Data.content"
  ></p>
</section>
