<div class="container-fluid" [ngStyle]="{'background-color':footer_bg_color}">
  <div class="row">

    <div class="col-md-4 col-sm-4 col-xs-12 pl-lg-5 pr-5">
      <h4 [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'About'|translate}} {{website_name}} </h4>
      <div class="lines2">


        <div class="line2"></div>
      </div>

      <a routerLink="home">
        <img [ngClass]="logo_shape=='circle'?'footer-brand-circle':'footer-brand-square'" class="footer-brand"
          [src]="footerlogo">
      </a>


      <div class="mt-5 mb-5">
        <p *ngIf="data_position != 'left'" class="desc" [ngStyle]="{'color':footer_color,'font-size':Footer_font_size}">
          {{website_description}}
        </p>
        <ng-container
          *ngIf="(contactData?.email  != null || contactData?.mobile  != null  || contactData?.address  != null ) && data_position == 'left'">
         



          <p *ngIf="contactData?.address  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
            {{'Address'|translate}} :
            {{ contactData?.address }}
          </p>



          <p *ngIf="contactData?.mobile  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
            {{'GENERAL_PAGES.CONTACT.PHONE'|translate}} :
            {{ contactData?.mobile }}
            <span style="padding:0px 3px">|</span>

            {{contactData?.landline}}
          </p>

          <p *ngIf="contactData?.email  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
            <!-- {{'PROFILE.EMAIL'|translate}} : -->
            {{ contactData?.email }}
          </p>
        </ng-container>
        <div class="social" *ngIf="data_position == 'left'">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item" *ngFor="let link of SocialLinks">
              <a *ngIf="link.value != null && link.footer == 1" class="btn-floating btn-sm rgba-white-slight" href="{{ link?.value}}"
                target="_blank">
                <img class="icon" [src]="link?.icon" alt="">

              </a>
            </li>
          </ul>
        </div>
      </div>


    </div>


    <div class="col-md-4 col-sm-4 col-xs-12">

      <h4 [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'COMPANY PAGES'|translate}}</h4>
      <div class="lines">

        <div class="line"></div>
      </div>



      <ul [ngClass]="footeritems?.length <= 4 &&  data_position == 'right' ? 'one-row':'two-row'">
        <ng-container *ngFor="let item of footeritems">

          <li class="mb-3" *ngIf="item.menu_design.footer == 1" (click)="navigatetoModule(item)" [ngStyle]="{
                              'color':footer_color , 
                              'font-size' : Footer_font_size 
                             }">

            <a> <i [ngClass]="lang == 'en_container' ?'fa fa-angle-double-right':'fa fa-angle-double-left'"></i>
              {{item.name}}
            </a>

          </li>

        </ng-container>



      </ul>
    </div>

    <div class="col-md-4 col-sm-4 col-xs-12">
      <ng-container
        *ngIf="(contactData?.email  != null || contactData?.mobile  != null  || contactData?.address  != null ) && data_position != 'left'">
        <h4 [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'Contact Info'|translate}}</h4>
        <div class="lines">

          <div class="line"></div>
        </div>

        <p *ngIf="contactData?.address  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
          {{'Address'|translate}} :
          {{ contactData?.address }}
        </p>



        <p *ngIf="contactData?.mobile  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
          {{'PROFILE.PHONE_NUMBER'|translate}} :
          {{ contactData?.mobile }}
          <span style="padding:0px 3px">|</span>

          {{contactData?.landline}}
        </p>

        <p *ngIf="contactData?.email  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
          {{'PROFILE.EMAIL'|translate}} :
          {{ contactData?.email }}
        </p>
      </ng-container>

     

      <div class="social mt-5" *ngIf="data_position != 'left'">
        <ng-container *ngIf="SocialLinks?.length>0&& hasActive() && data_position == 'right'">
          <h4 [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'Follow Us on'|translate}}</h4>
          <div class="lines">
  
            <div class="line"></div>
          </div>
        </ng-container>
        <ul class="list-unstyled list-inline">
          <li class="list-inline-item" *ngFor="let link of SocialLinks">
            <a *ngIf="link.value != null" class="btn-floating btn-sm rgba-white-slight" href="{{ link?.value}}"
              target="_blank">
              <img class="icon" [src]="link?.icon" alt="">

            </a>
          </li>
        </ul>
      </div>

    </div>

  </div>
</div>
<app-links-footer [parentStyle]=passStyle *ngIf="copyWriteFooter==1"></app-links-footer>