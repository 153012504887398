<div [attr.dir]="lang === 'ar' ? 'rtl' : 'ltr'" [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="row d-flex justify-content-center"
       
       [appMyStyles]="article">
    <ng-container *ngFor="let row of Four_logos_Data | slice:0:article.items_number | chunk:chunkSize; let rowIndex = index">
      <div class="col-12 d-flex align-items-stretch mb-4">
        <ng-container *ngFor="let item of row; let i = index">
          <div class="col-md-3 col-sm-6">
            <div class="specialty-item">
              <div class="circle-container mx-auto mb-2">
                <div class="circle-number">
                  {{ rowIndex * 3 + i + 1 < 10 ? '0' + (rowIndex * 3 + i + 1) : rowIndex * 3 + i + 1 }}
                </div>
              </div>
              <h6 class="title"
                  [ngStyle]="{'font-size': article?.title_size,
        'text-transform':'none'
        ,'color': article?.title_color, 'font-weight': article?.data_title_weight}">
                {{ item.title }}
              </h6>
              <p class="desc-details"
                 [ngStyle]="{'font-size': article?.description_size,  'text-transform':'lowercase','color': article?.description_color, 'font-weight': article?.data_desc_weight}">
                {{ item.description }}
              </p>
            </div>
          </div>

          <div *ngIf="i < row.length - 1" class="col-md-1 text-center d-flex align-items-center">
            <div class="line"></div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
