<div  [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
</div>

<div class="mt-3 mb-5">
  <div
    class="row col-lg-12 m-auto py-3"
    [ngStyle]="{
      'background-image': component?.component_background_image !== 'https://limberx.com/test/limberx_generator_api/public/img/Default_background.png' 
        ? 'url(' + component?.component_background_image + ')' 
        : 'none'
    }"
    
    [appMyStyles]="component"
    
  >
  <!-- for side content -->
    <div [ngClass]="col" class="mb-3"  *ngFor="let item of FourbuttoncardsData | slice : 0 : (component?.items_number === 2 ? FourbuttoncardsData.length : component?.items_number); index as i">
      <div [ngClass]="component?.Style_image_shape == 0 ? 'small-card-side d-flex flex-wrap align-items-center flex-row' : 'card-side d-flex flex-wrap align-items-center flex-row'" 
      [appGridStyle]="component">
        <div class="col-5 p-0"
        [ngClass]="component.Style_image_shape == '1' ? 'h-100':''">
          <img
            [ngClass]="component.Style_image_shape == '1' ? 'card-img-side':component?.Style_image_is_icon==1?'card-icon':'card-img'"
            [src]="item?.image"
            class="img-fluid"
            alt="Card image"
          />
        </div>
  
        <div class="col-7 p-0 h-100" [ngStyle]="{'background-color':component?.Style_card_background}">
          <h6 class="px-3 pt-3"
            [ngStyle]="{
              'font-size': component?.title_size,
              color: component?.title_color,
              'font-weight': component?.data_title_weight,
        'text-transform':'none'

            }"
          >
            {{ item.title }}
          </h6>
          <div>
            <p
              class="mb-5 px-3"
              [ngStyle]="{
                'font-size': component?.description_size,
                'text-transform':'lowercase',
                color: component?.description_color,
                'font-weight': component?.data_desc_weight
              }"
            >
              {{ item.description }}
            </p>
  
            <button
              *ngIf="component?.component_buttons?.length > 0"
              [ngStyle]="{
                'background-color': component?.component_buttons[0]?.button_background_color,
                color: component?.component_buttons[0]?.button_color,
                'border-color': component?.component_buttons[0]?.button_background_color,
                'font-size': '16px',
                'font-weight': component?.component_buttons[0]?.button?.font_weight
              }"
              (click)="navigateToShow(item.id)"
              class="btn btn-primary read-more-side"
            >
              <ng-container *ngIf="lang == 'ar_container'">
                {{ component?.component_buttons[0]?.button_text }}
              </ng-container>
              <ng-container *ngIf="lang == 'en_container'">
                {{ component?.component_buttons[0]?.button_text }}
              </ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>
  
  
  </div>
</div>

<app-alert 
  *ngIf="FourbuttoncardsData?.length == 0" 
  [componentId]="component?.id" 
  [mainId]="component?.main_id"
  [message]="'InfoAlarm' | translate"
  [type]="'warning'"
>
</app-alert>