import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';


@Component({
  selector: 'app-how-to-image-desc',
  templateUrl: './how-to-image-desc.component.html',
  styleUrls: ['./how-to-image-desc.component.scss']
})
export class HowtoImageDescComponent implements OnInit {
  @Input() article: any;
  howToDetails:any=[];
  lang: string;
  constructor(
    private homeService: HomeService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.howToDetails = res.content;
        
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.howToDetails = res.content;
        });
    });
  }
 




}
