import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from "../../shared_services/navigation.service";

import { HomeService } from 'src/app/general-pages/pages/home/home.service';
import { LangService } from '../../shared_services/lang.service';

@Component({
  selector: 'app-card-bg-label',
  templateUrl: './card-bg-label.component.html',
  styleUrls: ['./card-bg-label.component.scss']
})
export class CardBgLabelComponent implements OnInit {

  @Input() component: any;

  APIParameters;

  threeDateCardsData;

  website_main_color: any;

  websiteSecColor: any;





  currentLang;


  Items_Number;

  lang: { key: String; value: String } = { key: "en", value: "EN" };



  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,

    private langService: LangService,

    private navigationService:NavigationService

  ) {
    this.langService.getLang().subscribe((lang) => {
      this.lang = lang;
    });
  }

  ngOnInit(): void {


    this.website_main_color = JSON.parse(localStorage.getItem("mainColor"));

    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));


    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.threeDateCardsData = res.content;
              
      });
    this.currentLang = localStorage.getItem("lang");

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.APIParameters)
        .subscribe((res: any) => {
          this.threeDateCardsData = res.content;
    
        });
      });
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.component,id,2);

  }

}
