<div [appMyStyles]="article">

    <div
      class="row"
      
    >

        <div
        class="col-md-4 col-sm-12 col-xs-12"
        style="text-align: center"
        *ngIf="component_title_position == 'center'"
        >
        </div>

        <div
            class="col-md-4 col-sm-12 col-xs-12"
            style="text-align: center"
            *ngIf="component_title_position == 'center'"
        >
            <h4
            *ngIf="lang == 'en_container'"
            class="compoenet-title-center"
            [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
            >
            {{ ComponentTitle }}
            </h4>
            <h4
            *ngIf="lang == 'ar_container'"
            class="compoenet-title-center"
            [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
            >
            {{ ComponentTitle }}
            </h4>
            <div class="lines">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
            <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
            {{ component_sub_title }}
            </h6>
        </div>

        <div
        class="col-md-4 col-sm-12 col-xs-12"
        style="text-align: center"
        *ngIf="component_title_position == 'center'"
        >
        </div>
  
        <div
            class="col-md-12 col-sm-12 col-xs-12"
            *ngIf="component_title_position != 'center'"
        >
            <h4
            *ngIf="lang == 'en_container'"
            class="component-title-left"
            [ngStyle]="{
                'border-left-color': websiteMainColor,
                color: title_font_color,
                'font-size': title_font_size
            }"
            >
            {{ ComponentTitle }}
            </h4>
            <h4
            *ngIf="lang == 'ar_container'"
            class="component-title-right"
            [ngStyle]="{
                'border-right-color': websiteMainColor,
                color: title_font_color,
                'font-size': title_font_size
            }"
            >
            {{ ComponentTitle }}
            </h4>
            <div class="lines">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
            <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
            {{ component_sub_title }}
            </h6>
        </div>
        
    </div>


    <div
        class="row"
        
    >

    <div class="row" *ngFor="let item of TitleDescriptionCardData|slice:0:4" style="margin-bottom: 30px;">

        <div class="col-md-8 col-sm-12 col-xs-12">
            <h5
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
            [innerHtml]="item.title_detailed"
        ></h5>
        <div class="lines">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
        <p
            *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
            [innerHtml]="item.description_detailed"
        ></p>
        </div>

        <div class="col-md-4 col-sm-12 col-xs-12">
            <img class="main-img" src="{{ item.image }}" />

        </div>

    </div>

    </div>

</div>