<div [appMyStyles]="article">
  <app-header-title-desc [article]="article"></app-header-title-desc>
  <div class="row justify-content-center">
    <form
      [formGroup]="ReservationForm"
      (ngSubmit)="submitForm(ReservationForm.value)"
      class="d-flex justify-content-center flex-column align-items-center ml-4 col-6 mt-3 margin"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <input
        type="text"
        class="form-control form-contact-input  m-3"
        placeholder="name "
        formControlName="name"
        [ngStyle]="{
          color: article.title_font_color,
          'font-size': article.title_font_size,
          'font-weight': article?.component_title_weight
        }"
      />
      <input
        type="text"
        class="form-control form-contact-input  m-3"
        placeholder="Email "
        formControlName="email"
      />
      <input
        type="text"
        class="form-control form-contact-input  m-3"
        placeholder="Phone "
        formControlName="mobile"
      />
      <textarea
        type="text"
        class="form-control form-contact-area  m-3 message"
        placeholder="message "
        formControlName="message"
      ></textarea>
      <button
        class="btn btn-primary px-5 py-2"
        [disabled]="!ReservationForm.valid"
        type="submit"
      >
        Send
      </button>
    </form>
    <div *ngIf="contact_us?.length>0"
      class="d-flex col justify-content-center"
      [class]="article?.animations?.length > 1 ? article?.animations[1] : ''"
    >
      <img 
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color
        }"
        class="ml-5 form-img"
        [src]="article?.component_background_image" 
      />
    </div>
  </div>
</div>
