<div class="container-fluid">
  <div class="row" [appMyStyles]="component">
    <app-header-title-desc [article]="component"></app-header-title-desc>
  </div>

  <div class="row" [appMyStyles]="component">
    <div
      class="col-md-4 col-sm-12 col-xs-12 mb-5"
      [class]="component?.animations?.length > 1 ? component?.animations[0] : ''"
      *ngFor="let item of threeDateCardsData | slice : 0 : component.items_number"
    >
      <div
        class="card"
        onMouseOver="this.style.outline='thin website_main_color solid'"
        onMouseOut="this.style.outline='thin transparent solid'"
      >
        <div
       
          class="card-img-top"
          [ngStyle]="{ 'background-image': 'url(' + item?.image + ')' }"
        ></div>

        <!-- <iframe
                        *ngIf="item?.type  != 'image' "
                         [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(item?.link))"
                         width="100%"
                         height="170px"
                         frameborder="0"
                         title="test"
                         webkitallowfullscreen
                         mozallowfullscreen
                         allowfullscreen
                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    ></iframe> -->

        <div class="top-left">
          <button *ngIf="item?.price != null" class="btn  text-center" 
           [ngStyle]="{
              'background-color':websiteSecColor}">
            {{ item?.price }}
          </button>
          <button *ngIf="item?.image_tags != null" class="btn  text-center"
          [ngStyle]="{
              'background-color':websiteSecColor}">
            {{ item?.image_tags }}hh
          </button>
          <button *ngIf="item?.price == null " class="btn text-center"
          [ngStyle]="{
              'background-color':websiteSecColor}">
            {{ "Free" | translate }}
          </button>
        </div>
        <div class="top-right">
          <button class="btn btn-light rounded-circle text-center">
            <i class="far fa-bookmark"></i>
          </button>
        </div>
        <div class="card-body">
          <div class="row">
            <div
              class="col-md-3 col-sm-4 xol-xs-4"
              *ngIf="item?.start_date != ''"
            >
              <!-- for arabic time -->
              <time
                datetime=""
                class="icon"
                *ngIf="lang.key == 'ar' && item?.start_date != ''"
              >
                <em>{{ item?.start_date | dateFormatter : "Y" : "ar" }}</em>
                <strong>{{
                  item?.start_date | dateFormatter : "MMM" : "ar"
                }}</strong>
                <span [ngStyle]="{ color: website_main_color }">{{
                  item?.start_date | dateFormatter : "D" : "ar"
                }}</span>
              </time>
              <!-- for english time -->
              <time
                datetime=""
                class="icon"
                *ngIf="lang.key == 'en' && item?.start_date != ''"
              >
                <em>
                  {{ item?.start_date | dateFormatter : "Y" : "en" }}
                </em>
                <strong>{{
                  item?.start_date | dateFormatter : "MMM" : "en"
                }}</strong>
                <span [ngStyle]="{ color: website_main_color }">{{
                  item?.start_date | dateFormatter : "D" : "en"
                }}</span>
              </time>
            </div>

            <div
              class="col-md-9 col-sm-9 col-xs-9"
              *ngIf="item?.start_date != ''"
            >
              <div class="event-desc">
                <h4 class="card-title" 
      [ngStyle]="{'font-size':component?.title_size, 'color':component?.title_color,'font-weight':component?.data_title_weight}"
      >
                  {{ item?.title }}
                </h4>
                <p 
      [ngStyle]="{'font-size':component?.description_size, 'color':component?.description_color,'font-weight':component?.data_desc_weight}"
      >
                  {{ item?.description }}
                </p>
                <a
                  (click)="navigateToShow(item?.id)"
                  class="btn text-light text-center book-btn"
                  [ngStyle]="{
                    'background-color': websiteSecColor,
                    width: '50%'
                  }"
                  >{{ "Book" | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alert alert-warning text-center" role="alert"  *ngIf="threeDateCardsData?.length == 0">
      <p>this design need some data to be visible  </p>
    </div>
    
  </div>
</div>
