import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DivideRowStyleService {

  constructor() { }
  getColClass(items:[], component: any = null): string {
    const length: number = items.length;
    const itemsPerRow = (component?.items_number == 4) ? length : (component?.items_number ?? length);

    if (itemsPerRow === 3 || itemsPerRow === 6 ) {
      return 'col-lg-4 col-md-4 col-sm-4 col-xs-12'; // Exactly 3 items
    } else if (itemsPerRow === 1) {
      return 'col-lg-12 col-md-12 col-sm-12 col-xs-12'; // More or fewer than 3 items
    } else if (itemsPerRow === 2) {
      return 'col-lg-6 col-md-6 col-sm-6 col-xs-12'; // More or fewer than 3 items
    } else if (itemsPerRow === 12) {
      return 'col-lg-2 col-md-4 col-sm-4 col-xs-12'; // Exactly 12 items
    } else if (itemsPerRow === 8 || itemsPerRow === 4) {
      return 'col-lg-3 col-md-6 col-sm-6 col-xs-12'; // Exactly 8 items
    } else if (itemsPerRow >= 4 && itemsPerRow != 12) {
      return 'col-lg-3 col-md-6 col-sm-6 col-xs-12'; // More than or equal to 4 items (excluding 6, 8, and 12)
    }
  }
}
