import { Component, HostListener, Input, OnInit, ViewChildren, QueryList, ElementRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';
import { DivideRowStyleService } from '../../shared_services/divide-row-style.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-videos-slider',
  templateUrl: './videos-slider.component.html',
  styleUrls: ['./videos-slider.component.scss']
})
export class VideosSliderComponent implements OnInit, OnDestroy {
  @Input() component: any;
  @ViewChildren('videoFrame') videoFrames: QueryList<ElementRef<HTMLIFrameElement>>;

  imageTitleData: any[] = [];
  visibleItems: any[] = [];
  currentIndex: number = 0;
  itemsToShow: number = 4;
  lang: string;
  currentLang: string;
  outData: any;
  websiteMainColor: any;
  col: string;
  currentlyPlaying: number | null = null;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private colStyleService: DivideRowStyleService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));
    
    this.homeService.getHomeComponentsData(this.component).subscribe((res: any) => {
      this.imageTitleData = res.content.map(item => ({
        ...item,
        safeUrl: this.sanitizeUrl(this.transformYouTubeUrl(item.link))
      }));
      this.updateItemsToShow();
      this.updateVisibleItems();
      this.col = this.colStyleService.getColClass(res.content, this.component);
    });
    
    this.translateService.onLangChange.subscribe(() => {
      this.currentLang = localStorage.getItem("lang");
      this.homeService.getHomeComponentsData(this.component).subscribe((res: any) => {
        this.imageTitleData = res.content.map(item => ({
          ...item,
          safeUrl: this.sanitizeUrl(this.transformYouTubeUrl(item.link))
        }));
        this.col = this.colStyleService.getColClass(res.content, this.component);
        this.updateItemsToShow();
        this.updateVisibleItems();
            });
    });
  }
  
  ngOnDestroy(): void {
    this.deactivateAllIframes();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateItemsToShow();
    this.updateVisibleItems();
  }

  next(): void {
    this.deactivateAllIframes();
    const maxIndex = this.imageTitleData?.length - this.itemsToShow;
    if (this.currentIndex < maxIndex) {
      this.currentIndex++;
      this.updateVisibleItems();
    }
  }

  prev(): void {
    this.deactivateAllIframes();
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateVisibleItems();
    }
  }

  updateVisibleItems(): void {
    if (this.imageTitleData && this.imageTitleData.length) {
      this.visibleItems = this.imageTitleData.slice(
        this.currentIndex,
        this.currentIndex + this.itemsToShow
      );
    }
  }

  updateItemsToShow(): void {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 576) {
      this.itemsToShow = 1;
    } else if (screenWidth <= 768) {
      this.itemsToShow = 2;
    } else if (screenWidth <= 992) {
      this.itemsToShow = 3;
    } else {
      this.itemsToShow = 4;
    }
  }

  transformYouTubeUrl(url: string): string {
    return url.replace("watch?v=", "embed/") + "?autoplay=1&mute=1&enablejsapi=1&rel=0";
  }

  sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  deactivateAllIframes(): void {
    this.currentlyPlaying = null;
  }

  activateIframe(index: number): void {
    console.log('Activating iframe at index:', index);
    this.deactivateAllIframes();
    this.currentlyPlaying = index;
  }
  
  deactivateIframe(index: number): void {
    if (this.currentlyPlaying === index) {
      this.currentlyPlaying = null;
    }
  }

  openFullscreenIframe(index: number): void {
    if (this.videoFrames) {
      const iframe = this.videoFrames.find((_, i) => i === index);
      if (iframe && iframe.nativeElement) {
        if (iframe.nativeElement.requestFullscreen) {
          iframe.nativeElement.requestFullscreen();
        }
      }
    }
  }

  isSlider() {
    if (!this.imageTitleData) return [];
    return this.component.slider_icon == 0 ? this.imageTitleData : this.visibleItems;
  }

  isPlaying(index: number): boolean {
    return this.currentlyPlaying === index;
  }
}
