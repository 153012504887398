import { Component, Input, OnInit } from "@angular/core";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../shared_services/navigation.service";

@Component({
  selector: "app-about-us-overview",
  templateUrl: "./about-us-overview.component.html",
  styleUrls: ["./about-us-overview.component.scss"],
})
export class AboutUsOverviewComponent implements OnInit {
  type: string = "mission";
  @Input() component: any;

  aboutUsOverviewData: any;

  websiteSecColor: any;

  lang: string;
  currentLang: string;

  hoverHeaderItem: number;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private navigationService:NavigationService

  ) {}

  ngOnInit(): void {
    
    this.lang = localStorage.getItem("container_lang");
     
    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.aboutUsOverviewData = res.content;
        
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.aboutUsOverviewData = res.content;
        });
    });
  }

  chooseType(type: string) {
    this.type = type;
  }

  Navigatetoshow(id) {
    this.navigationService.navigateToShow(this.component,id,2);

  }
}
