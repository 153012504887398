<div class="container-fluid" [ngStyle]="{'background-color':footer_bg_color}">
  <div class="row" *ngIf="footerlogo != null">

    <div class="col-md-6 col-sm-4 col-xs-12 px-lg-4 px-md-4 d-flex logo-desc">
      <!-- footer website logo -->
      <a routerLink="home">
        <img class="footer-brand" [src]="footerlogo">
      </a>
      <!-- website description -->
      <div class="mt-5 mb-5 px-lg-4 px-md-4 px-sm-2 px-xs-2">
        <p class="desc" [ngStyle]="{'color':footer_color}">
          {{website_description}}
        </p>
      </div>
     

    </div>

    <!-- empty space -->
    <div class="col-md-3 col-sm-4 col-xs-12">

      

      <ul [ngClass]="footeritems?.length <= 4  ? 'one-row':'two-row'">
        <ng-container *ngFor="let item of footeritems">

            <li class="mb-3" 
                *ngIf="item.menu_design.footer == 1" 
                (click)="navigatetoModule(item)"
                [ngStyle]="{
                            'color':footer_color , 
                            'font-size' : Footer_font_size 
                           }">

              <a> <i [ngClass]="lang == 'en_container' ?'fa fa-angle-double-right':'fa fa-angle-double-left'" ></i>
                {{item.name}}
              </a>
              
            </li>

        </ng-container>
       

     
      </ul>
    </div>

    <div class="col-md-3 col-sm-4 col-xs-12">
      <h4 [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">{{'Contact Info'|translate}}</h4>
      <!-- menu list -->

      <p *ngIf="contactData.address  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
        {{'Address'|translate}} :
       {{ contactData.address }}
      </p>

      

      <p *ngIf="contactData.mobile  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
              {{'PROFILE.PHONE_NUMBER'|translate}} :
              {{ contactData.mobile }} 
              <span style="padding:0px 3px">|</span> 
              <!-- {{contactData.postal_code}}
              <span style="padding:0px 3px">/</span>  -->
              {{contactData.landline}}
      </p>

      <p *ngIf="contactData.email  != null  " [ngStyle]="{ 'color':footer_color,'font-size':Footer_font_size}">
        {{'PROFILE.EMAIL'|translate}} :
      {{ contactData.email }}
      </p>

       <!-- social links -->
       <div class="social mt-5">
        <ul class="list-unstyled list-inline">
          <li class="list-inline-item" *ngFor="let link of SocialLinks">
            <a
            *ngIf="link.value != null"
            class="btn-floating btn-sm rgba-white-slight"
            href="{{ link?.value}}"
            target="_blank"
          >
           <img [src]="link?.icon" alt=""> 
         
          </a>
          </li>
        </ul>
      </div>
     
    </div>

  </div>





</div>
<app-links-footer [parentStyle]=passStyle *ngIf="copyWriteFooter==1"></app-links-footer>
