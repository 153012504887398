import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';
import { LangService } from '../../shared_services/lang.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ModuleService } from 'src/app/cms/services/module.service';
import { FormService } from 'src/app/form/services/form.service';

@Component({
  selector: 'app-contact-us7',
  templateUrl: './contact-us7.component.html',
  styleUrls: ['./contact-us7.component.scss']
})
export class ContactUs7Component implements OnInit {

  @Input() component: any;

  ImageTitleData;
  
  lang: string;
  currentLang: string;

  outData: any;
  ReservationForm: FormGroup;
  contact_us: any;
  Button_Color: any;
  Button_Background_Color: any;
  websiteMainColor: any;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private langService: LangService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private formService: FormService,
    private moduleService: ModuleService
    ) {this.ReservationForm = formBuilder.group({
      name: ["", Validators.compose([Validators.required])],
      email: ['',Validators.compose([Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")])],
      mobile: ["", Validators.compose([Validators.required])],
      message: ["", Validators.compose([Validators.required])],
      })}

  ngOnInit(): void {
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.websiteMainColor = JSON.parse(localStorage.getItem("mainColor"));

    this.Button_Color = this.component.button_color;
    this.Button_Background_Color = this.component.button_background_color;
    this.moduleService.GetContactInfo().subscribe((res:any)=>{
      this.contact_us=res.content;

      console.log("contact us data :", this.contact_us);
      console.log("component  us data :", this.component);

    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.ImageTitleData = res.content;
      });

      this.outData = this.ImageTitleData;
    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      })
      this.homeService
        .getHomeComponentsData(this.component)
        .subscribe((res: any) => {
          this.ImageTitleData = res.content;
        });

    });
  }
  submitForm(Data) {

    this.formService.ReservationForm1(Data).subscribe((res: any) => {
      if (res.status.code === 200) {
        this.toastr.success(res.status.message);
        this.ReservationForm.reset();

      } else if (res.status.code === 400) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code == 401) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 403) {
        let validation_error = res.status.validation_errors;
        validation_error.forEach((element) => {
          if (element.field == "message") {
            this.toastr.error(element.message);
          }
          else if (element.field == "email") {
            this.toastr.error(element.message);
          }
          else if (element.field == "mobile") {
            this.toastr.error(element.message);
          }
          else if (element.field == "name") {
            this.toastr.error(element.message);
          }

        });
      }
    });
  }

}
