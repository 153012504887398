<div class="container" [appMyStyles]="article">
  <div
    class="row"
    
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      style="text-align: center"
      *ngIf="component_title_position == 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="compoenet-title-center"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="compoenet-title-center"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>

    <div
      class="col-md-12 col-sm-12 col-xs-12"
      *ngIf="component_title_position != 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        [ngStyle]="{
          'border-left-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>
  </div>

  <div
    class="row"
    
  >
    <div class="col-md-3 col-sm-12 col-xs-12">
      <h1
        class="main-title"
        *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
        [innerHtml]="Title_bg_Card_Data[0].title_detailed"
      ></h1>
      <h1 class="main-title" *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
        {{ Title_bg_Card_Data[0].title }}
      </h1>
    </div>

    <div class="col-md-3 col-sm-12 col-xs-12"></div>

    <div class="col-md-6 col-sm-12 col-xs-12"></div>
  </div>
</div>
