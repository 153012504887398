<div [appMyStyles]="article">
  <div
    class="row"
    
  >
    <div
      class="col-md-12 col-sm-12 col-xs-12"
      style="text-align: center"
      *ngIf="component_title_position == 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="compoenet-title-center"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="compoenet-title-center"
        [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>

    <div
      class="col-md-12 col-sm-12 col-xs-12"
      *ngIf="component_title_position != 'center'"
    >
      <h4
        *ngIf="lang == 'en_container'"
        class="component-title-left"
        [ngStyle]="{
          'border-left-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h4
        *ngIf="lang == 'ar_container'"
        class="component-title-right"
        [ngStyle]="{
          'border-right-color': websiteMainColor,
          color: title_font_color,
          'font-size': title_font_size
        }"
      >
        {{ ComponentTitle }}
      </h4>
      <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6>
    </div>
  </div>

  <div
    class="row bottom-responsive"
    
    style="margin-bottom: 60px"
  >
    <div
      class="col-md-6 col-sm-6 col-xs-6 text-center"
      *ngFor="let item of two_logo_Data | slice : 0 : 2"
    >
      <img
        class="logo-img"
        src="{{ item.image }}"
        (click)="navigateToShow(item.id)"
      />
    </div>
  </div>
</div>
