<div class="px-4">
  <div class="row align-items-stretch">
    <div
    *ngIf="Cards4ObjectivesData?.length > 0"
      class="col-md-5 col-sm-12 col-xs-12 left-column"
      
      (click)="navigateToShow(Cards4ObjectivesData[0]?.id)"
    >
      <h5 class="line-behind-title" [ngStyle]="{ color: title_font_color, 'font-size': title_font_size }">
        {{ ComponentTitle }}
      </h5>
      <!-- <h6 [ngStyle]="{ color: desc_font_color, 'font-size': desc_font_size }">
        {{ component_sub_title }}
      </h6> -->

      <h1 class="big-title">
        {{ Cards4ObjectivesData[0]?.title }}
      </h1>

      <p class="big-desc">
      {{ component_sub_title }}
      </p>

    </div>

    <div
    *ngIf="Cards4ObjectivesData?.length > 0"
      class="col-md-7 col-sm-12 col-xs-12 right-column"
      
      [appMyStyles]="article"
    >
      <div class="row">
        <div
          class="col-md-6 col-sm-12 col-xs-12"
          (click)="navigateToShow(Cards4ObjectivesData[0].id)"
        >
          <div class="card" [appGridStyle]="article">
            <img
              *ngIf="Cards4ObjectivesData[0]?.type == 'image'"
              src="{{ Cards4ObjectivesData[0]?.image }} "
            />

            <h6
            [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[0]?.title"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[0]?.description"
            ></p>

            <h6
            [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}"
             *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[0]?.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[0]?.description }}
            </p>
          </div>
        </div>
        <div
          class="col-md-6 col-sm-12 col-xs-12"
          (click)="navigateToShow(Cards4ObjectivesData[1]?.id)"
        >
          <div class="card" [appGridStyle]="article">
            <img
              *ngIf="Cards4ObjectivesData[1]?.type == 'image'"
              src="{{ Cards4ObjectivesData[1]?.image }}"
            />

            <h6
            [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[1]?.title"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[1]?.description"
            ></p>

            <h6
            [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}"
             *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[1]?.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[1]?.description }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-6 col-sm-12 col-xs-12"
          (click)="navigateToShow(Cards4ObjectivesData[2]?.id)"
        >
          <div class="card" [appGridStyle]="article">
            <img
              *ngIf="Cards4ObjectivesData[2]?.type == 'image'"
              src="{{ Cards4ObjectivesData[2]?.image }}"
            />
            <h6
            [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[2]?.title"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[2]?.description"
            ></p>

            <h6 
            [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}"
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[2]?.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[2]?.description }}
            </p>
          </div>
        </div>

        <div
          class="col-md-6 col-sm-12 col-xs-12"
          (click)="navigateToShow(Cards4ObjectivesData[3]?.id)"
        >
          <div class="card" [appGridStyle]="article">
            <img
              *ngIf="Cards4ObjectivesData[3]?.type == 'image'"
              src="{{ Cards4ObjectivesData[3]?.image }}"
            />

            <h6
            [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}"
              *ngIf="article.show_cms_title == 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[3]?.title"
            ></h6>
            <p
              *ngIf="article.show_cms_description== 1 && article.text_as_html == 1"
              [innerHtml]="Cards4ObjectivesData[3]?.description"
            ></p>

            <h6
            [ngStyle]="{'font-size':article?.title_size, 'color':article?.title_color,'font-weight':article?.data_title_weight}" 
            *ngIf="article.show_cms_title == 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[3]?.title }}
            </h6>
            <p *ngIf="article.show_cms_description== 1 && article.text_as_html == 0">
              {{ Cards4ObjectivesData[3]?.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
