import { Component, OnInit ,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/general-pages/pages/home/home.service';

@Component({
  selector: 'app-comments-with-cards',
  templateUrl: './comments-with-cards.component.html',
  styleUrls: ['./comments-with-cards.component.scss']
})
export class CommentsWithCardsComponent implements OnInit {

  @Input() component: any;
imageTitleData;

lang: string;
currentLang: string;

i:number=0;

Data;
constructor(
  private homeService: HomeService,
  private translateService: TranslateService,

  ) {}

ngOnInit(): void {
  console.log("212", this.component);
  
  this.lang = localStorage.getItem("container_lang");
  this.currentLang = localStorage.getItem("lang");
  this.homeService
    .getHomeComponentsData(this.component)
    .subscribe((res: any) => {
      this.imageTitleData = res.content;

    });

  this.translateService.onLangChange.subscribe((res) => {
    this.currentLang = localStorage.getItem("lang");
     
    this.homeService
      .getHomeComponentsData(this.component)
      .subscribe((res: any) => {
        this.imageTitleData = res.content;

      });

  });
}
    next(){
      this.imageTitleData.push(this.imageTitleData[this.i])
        this.i=this.i+1;

    }

    prev(){
      if(this.i<=0){

      }
      else{
        this.i=this.i-1;
      }
    }

}
