<app-loader></app-loader>
<div class="main-container" [ngStyle]="{ 'font-family': website_font_type }"
  [hidden]="loadingService.isLoading | async">
  <!-- <div class="edit-header" (click)="showDialog('header')">
  <button pButton type="button" icon="pi pi-pencil" class="p-button-raised p-button-rounded" style="margin: 5px;"></button>
</div> -->

  <!-- 11 , 12, 17, 18 ,19 ,29 ,37 -->
  <app-header *ngIf="Header == '11' && showHeader"></app-header>
  <app-header2 *ngIf="Header == '12' && showHeader && applicationId != '358'"></app-header2>
  <app-no-header *ngIf="applicationId == '358' && Header == '12'"></app-no-header>
  <app-menu-transparent *ngIf="Header == '17' && showHeader"></app-menu-transparent>

  <app-header5 *ngIf="Header == '18' && showHeader"></app-header5>
  <app-header3 *ngIf="Header == '19' && showHeader"></app-header3>
  <app-academy-menu *ngIf="Header == '29' && showHeader"></app-academy-menu>


  <app-header-with-two-button *ngIf="Header == '37' && showHeader"></app-header-with-two-button>

  <app-popup></app-popup>
  <div [ngStyle]="{ 'background-color': website_background_color }">
    <router-outlet></router-outlet>
  </div>

  <!-- <app-loader></app-loader>
      <router-outlet></router-outlet> -->
      <!-- <div class="edit-header" (click)="showDialog('footer')">
        <button pButton type="button" icon="pi pi-pencil" class="p-button-raised p-button-rounded" style="margin: 5px;"></button>
      </div>  -->
      <!-- 7 , 14 ,9 , 20  -->

  <app-etaam-footer *ngIf="Footer == '7' && showHeader"></app-etaam-footer>
  <app-footer2 *ngIf="Footer == '9' && showHeader"></app-footer2>
  <app-footer4 *ngIf="Footer == '20' && showHeader"></app-footer4>
  <app-footer5 *ngIf="Footer == '14' && showHeader"></app-footer5>

  <app-links-footer *ngIf="Footer != '7' && Footer != '9' && Footer != '14' && Footer != '20'"></app-links-footer>


  <!-- WhatsApp Icon (Only if applicationId is 358) -->
  <div *ngIf="applicationId == '358'" class="whatsapp-icon">
    <a href="https://api.whatsapp.com/send?phone=201090005314&amp;text=Hello" class="float" target="_blank" aria-label="WhatsApp">
      <i class="fab fa-whatsapp my-float"></i>
    </a>
  </div>



</div>