import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { CommonGeneralService } from "src/app/general-pages/pages/services/common-general.service";
import { NavigationService } from "../../shared_services/navigation.service";
declare var intlTelInput: any; 
@Component({
  selector: 'app-contactus8',
  templateUrl: './contactus8.component.html',
  styleUrls: ['./contactus8.component.scss']
})
export class Contactus8Component implements OnInit {

  @Input() article: any;

  FourbuttoncardsData;
 
  lang: string;
  currentLang: string;

  video_array: any[] = [];

  Button_Color: any;
  Button_Background_Color: any;
  main_id: any;
  websiteSecColor: any;

  submitted = false;
  guest_value = false;

  contactForm: FormGroup;
  formdata = new FormData();
  message_title;
  message_id;

  file_url: any;

  uploaded_file = false;
  fileName: any;

  RandomNumber1;
  RandomNumber2;
  Contact_Image: any;
  Contact_Data=[];
  Background_image: any;
  button: any;

  constructor(
    private homeService: HomeService,
    public sanitizer: DomSanitizer,
    private langService: LangService,
    public fb: FormBuilder,
    private commonPagesService: CommonGeneralService,
    private toastr: ToastrService,
    private router: Router,
    private navigationService:NavigationService
  ) {}

  ngOnInit(): void {    
    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");

    this.main_id = this.article.main_id;
    this.Background_image = this.article.component_background_image

    this.websiteSecColor = JSON.parse(localStorage.getItem("secColor"));

    this.Button_Color = this.article.button_color;
    this.Button_Background_Color = this.article.button_background_color

    this.button = this.article.component_buttons[0]

    this.initform();
    this.randomNumber(0, 10);
    this.randomNumber(0, 10);

    this.commonPagesService.getContactData().subscribe((res: any) => {
      this.Contact_Image = res.content[0].photo
     
    })

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Contact_Data = res.content;
     
      });

  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article,id,2);
  }

  initform() {
    this.contactForm = this.fb.group({
      name: ["", [Validators.required]],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ]),
      ],
      mobile: ["", [Validators.required, Validators.pattern(/01[0-9]{9}/g)]],
      message: ["", [Validators.required]],
    
    });
  }

  readImage(event) {
    var files = event.target.files[0];
    this.fileName = event.target.files[0].name;

    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.file_url = "data:application/;base64," + btoa(binaryString);
    this.uploaded_file = true;

  }

  submit(formValue) {
    this.submitted = true;
  
      let submitobj = {
        name: this.contactForm.value["name"],
        email: this.contactForm.value["email"],
        mobile: this.contactForm.value["mobile"],
        message: this.contactForm.value["message"],
        lang_id:1
        };

      this.commonPagesService
        .submitContactForm(submitobj)
        .subscribe((res: any) => {
        
          //  this.toastr.error(res.status.error_details);

          if (res.status.code === 200) {
            this.toastr.success(res.status.message);
            this.langService.getLang().subscribe((res) => {
              this.router.navigate([`${res.key}/home`]);
            });
            // this.router.navigateByUrl("/home");
          } else if (res.status.code === 401) {
            this.toastr.error(res.status.error_details);
          } else if (res.status.code === 403) {
            let validation_error = res.status.validation_errors;
            validation_error.forEach((element) => {
              if (element.field == "name") {
                this.toastr.error(element.message);
              } else if (element.field == "email") {
                this.toastr.error(element.message);
              } else if (element.field == "mobile") {
                this.toastr.error(element.message);
              } else if (element.field == "message") {
                this.toastr.error(element.message);
              } else if (element.field == "message_id") {
                this.toastr.error(element.message);
              } else if (element.field == "document") {
                this.toastr.error(element.message);
              }
            });
          }
        });
    
  }

  acceptOnlyNumbers(event) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/[0-9]/g.test(inputChar)) {
      event.preventDefault();
    }
  }

  ngAfterViewInit() {
    const input = document.querySelector("#phone") as HTMLInputElement;
    if (input) {
      intlTelInput(input, {
        initialCountry: "eg",
        separateDialCode: false, 
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js"
      });
    }
  }

  randomNumber(min, max) {
    // return Math.random() * (max - min) + min;
    this.RandomNumber1 = Math.floor(Math.random() * (max - min + 1) + min);
    this.RandomNumber2 = Math.floor(Math.random() * (max - min + 1) + min);

  }
  calculateStyles() {
    if (this.article.bg_source === 'component_Bg') {
      if (this.article.component_background_color) {
        return {
          'background-color': this.article.component_background_color,
          'background-image': 'none', 
        };
      } else if (this.article.component_background_image) {
        return {
          'background-color': 'transparent',
          'background-image': `url(${this.article.component_background_image})`,
        };
      }
    } else if (this.article.bg_source !== 'component_Bg') {
      return {
        'background-color': 'transparent',
        'background-image':this.Contact_Data.length>0? `url(${this.Contact_Data[0]?.image})`:'none',
      };
    }
  
    return {
      'background-color': 'transparent',
      'background-image': 'none'
    };
  }
  
}
