<div [ngStyle]="calculateStyles()" class=" Background mx-0">


    <div class="row big-padd d-flex justify-content-center align-items-center">
        <div class="col-md-6 col-sm-12 col-xs-12 padd">
            <app-header-title-desc [article]="article"></app-header-title-desc>

            <form class="custom full-width form-padding mt-4" [formGroup]="contactForm"
                (ngSubmit)="submit(contactForm.value)" enctype="multipart/form-data">
                <div class="form-group">
                    <input type="text" formControlName="name" class="form-control placeholder-light"
                        placeholder="{{ 'Name' | translate }}" />
                    <span *ngIf="
                    contactForm.get('name').touched && contactForm.get('name').invalid
                ">
                        <small *ngIf="contactForm.get('name').errors.required" class="form-text required">
                            {{ "VALIDATORS.REQUIRED" | translate }}
                        </small>
                    </span>
                </div>

                <div class="form-group">
                    <input type="email" formControlName="email" class="form-control placeholder-light"
                        placeholder="{{ 'Email' | translate }}" />
                    <!--Validation-->
                    <span *ngIf="
                    contactForm.get('email').touched && contactForm.get('email').invalid
                ">
                        <small *ngIf="contactForm.get('email').errors.required" class="form-text required">
                            {{ "VALIDATORS.REQUIRED" | translate }}
                        </small>
                        <small *ngIf="contactForm.get('email').errors.pattern" class="form-text required">
                            {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
                        </small>
                    </span>
                </div>

                <div class="form-group">
                    <input id="phone" type="text" (keypress)="acceptOnlyNumbers($event)" formControlName="mobile"
                        maxlength="11" class="form-control Numbers-font placeholder-light"
                        placeholder="{{ 'Mobile' | translate }}" />

                    <span *ngIf="
                    contactForm.get('mobile').touched &&
                    contactForm.get('mobile').invalid
                ">
                        <small *ngIf="contactForm.get('mobile').errors.required" class="form-text required">
                            {{ "VALIDATORS.REQUIRED" | translate }}
                        </small>
                        <small *ngIf="contactForm.get('mobile').errors.pattern" class="form-text required">
                            {{ "phoneLength" | translate }}
                        </small>
                    </span>
                </div>

                <div class="form-group">
                    <textarea class="form-control placeholder-light" formControlName="message" rows="5"
                        placeholder="{{ 'GENERAL_PAGES.CONTACT.MESSAGE' | translate }}"></textarea>
                </div>

                <button type="button" class="btn-custom" type="submit" [ngStyle]="{
                'background-color': button?.button_background_color,
                 color: button?.button_color,
                'border-color': button?.button_background_color
                }">
                    {{ 'send'|translate}}
                </button>
            </form>
        </div>
    </div>

</div>