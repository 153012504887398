<div [appMyStyles]="component">
  <app-header-title-desc [article]="component"></app-header-title-desc>
  <div class="row justify-content-center">
    <div class="col-lg-6 col-sm-12">
        <form
      [formGroup]="ReservationForm"
      (ngSubmit)="submitForm(ReservationForm.value)"
      class="d-flex justify-content-center flex-column align-items-center ml-4 mt-3 margin"
      
    >
      <input
        type="text"
        class="form-control form-contact-input  m-3"
        placeholder="name "
        formControlName="name"
        [ngStyle]="{'font-size':component?.title_size, 
    'color':component?.title_color,
    'font-weight':component?.data_title_weight
        
      }"
      />
      <input
        type="text"
        class="form-control form-contact-input  m-3"
        placeholder="Email "
        formControlName="email"
        [ngStyle]="{'font-size':component?.title_size, 
        'color':component?.title_color,
        'font-weight':component?.data_title_weight
            
          }"
      />
      <input
        type="text"
        class="form-control form-contact-input  m-3"
        placeholder="Phone "
        formControlName="mobile"
        [ngStyle]="{'font-size':component?.title_size, 
        'color':component?.title_color,
        'font-weight':component?.data_title_weight
            
          }"
      />
      <textarea
        type="text"
        class="form-control form-contact-area  m-3 message"
        placeholder="message "
        formControlName="message"
        [ngStyle]="{'font-size':component?.title_size, 
        'color':component?.title_color,
        'font-weight':component?.data_title_weight
          }"
      ></textarea>
      <button
        class="btn px-5 py-2 w-100"
        [disabled]="!ReservationForm.valid"
        type="submit"
        [ngStyle]="{'background-color':websiteMainColor,'color':'#fff'}"
      >
        Send
      </button>
    </form>
    </div>
  <div class="col-lg-6 col-sm-12">
      <div *ngIf="ImageTitleData?.length>0"
      class="d-flex col justify-content-center"
    >
      <img 
        [ngStyle]="{
          'background-color': Button_Background_Color,
          color: Button_Color
        }"
        class="ml-5 form-img"
        [src]="ImageTitleData[0]?.image"
      />
    </div> 
  </div>
 
  </div>
</div>
