import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DivideRowStyleService {

  constructor() { }
  getColClass(items:[]): string {
    console.log(items);
    
    const length: number = items.length;
    if (length === 3 || length === 6 ) {
      return 'col-lg-4 col-md-6 col-sm-6 col-xs-12'; // Exactly 3 items
    } else if (length === 1) {
      return 'col-lg-12 col-md-12 col-sm-12 col-xs-12'; // More or fewer than 3 items
    
    } else if (length === 2) {
      return 'col-lg-6 col-md-6 col-sm-6 col-xs-12'; // More or fewer than 3 items
    }
    else if (length >= 4 || length === 8) {
      return 'col-lg-3 col-md-6 col-sm-6 col-xs-12'; // More or fewer than 3 items
    }
    

  }
}
