import { Component, Input, OnInit } from "@angular/core";

import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";

declare var $: any;

@Component({
  selector: "app-cards6-slider",
  templateUrl: "./cards6-slider.component.html",
  styleUrls: ["./cards6-slider.component.scss"],
})
export class Cards6SliderComponent implements OnInit {
  @Input() article: any;

  Cards6_Slider_Data;
  responsiveOptions = [];
  isCurve :number=1;

  lang: string;
 
  currentLang: string;

  slides: any[] = [];

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private router: Router,
    private langService: LangService,
    public sanitizer: DomSanitizer,

  ) {}

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 4,
        numScroll: 1,
      },
      {
        breakpoint: "768px",
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];

    this.lang = localStorage.getItem("container_lang");
     
    this.currentLang = localStorage.getItem("lang"); 

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.Cards6_Slider_Data = res.content;
        this.slides = res.content;
      
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");
       
      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.Cards6_Slider_Data = res.content;
        });
    });
  }

  navigateToShow(id) {
    // if (this.article.can_navigate == "1") {
    this.langService.getLang().subscribe((res) => {
      this.router.navigate([`${res.key}/cms/1/${id}`],
      { queryParams:
        {module: this.article.parameter.module_id}
      });
    });
    // }
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

}
