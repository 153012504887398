


  <form action="/action_page.php" class="ar-form">
    <h3 class="main-title">{{'Login AS:'|translate}}</h3>
    <div class="form-group">
      <input type="radio" class="radio-input" id="Admin" name="gender" value="Admin" (click)="showadminform()">
      <label for="Admin" class="radio-label">{{'Guest'|translate}}</label><br>
    </div>
    <div class="form-group">
      <input type="radio" class="radio-input" id="Guest" name="gender" value="Guest" (click)="showaGuestform()" checked>
      <label for="Guest" class="radio-label"> {{'Admin'|translate}}</label><br>
    </div>
    <br>
  </form>
   

    <form id="adminloginform" [formGroup]="adminloginform" (ngSubmit)="submitAdminForm(adminloginform.value)">
      <div class="form-group">
        <label>{{'Username'|translate}}</label>
        <input type="text" class="form-control" formControlName="username">
        <div *ngIf="adminloginform.get('username').touched && adminloginform.get('username').invalid">
          <div *ngIf="adminloginform.get('username').errors.required" class=" text-right validator-error">
            {{'VALIDATORS.REQUIRED'|translate}}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>{{'Password'|translate}}</label>
        <input type="password" class="form-control" formControlName="password">
        <div *ngIf="adminloginform.get('password').touched && adminloginform.get('password').invalid">
          <div *ngIf="adminloginform.get('password').errors.required" class=" text-right validator-error">
            {{'VALIDATORS.REQUIRED'|translate}}
          </div>
        </div>
      </div>
      <!-- <button type="submit">admin login</button> -->
      <button class="btn btn-xs btn-custom-sec new"  [disabled]="!adminloginform.valid" type="submit">{{'Login'|translate}}</button>
      <!-- <a class="btn btn-xs btn-custom new" [routerLink]="['registration']" >{{'Register'|translate}}</a> -->
      <!-- <a class="btn btn-xs btn-custom new password-reset-btn"  [routerLink]="['reset-password']" >{{'passwordreset'|translate}}</a> -->

    </form>

    <form id="loginForm" [formGroup]="loginForm" (ngSubmit)="submitForm(loginForm.value)">
      <div class="form-group">
        <label>{{'AUTH.REGISTER.EMAIL'|translate}}</label>
        <input type="text" class="form-control" formControlName="email">
        <div *ngIf="loginForm.get('email').touched && loginForm.get('email').invalid">
          <div *ngIf="loginForm.get('email').errors.required" class=" text-right validator-error">
            {{'VALIDATORS.REQUIRED'|translate}}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>{{'Password'|translate}}</label>
        <input type="password" class="form-control" formControlName="password">
        <div *ngIf="loginForm.get('password').touched && loginForm.get('password').invalid">
          <div *ngIf="loginForm.get('password').errors.required" class=" text-right validator-error">
            {{'VALIDATORS.REQUIRED'|translate}}
          </div>
        </div>
      </div>

      <!-- <div class="form-group">
        <label>التطبيقات</label>
        <select class="form-control" formControlName="application_id">
          <option *ngFor="let app of applications" [value]="app.id">{{app.title}}</option>
        </select>
      </div> -->
      <!-- <button type="submit">admin login</button> -->
      <button class="btn btn-xs btn-custom-sec new pull-left"  style="font-size: 14px;" [disabled]="!loginForm.valid" type="submit">{{'Login'|translate}}</button>
      <a class="btn btn-xs btn-custom new pull-left" style="font-size: 14px;" routerLink="/registration" >{{'Register'|translate}}</a>
      <a class="btn btn-xs btn-custom-dark new password-reset-btn pull-left" style="font-size: 14px;"   [routerLink]="['reset-password']" >{{'passwordreset'|translate}}</a>

    </form>

