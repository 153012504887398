import * as $ from "jquery";

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";

import AOS from "aos";
import { CommonGeneralService } from "src/app/general-pages/pages/services/common-general.service";
import { LangService } from "../../shared_services/lang.service";
import { MenuService } from "../../../services/General/menu.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import { SubscribeService } from "../../shared_services/subscribe.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

// import { $ } from 'jquery';

const VISITOR_COUNTER = 9;
@Component({
  selector: "app-etaam-footer",
  templateUrl: "./etaam-footer.component.html",
  styleUrls: ["./etaam-footer.component.scss"],
})
export class EtaamFooterComponent implements OnInit {
  myprofile: boolean = false;
  contactData: any = {};
  SubscribeForm: FormGroup;
  SocialLinks;
  visitors: any;
  facebooklink;
  instagramlink;
  youtubelink;
  twitterlink;
  snapchatlink: any;

  MenuItems;
  footeritems: any[] = [];
  APP_ID;

  website_logo;
  website_main_colour;
  website_sec_colour: any;
  footer_logo: any;
  footer_color: any;
  footer_bg_color: any;

  lang;
  language;
  website_sec_color: any;
  footerlogo: any;
  footer_size: any;
  Footer_font_size: any;
  passStyle: { color: any; background: any; };

  copyWriteFooter: any;
  data_position: any;
  website_description: any;
  logo_shape: string='circle';

  constructor(
    private router: Router,
    private commonPagesService: CommonGeneralService,
    private subscribeseervicez: SubscribeService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private menuService: MenuService,
    private service: CommonGeneralService,
    private translateService: TranslateService,
    protected langService: LangService
  ) {
    this.langService.getLang().subscribe((lang) => {
      this.lang = lang;
      this.language = lang.key;
    });
    this.SubscribeForm = formBuilder.group({
      email: ["", Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.APP_ID = localStorage.getItem("Application_ID");
    this.lang = localStorage.getItem("container_lang");
    this.commonPagesService.getwebsitedata().subscribe((res: any) => {
    console.log("desc ",res);
    
      this.website_description = res.content[0].des;
     
    })
    AOS.init();
    this.website_logo = JSON.parse(localStorage.getItem("footer_logo"))

    this.commonPagesService.getContactData().subscribe((res: any) => {
      this.contactData = res.content[0];
      console.log( "footer ",res.content);
      
    });

    this.commonPagesService.getFooterSocialLinks().subscribe((res: any) => {
      if (res) {
        this.SocialLinks = res.content;

      }
    });

    if (this.APP_ID != null) {
      this.menuService.menuData$.subscribe((res: any) => {
        if (res) {
          this.MenuItems = res.Items;

          this.MenuItems.forEach( (element:any) => {
            if (element.menu_design.footer == 1){
              this.footeritems.push(element)
            }
          })
          
          this.website_main_colour = res.Design.primary_color;
          this.website_sec_colour = res.Design.secondary_color;
          this.footer_bg_color = res.Design.footer_background_color;
          this.footer_color = res.Design.footer_color;
          this.footerlogo = res.Design.Footer_Logo
          this.footer_size = res.Design.footer_font_size
          this.Footer_font_size = res.Menu.footer_font_size;
          this.passStyle={'color':this.website_main_colour,'background':this.footer_color};
          this.copyWriteFooter=res.Menu.copy_writer;
          this.data_position=res.Menu.contact_data_postion;
          this.logo_shape=res.Menu.logo_shape;
     
        }
      });
    }
  }

  viewProfile() {
    if (this.myprofile == false) {
      this.myprofile = true;
    }

    window.scrollTo(0, 0);
  }

  closeProfile(val) {
    this.myprofile = val;
  }

  PostSubscribeEmail() {
    this.subscribeseervicez
      .PostKa2emaBaridaya(this.SubscribeForm.value)
      .subscribe((res: any) => {
        if (res.status.code === 200) {
          this.toastr.success(res.status.message);
        } else if (res.status.code === 401) {
          this.toastr.error(res.status.error_details);
        } else if (res.status.code === 400) {
          this.toastr.error(res.status.error_details);
        } else if (res.status.code === 403) {
          this.toastr.error(res.status.validation_errors[0].message);
        } else {
          this.toastr.error(res.status.error_details);
        }
      });
  }

  // formatDigits() {
  //   var text = $("#visitors").html();
  //   $("#visitors").html('');
  //   for (let i = 0; i <= text.length - 1; i++) {
  //     var html = text.substr(i, 1);
  //     var sp = "<span class='digit'>" + html
  //     sp += " </span> &nbsp;";
  //     $("#visitors").prepend(sp);
  //   }
  //   for (let j = 0; j <= VISITOR_COUNTER - text.length; j++) {
  //     var sp = "<span class='digit'>" + 0
  //     sp += " </span> &nbsp;";
  //     $("#visitors").append(sp);
  //   }
  //   /* var list = $('#visitors');
  //   var listItems = list.children('span');
  //   list.append(listItems.get().reverse()); */
  // }

  navigatetoModule(item) {
    $("body").removeClass(" nb-theme-default");

    localStorage.setItem("apiparameter", JSON.stringify(item.flow[0]));
    localStorage.setItem("sublist", JSON.stringify(item.flow[1]));
    localStorage.setItem("lastScreen", JSON.stringify(item.flow.length - 1));
    localStorage.setItem(
      "sublist_moduleNo",
      JSON.stringify(item.flow[item.flow.length - 1].parameters.module_id)
    );

    localStorage.setItem("perview", JSON.stringify(item.flow[0].action_id));

    localStorage.setItem("ModuleID", JSON.stringify(item.name));
    localStorage.setItem("ModuleNo", JSON.stringify(item.module_id));

    // this.router.navigate(['/cmsforms'], { queryParams: { formparams:JSON.parse( item.flow[0]) } });
    if (item.can_navigate == 1) {
    if (item.flow[0].layout_id == 32 || item.flow[0].layout_id == 1) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 1,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list1_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show1_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List1_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 33 || item.flow[0].layout_id == 2) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 2,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list2_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show2_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List2_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 31) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 3,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list3_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show3_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List3_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 34) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 4,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list4_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show4_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List4_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 35) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 5,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list5_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show5_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List5_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 36) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 6,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list6_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show6_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List6_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 37) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 7,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });
      localStorage.setItem(
        "list7_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show7_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List7_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 38) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: {
            list: 8,
            module: JSON.parse(localStorage.getItem("ModuleNo")),
          },
        });
      });

      localStorage.setItem(
        "list8_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show8_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("List8_Title", JSON.stringify(item.title));
    } else if (item.flow[0].layout_id == 59) {
      // Go to General Pages Module
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general`], {
          queryParams: { module: JSON.parse(item.id) },
        });
      });
    } else if (item.flow[0].layout_id == 29) {
      localStorage.setItem(
        "Home_components",
        JSON.stringify(item.flow[0].components)
      );
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/home`]);
      });
    } else if (item.flow[0].layout_id == 30) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cmspages/contactus`]);
      });
    } else if (item.flow[0].layout_id == 57) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/info`], {
          queryParams: { module: JSON.parse(localStorage.getItem("ModuleNo")) },
        });
      });
    } else if (item.flow[0].layout_id == 58) {
      this.langService.getLang().subscribe((res) => {
        this.router.navigate([`${res.key}/cms/general-list`], {
          queryParams: { module: JSON.parse(localStorage.getItem("ModuleNo")) },
        });
      });

      localStorage.setItem(
        "General_list_Components",
        JSON.stringify(item.flow[0].components)
      );
      localStorage.setItem(
        "show_Components",
        JSON.stringify(item.flow[1].components)
      );
      localStorage.setItem("general_List_Title", JSON.stringify(item.title));
    }

    } else {
      this.scrollToElement(item.internal_navigate_to);
    }

    // else if (item.flow[0].name == "الاخبار" && item.flow[0].layout_id == 2){
    //   // Go to Forms  Module
    //   this.router.navigate(['/cmsforms'], { queryParams: { formparams: JSON.parse(item.flow[0].flow[1]) } });

    // }
  }

  Logout() {
    localStorage.removeItem("login_token");
    localStorage.removeItem("login_assoc");
    localStorage.removeItem("association_id");
    localStorage.removeItem("Application_ID");

    if (this.lang == "ar_container") {
      this.toastr.success("لقد تم تسجيل الخروج بنجاح");
    } else {
      this.toastr.success("  You Have Been Logout Suceesfully ");
    }
    window.location.replace("/cms");
  }

  scrollToElement(element: string): void {
    if (this.router.url !== "/en/home" && this.router.url !== "/ar/home") {
      this.router.navigate([`${this.lang.key}/home`]).then(() => {
        setTimeout(() => {
          document
            .getElementById(element)
            .scrollIntoView({ behavior: "smooth", inline: "nearest" });
        }, 2000);
      });
    } else {
      document
        .getElementById(element)
        .scrollIntoView({ behavior: "smooth", inline: "nearest" });
    }
  }
}
