import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";

import { Article } from "../../../articles/models/article";
import { HomeService } from "../../../general-pages/pages/home/home.service";
import { LangService } from "../../shared_services/lang.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationService } from "../../shared_services/navigation.service";

declare var $: any;

@Component({
  selector: "app-cards-slider",
  templateUrl: "./cards-slider.component.html",
  styleUrls: ["./cards-slider.component.scss"],
})
export class CardsSliderComponent implements OnInit {
  @Input() article: any;
  @Input("staticData") staticData;

  cards_slider_data: any[] = [];
  lang: string;
  currentLang: string;
  slides: any[] = [];
  param;
  main_param;
  website_main_colour: any;

  screenWidth = 1024;
  responsiveOptions;
  scroll = 2;
  visible = 2;
  activeIndex: number = 0;

  constructor(
    private homeService: HomeService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private navigationService: NavigationService
  ) {
    if (window.screen.width >= 1024) {
      this.screenWidth = window.screen.width;
    }
    this.responsiveOptions = [
      {
        breakpoint: this.screenWidth + "px",
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: "1440px",
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: "768px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "430px",
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.param = params["module"];
    });

    if (window.screen.width > 768) {
      this.scroll = this.visible = 2;
    } else {
      this.scroll = this.visible = 1;
    }

    $(".owl-carousel").owlCarousel({
      loop: false,
      margin: 10,
      responsiveClass: true,
      center: true,
      dots: true,
      navText: [
        '<i style="font-size: 22px;color: #a3a3a3; display:none" class="fa fa-chevron-circle-left "></i>',
        '<i style="font-size: 22px;color: #a3a3a3;display:none" class="fa fa-chevron-circle-right"></i>',
      ],

      responsive: {
        0: {
          items: 1,
          nav: true,
          dots: true,
        },
        600: {
          items: 1,
          nav: false,
          dots: true,
        },
        1000: {
          items: 6,
          nav: true,
          loop: true,
          dots: true,
        },
      },
    });

    this.lang = localStorage.getItem("container_lang");
    this.currentLang = localStorage.getItem("lang");
    this.website_main_colour = JSON.parse(localStorage.getItem("mainColor"));

    this.homeService
      .getHomeComponentsData(this.article)
      .subscribe((res: any) => {
        this.cards_slider_data = res.content;
        this.main_param = this.article.main_id;
        this.slides = res.content;
      });

    this.translateService.onLangChange.subscribe((res) => {
      this.currentLang = localStorage.getItem("lang");

      this.homeService
        .getHomeComponentsData(this.article)
        .subscribe((res: any) => {
          this.cards_slider_data = res.content;
        });
    });
  }

  ngAfterViewInit() {
    if (document.querySelector(".swiper-pagination-bullet-active")) {
      (
        document.querySelector(
          ".swiper-pagination-bullet-active"
        ) as HTMLElement
      ).style.color = this.website_main_colour;
    }
  }

  navigateToShow(id) {
    this.navigationService.navigateToShow(this.article, id, 1);
  }

  onPageChange(event: any): void {
    this.activeIndex = event.page;
  }
}
