<div [appMyStyles]="article">

  <app-header-title-desc [article]="article"></app-header-title-desc>

  <div class="row">

    <div class="mar-bottom-col px-1" [ngClass]="col" *ngFor="let item of ThreeDateCardsData | slice : 0 : article.items_number"
      (click)="navigateToShow(item.id)">

      <div class="card" [ngStyle]="{'background-color':article?.Style_card_background}">
        <h6 class="title" [ngStyle]="{
                  'font-size': article.title_size, 
                  color: article.title_color,
                  'font-weight':article?.data_title_weight
                }">{{item.title}}</h6>
        <p class="card-body" [ngStyle]="{
                      'font-size': article.description_size,
                      color: article.description_color,
                      'font-weight':article?.data_desc_weight
                    }">{{item.description}}</p>
        <button *ngIf="article?.component_buttons?.length>0" [ngStyle]="{
    'background-color': article && article.component_buttons && article.component_buttons[0] && article.component_buttons[0].button_background_color ? article.component_buttons[0].button_background_color : 'transparent',
    'color': article && article.component_buttons && article.component_buttons[0] ? article.component_buttons[0].button_color : 'inherit',
    'font-size': '16px',
    'font-weight': article && article.component_buttons && article.component_buttons[0] && article.component_buttons[0].button ? article.component_buttons[0].button.font_weight : 'normal'
  }" (click)="navigateToShow(item.id)" class="link">
          <ng-container *ngIf="lang == 'ar_container'">{{article?.component_buttons[0]?.button_text}} </ng-container>
          <ng-container *ngIf="lang == 'en_container'">{{article?.component_buttons[0]?.button_name}} </ng-container>


        </button>
      </div>

    </div>

  </div>



</div>

<app-alert *ngIf="ThreeDateCardsData?.length == 0" [componentId]="article?.id" [mainId]="article?.main_id"
  [message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>