<div>
  <app-header-title-desc [appMyStyles]="component" [article]="component"></app-header-title-desc>
</div>
  <div [appMyStyles]="component" class="d-flex">

  <div *ngIf="ImageTitleData?.length>0"
    class=" row mb-4 mx-4 content"
    [ngClass]="{ 'flex-row-reverse': !component.image_position }"
  >
    <div
      class="d-flex flex-column p-3 col-md-6 col-sm-11 col-xs-11"
      
    >
      <div
        class="d-flex flex-column mb-5 justify-content-start"
        *ngFor="let item of ImageTitleData | slice : 0 : 2"
      >
        <div class="header py-2 px-2 col-4 mb-3 d-flex justify-content-center">
          <span class="text-center"
          [ngStyle]="{
            'font-size': component.title_size,
            color: component.title_color,
            'font-weight':component?.data_title_weight
          }"> {{item?.title}} </span>
        </div>
        <p class="desc" [ngStyle]="{
          'font-size': component.description_size,
          color: component.description_color,
          'font-weight':component?.data_desc_weight
        }">{{item?.description}}</p>
      </div>
    </div>
    <img
      class="col-md-6 col-sm-11 col-xs-11 img-side"
      
      [ngClass]="{ 'rounded-lg': isCurve }"
      src="{{ ImageTitleData[0]?.image }}"
    />
  </div>
</div>
<app-alert *ngIf="ImageTitleData?.length == 0" [componentId]="component?.id" [mainId]="component?.main_id"
[message]="'InfoAlarm' | translate" [type]="'warning'">
</app-alert>