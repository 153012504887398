<div [appMyStyles]="article" class="technically-skilled-section">
  <div class="row d-flex align-items-center justify-content-between"
    [class.flex-row-reverse]="article?.image_position !== 'left'"
    [ngClass]="article?.animations?.length > 1 ? article?.animations[0] : ''">
    <!-- Image Section -->
    <div class="col-lg-4 col-md-4 col-sm-12 profile-image-container">
      <img *ngIf="Cards6_Slider_Data?.length > 0" class="main-img w-100 h-100"
        [ngClass]="{ 'mt-4': article?.component_background_image }" src="{{ Cards6_Slider_Data[0]?.image }}"
        alt="Profile Image" />
    </div>

    <!-- Content Section -->
    <div class="col-lg-8 col-md-8 col-sm-12 skills-container p-4">
      <h4 class="mb-3 title"
        [ngStyle]="{ 'color': article?.title_font_color, 'font-size': article?.title_font_size ,'font-weight':article?.component_title_weight}">
        {{ article?.component_title }}
      </h4>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6" [appGridStyle]="article"
          *ngFor="let item of Cards6_Slider_Data | slice: 1:article.items_number">
          <div class="card d-flex flex-column align-items-center" style="height: 150px;">
            <div class="skill-icon">
              <img src="{{ item.image }}" alt="Skill Icon" class="icon mb-2" />
            </div>
            <p class="skill-title" [ngStyle]="{
                fontSize: article?.title_size,
                color: article?.title_color,
                fontWeight: article?.data_title_weight
              }">
              {{ item.title }}
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- Fallback Message -->
  <div *ngIf="Cards6_Slider_Data?.length === 0" class="alert alert-warning text-center" role="alert">
    <p><b>#{{ article?.id }}</b> {{ 'InfoAlarm' | translate }}</p>
  </div>
</div>