<div
  [appMyStyles]="article"
  class=""
>
<app-header-title-desc [article]="article"></app-header-title-desc>
  

  <div
    class=" col-12  brand-container"
    
  >
    <img  [ngClass]="
    article.Style_image_shape == '0'? 'slider-img-circle': article?.items_number==3 || ImageTitleData.length == 3?  'slider-img-square2':'slider-img-square'
  "
*ngFor="let item of (article?.items_number ? (ImageTitleData | slice: 0 : article?.items_number) : ImageTitleData); index as i"

      src="{{ item.image }}"
    />
  </div>
</div>
