

<nav class="navbar navbar-expand-lg navbar-dark" [ngStyle]="{ 'background-color': Header_Bg_Color }" [ngClass]="full_coverage?'header-full-width':''"> 
  <a class="navbar-brand" [ngStyle]="{'width':website_logo?'unset':'50px'}">
     <img  alt="" class="logo" [src]="website_logo" *ngIf="website_logo" />     
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <ng-container *ngFor="let item of Menu_items_sorted; let i = index">
        <li
          class="nav-item"
          *ngIf="item.menu_design.header == 1"
        >
          <a
            (mouseover)="hoverHeaderItem = i"
            (mouseleave)="hoverHeaderItem = -1"
            [ngStyle]="{
              'color':  hoverHeaderItem == i || itemSelectedFromHeader == item.name ? websiteSecColor : Header_Color,
             'font-size' : Header_font_size ,
              'white-space': 'nowrap',
              'text-transform': Text_Style,
              'font-family':text_type,
              'font-weight': text_weight
             
            }"
            class="nav-link"
            [routerLinkActive]="'active-link'"
            (click)="navigatetoModule(item)"
            >{{ item.name }}
          </a>
        </li>
       
      </ng-container>
     
    
     
    </ul>
    <div class="form-inline my-2 my-lg-0 ">
      <div class="searchbar desktop-only " *ngIf="menu?.Menu?.can_search">
        <span class="search-button">
          <div
            [ngStyle]="{
              'color':websiteSecColor, 
              'background-color': Header_Color ,
              'border-color': Header_Color,
              'text-transform': Text_Style,
              'font-family':text_type,
              'font-weight': text_weight
            }"
            class="nav-link bold search-toggle bold"
            aria-hidden="true"
            (click)="clickEvent()"
          >
          <i class="fa fa-search p-1"></i>

          </div>
        </span>
        <div class="togglesearch" [ngClass]="status ? 'show' : 'hide'">
          <div class="container p-0">
            <input
              type="text"
              placeholder="{{ 'search' | translate }}"
              [(ngModel)]="searchQuery"
              [ngModelOptions]="{ standalone: true }"
            />
            <button
              [ngStyle]="{
                left: lang.key == 'en' ? '8%' : 'unset',
                right: lang.key == 'ar' ? '8%' : 'unset'
              }"
              [ngStyle]="{ 'background-color': websiteMainColor , 'text-transform': Text_Style,'color':Header_Color, 
              'font-family':text_type,
              'font-weight': text_weight }"
              class="search-btn-new"
              (click)="search()"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="actions"  *ngIf="menu?.Menu?.show_buttons == 1 && menu?.Menu?.header_buttons.length>0">
        <ng-container *ngFor="let button of menu?.Menu?.header_buttons | slice:0:2">
          <button class="btn nav-btn" [ngStyle]="{'background-color':button.button_background_color,'border-color': button.button_background_color , 'text-transform': Text_Style ,
          'font-family':text_type,
          'font-weight': text_weight,
          'color':button.button_color,
          'font-size' : Header_font_size
          }" (click)="viewLogin()">{{button.button_text}} </button>
        
        </ng-container>
        
      </div>
        
     
    <div class="lang-section">

  
      <i class="fa fa-thin fa-globe p-1" aria-hidden="true"  [ngStyle]="{
          color: Header_Color }"></i>
      <a 
      (click)="lang.key == 'ar' ? changeLang('en') : changeLang('ar')"
        [ngStyle]="{
          color: Header_Color,
         'font-size' : Header_font_size ,
         
         'font-family':text_type,
         'font-weight': text_weight,
         'white-space': 'nowrap'
         }"> {{ 'languageType'  | translate}} </a>
       </div> 
 
    </div>
  </div>
</nav>
<app-auth-layout
  *ngIf="mylogin"
  (closeLoginPopup)="closeLogin($event)"
></app-auth-layout>
