<div *ngIf="showCarousel" class="image-slider-sign">
  <p-carousel
    [value]="slides"
    [numVisible]="visible"
    [numScroll]="scroll"
    [responsiveOptions]="responsiveOptions"
  >
    <ng-template let-slide pTemplate="item">
      <div class="row" *ngIf="slide?.image">
        <div class="col-10 col-sm12 col-xs-12 side-slider">
          <div class="image-title">
            <img
              [src]="slide?.image"
              [ngClass]="component.Style_image_shape == '0' ? 'slider-img-circle' : 'slider-img-square'"
            />
            <p class="py-3" [ngStyle]="{ 'font-size': component?.title_size, color: component?.title_color }">
              {{ slide?.title }}
            </p>
          </div>
          <div class="desc">
            <p [ngStyle]="{ 'font-size': component?.description_size, color: component?.description_color }">
              {{ slide?.description }}
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </p-carousel>
</div>
